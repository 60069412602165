import * as React from "react";
import "./users.scss";
import { Page } from "components/page/page";
import { Card } from "components/card/card";
import { Table } from "components/table/table";
import { FinancialSettings, User } from "core/domain";
import { Badge } from "components";
import {
  EditOutlined,
  ExportOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  getAllLevels,
  getAllRoles,
  getAllUserCategories,
  getAllUsers,
} from "core/repositories/user";
import { Button, buttonType } from "components/button/button";
import { Input, inputType } from "components/input/input";
// import { ClientSidePagination } from "components/pagination/pagination";
import { Dropdown } from "../../../../components/dropdown/dropdown";
import { ReactComponent as CalendarIcon } from "assets/icons/dash-line-icons/calendar.svg";
import {
  deleteCommaSperator,
  digitSperator,
  faNumToEnNum,
  IsNumeric,
} from "core/utils/decimalExtentions";

import {
  Enum,
  EnumAsString,
  PaginationList,
} from "../../../../core/domain/commons/common";
import moment from "jalali-moment";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { downloadCSV } from "../../../../core/utils/export-to-file";
import { Modal } from "components/modal/modal";
import { message, Spin, Switch } from "antd";
import { getViaAuth, postViaAuth, responseValidator } from "apis/api";
import { API } from "apis";
import { TableColumn } from "react-data-table-component";

interface FilterValues {
  search: string;
  level: EnumAsString;
  role: EnumAsString;
  isActive: Enum;
  authMediaIsConfirmed: Enum;
  startDate: string;
  endDate: string;
  userCategory: Enum;
}

const defaultSelectedRole: EnumAsString = {
  id: "0",
  title: "همه نقش ",
};
const defualtSelectedCategory: Enum = {
  id: 0,
  title: "همه دسته‌بندی‌ها",
};
const defaultSelectedLevels: EnumAsString = {
  id: "0",
  title: "همه سطوح",
};
const defaultStringSelected: EnumAsString = {
  id: "0",
  title: "همه",
};

const authMediaIsConfirmedTypes = [
  {
    id: 0,
    title: "همه وضعیت سلفی",
  },
  {
    id: 1,
    title: "سلفی تایید شده",
  },
  {
    id: 2,
    title: "سلفی تایید نشده",
  },
];

const isActiveTypes: Enum[] = [
  {
    id: 0,
    title: "همه وضعیت فعالیت",
  },
  {
    id: 1,
    title: "کاربر فعال",
  },
  {
    id: 2,
    title: "کاربر غیرفعال",
  },
];

const isActiveTypeDefualtValue = {
  id: 0,
  title: "همه وضعیت فعالیت",
};
const authMediaIsConfirmedTypesDefualtValue = {
  id: 0,
  title: "همه وضعیت سلفی",
};
const empty_filters = {
  search: "",
  level: defaultSelectedLevels,
  role: defaultSelectedRole,
  isActive: isActiveTypeDefualtValue,
  authMediaIsConfirmed: authMediaIsConfirmedTypesDefualtValue,
  startDate: "",
  endDate: "",
  userCategory: defualtSelectedCategory,
};

export const Users: React.FC = () => {
  var itemsPerPageCount = 20;

  const [financialModal, setFinancialModal] = React.useState<boolean>(false);
  const [financialModalIsLoading, setFinancialModalIsLoading] =
    React.useState<boolean>(false);
  const [financialDeleteIsLoading, setFinancialDeleteIsLoading] =
    React.useState<boolean>(false);
  const [financialSettings, setFinancialSettings] =
    React.useState<FinancialSettings>();
  const [selectedItem, setSelcetedItem] = React.useState<User>();
  const [userLevels, setUserLevels] = React.useState<EnumAsString[]>([
    defaultSelectedLevels,
  ]);
  const [userRoles, setUserRoles] = React.useState<EnumAsString[]>([
    defaultSelectedRole,
  ]);
  const [userCategories, setUserCategories] = React.useState<Enum[]>([
    defualtSelectedCategory,
  ]);
  const [users, setUsers] = React.useState<PaginationList<User>>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
  const [deleteButtonLoading, setDeleteButtonLoading] =
    React.useState<boolean>(false);
  const [query, setQuery] = React.useState<string>("");
  const [filterValues, setFilterValues] =
    React.useState<FilterValues>(empty_filters);
  const [pageSize, setPageSize] = React.useState<number>(10);

  const openFinancialSettingsModal = () => setFinancialModal(true);
  const closeFinancialSettingsModal = () => {
    setFinancialSettings({});
    setFinancialModal(false);
  };

  const handleNumChange = (value: string, field: string) => {
    const enValue = faNumToEnNum(value);
  
    const numericValue = parseFloat(deleteCommaSperator(enValue));
  
    const sanitizedValue = numericValue === 0 ? null : numericValue;
  
    setFinancialSettings({
      ...financialSettings,
      [field]: sanitizedValue,
    });
  };
  

  const onRemoveFinancialSettings = (id: string) => {
    setFinancialDeleteIsLoading(true);

    postViaAuth(
      API.financialSettings.removeFinancialSetting +
        `/${financialSettings.userId}`,
      {},
    ).then((response: any) => {
      setFinancialDeleteIsLoading(false);
      if (responseValidator(response.status)) {
        closeFinancialSettingsModal();
        message.success("محدودیت های مالی کاربر با موفقیت حذف شد.");
      }
    });
  };

  const onUpdateFinancialSettings = (id: string) => {
    setLoading(true);
  
    // Convert 0 values to null
    const maxIrtDepositInDay =
      parseFloat(deleteCommaSperator(financialSettings?.maxIrtDepositInDay || "0")) === 0
        ? null
        : parseFloat(deleteCommaSperator(financialSettings?.maxIrtDepositInDay || "0"));
  
    const maxIrtWithdrawlInDay =
      parseFloat(deleteCommaSperator(financialSettings?.maxIrtWithdrawlInDay || "0")) === 0
        ? null
        : parseFloat(deleteCommaSperator(financialSettings?.maxIrtWithdrawlInDay || "0"));
  
    postViaAuth(API.financialSettings.editFinancialSettings, {
      userId: id,
      maxIrtDepositInDay: maxIrtDepositInDay,
      maxIrtWithdrawlInDay: maxIrtWithdrawlInDay,
      lockAllWithdrawal: financialSettings?.lockAllWithdrawal ?? false,
      manualWithdrawal: financialSettings?.manualWithdrawal ?? false,
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        message.success("محدودیت های مالی کاربر با موفقیت اصلاح شد.");
        closeFinancialSettingsModal();
      }
    });
  };
  
  
  const onFinancialSettingsHandler = (id: string) => {
    setFinancialModalIsLoading(true);
    openFinancialSettingsModal();
    getViaAuth<FinancialSettings>(
      API.financialSettings.getFinancialSettingsById + `/${id}`,
    ).then((data: any) => {
      setFinancialModalIsLoading(false);
      if (responseValidator(data.status) && data.data) {
        data.data && setFinancialSettings(data.data);
        data.data.lockAllWithdrawal == null &&
          setFinancialSettings({ ...data.data, lockAllWithdrawal: false });
      }
    });
  };

  const startDateRef = React.useRef();
  const endDateRef = React.useRef();

  const navigate = useNavigate();

  const clearFiltersHandle = () => {
    setFilterValues(empty_filters);
    filterHandle(true);
  };

  const filterHandle = (clearFilter?: boolean) => {
    var _query = "";

    if (filterValues.search)
      _query += `${_query.includes("?") ? "&" : "?"}keywords=${
        filterValues.search
      }`;
    if (filterValues.level?.id !== "0")
      _query += `${_query.includes("?") ? "&" : "?"}LevelId=${filterValues.level
        ?.id}`;
    if (filterValues.role?.id !== "0")
      _query += `${_query.includes("?") ? "&" : "?"}RoleId=${filterValues.role
        ?.id}`;
    if (filterValues.isActive.id !== 0)
      _query += `${_query.includes("?") ? "&" : "?"}isActive=${
        filterValues.isActive.id === 1 ? true : false
      }`;
    if (filterValues.authMediaIsConfirmed.id !== 0)
      _query += `${_query.includes("?") ? "&" : "?"}authMediaIsConfirmed=${
        filterValues.authMediaIsConfirmed.id === 1 ? true : false
      }`;
    if (filterValues.userCategory.id !== 0)
      _query += `${_query.includes("?") ? "&" : "?"}userCategory=${
        filterValues.userCategory.id
      }`;
    if (filterValues.startDate !== "")
      _query += `${_query.includes("?") ? "&" : "?"}startDate=${moment(
        faNumToEnNum(filterValues?.startDate),
        "jYYYY/jMM/jDD",
        "fa",
      )
        .locale("en")
        .format("YYYY/MM/DD")}`;
    if (filterValues.endDate !== "")
      _query += `${_query.includes("?") ? "&" : "?"}endDate=${moment(
        faNumToEnNum(filterValues?.endDate),
        "jYYYY/jMM/jDD",
        "fa",
      )
        .locale("en")
        .format("YYYY/MM/DD")}`;

    setQuery(_query);

    getAllUsers(
      clearFilter
        ? setDeleteButtonLoading
        : _query !== ""
        ? setButtonLoading
        : setLoading,
      setUsers,
      1,
      pageSize,
      clearFilter ? "" : _query,
    );
  };

  React.useEffect(() => {
    fetches();
  }, []);

  async function fetches() {
    await getAllLevels(setLoading, setUserLevels);
    getAllRoles(setLoading, setUserRoles);
    getAllUserCategories(setLoading, setUserCategories);
    filterHandle();
  }

  const columns: TableColumn<any>[] = [
    {
      name: "نام و نام‌ خانوادگی",
      id: "fullName",
      selector: (row: User, rowIndex: number) => row.fullName || "-",
      width: "20%",
    },
    {
      name: "موبایل",
      id: "phoneNumber",
      selector: (row: User) => row?.phoneNumber,
      cell: (row: User) => (
        <span className="en">{row?.phoneNumber || "-"}</span>
      ),
      width: "300px",
    },
    {
      name: "تاریخ عضویت",
      id: "joinedDate",
      selector: (row: User) => row?.joinedDate,
      cell: (row: User) => (
        <span>
          {moment
            .utc(row?.joinedDate)
            .locale("fa")
            .local()
            .format("YYYY/MM/DD")}
        </span>
      ),
      width: "10%",
    },
    {
      name: "وضعیت",
      id: "state",
      width: "10%",
      selector: (row: User) => row.isActive,
      cell: (row: User) => (
        <Badge
          text={row.isActive ? "فعال" : "غیرفعال"}
          type={row.isActive ? "success" : "error"}
        />
      ),
    },
    {
      name: "سطح کاربر",
      id: "level",
      width: "20%",
      selector: (row: User) => row?.level?.title,
      cell: (row: User) =>
        row.level?.title && <Badge text={row?.level?.title} type={"info"} />,
    },
    {
      name: "وضعیت تایید سلفی",
      id: "authMediaIsConfirmed",
      width: "15%",
      selector: (row: User) => row?.authMediaSurveyIsConfirmed,
      cell: (row: User) => (
        <Badge
          text={
            row?.authMediaSurveyIsConfirmed === true
              ? "تایید شده"
              : "تایید نشده"
          }
          type={row.authMediaSurveyIsConfirmed === true ? "success" : "error"}
        />
      ),
    },
    {
      name: "عملیات",
      id: "action",
      width: "10%",
      cell: (row: User) => (
        <div className="actions">
          <EditOutlined
            onClick={() => {
              navigate(`/dashboard/users/edit/${row.id}`);
            }}
          />
          <FileSearchOutlined
            onClick={() => onFinancialSettingsHandler(row.id)}
          />
        </div>
      ),
    },
  ];

  return (
    <Page className="pmx-users" title="مدیریت کاربران">
      <Modal
        isOpen={financialModal}
        setIsOpen={(e: any) => setFinancialModal(e)}
        className="financial-settings-modal"
      >
        <div className="modal-box">
          <div className="head">
            <h4>تنظیمات مالی کاربر</h4>
          </div>
          <Spin spinning={financialModalIsLoading}>
            <div className="content">
              <p>
                از این قسمت می توانید حداکثر مقدار برداشتی کاربر را محدود نمایید
              </p>
              <Input
                type={inputType.text}
                name="maxIrtWithdrawlInDay"
                value={digitSperator(financialSettings?.maxIrtWithdrawlInDay)}
                onChange={(e) =>
                  handleNumChange(e.target.value, "maxIrtWithdrawlInDay")
                }
                label="حداکثر مقدار برداشت تومانی در روز"
                placeholder="به تومان وارد کنید"
                dir={
                  financialSettings?.maxIrtWithdrawlInDay?.length
                    ? "ltr"
                    : "rtl"
                }
              />
              <Input
                name="maxIrtDepositInDay"
                placeholder="به تومان وارد کنید"
                value={digitSperator(financialSettings?.maxIrtDepositInDay)}
                onChange={(e) =>
                  handleNumChange(e.target.value, "maxIrtDepositInDay")
                }
                label="حداکثر مقدار واریز تومانی در روز"
                dir={
                  financialSettings?.maxIrtDepositInDay?.length ? "ltr" : "rtl"
                }
              />

              <Switch
                checked={financialSettings?.lockAllWithdrawal}
                checkedChildren={"قفل برداشت ها فعال است"}
                unCheckedChildren={"قفل برداشت ها غیر فعال است "}
                onChange={(checked) =>
                  setFinancialSettings({
                    ...financialSettings,
                    lockAllWithdrawal: checked,
                  })
                }
              />

              <Switch
                checked={financialSettings?.manualWithdrawal}
                unCheckedChildren={"برداشت‌های دستی غیرفعال است"}
                checkedChildren={"برداشت‌های دستی فعال است"}
                onChange={(checked) =>
                  setFinancialSettings({
                    ...financialSettings,
                    manualWithdrawal: checked,
                  })
                }
              />

              <Button
                type={buttonType.error}
                onClick={() =>
                  onRemoveFinancialSettings(financialSettings?.userId || "")
                }
                text="حذف تنظیمات مالی کاربر"
                loading={financialDeleteIsLoading}
              />
            </div>
            <div className="buttons">
              <Button
                type={buttonType.info}
                loading={loading}
                disabled={loading}
                text="ویرایش"
                htmlType="button"
                onClick={() =>
                  onUpdateFinancialSettings(financialSettings?.userId || "")
                }
              />
              <Button
                type={buttonType.outlined}
                text="بستن"
                onClick={() => closeFinancialSettingsModal()}
              />
            </div>
          </Spin>
        </div>
      </Modal>
      <Card title="کاربران" blur={loading}>
        <div className="filter-row">
          <div className="fields">
            <Input
              value={filterValues.search}
              className="search"
              placeholder="جستجو کنید ..."
              name="search"
              onPressEnter={filterHandle}
              onChange={(e) =>
                setFilterValues({ ...filterValues, search: e.target.value })
              }
            />
            {userLevels.length > 0 && (
              <Dropdown
                items={userLevels}
                onChangeHandle={(value) =>
                  setFilterValues({
                    ...filterValues,
                    level: userLevels.find((l) => l.id === value),
                  })
                }
                selectedOption={filterValues.level}
              />
            )}
            {userRoles.length > 0 && (
              <Dropdown
                items={userRoles}
                onChangeHandle={(value) =>
                  setFilterValues({
                    ...filterValues,
                    role: userRoles.find((l) => l.id === value),
                  })
                }
                selectedOption={filterValues.role}
              />
            )}
            {userCategories.length > 0 && (
              <Dropdown
                items={userCategories}
                onChangeHandle={(value) =>
                  setFilterValues({
                    ...filterValues,
                    userCategory: userCategories.find((l) => l.id === value),
                  })
                }
                selectedOption={filterValues.userCategory}
              />
            )}
            <Dropdown
              items={authMediaIsConfirmedTypes}
              onChangeHandle={(value) =>
                setFilterValues({
                  ...filterValues,
                  authMediaIsConfirmed: authMediaIsConfirmedTypes.filter(
                    (l) => l.id === value,
                  )[0],
                })
              }
              selectedOption={filterValues.authMediaIsConfirmed}
            />
            <Dropdown
              items={isActiveTypes}
              onChangeHandle={(value) =>
                setFilterValues({
                  ...filterValues,
                  isActive: isActiveTypes.filter((l) => l.id === value)[0],
                })
              }
              selectedOption={filterValues.isActive}
            />
          </div>
          <div className="buttons">
            <DatePicker
              className="date-picker"
              value={filterValues.startDate}
              calendar={persian}
              ref={startDateRef}
              locale={persian_fa}
              render={
                <div>
                  <Input
                    value={filterValues.startDate}
                    icon={<CalendarIcon />}
                    placeholder="از تاریخ"
                  />
                </div>
              }
              onChange={(e) => {
                setFilterValues({ ...filterValues, startDate: e.format() });
              }}
            />
            <DatePicker
              className="date-picker"
              value={filterValues.endDate}
              calendar={persian}
              ref={endDateRef}
              locale={persian_fa}
              render={
                <div>
                  <Input
                    value={filterValues.endDate}
                    icon={<CalendarIcon />}
                    placeholder="تا تاریخ"
                  />
                </div>
              }
              onChange={(e) => {
                setFilterValues({ ...filterValues, endDate: e.format() });
              }}
            />
            <Button
              loading={buttonLoading}
              disabled={buttonLoading}
              text="اعمال فیلتر"
              type={buttonType.success}
              onClick={() => filterHandle()}
            />
            <Button
              loading={deleteButtonLoading}
              disabled={deleteButtonLoading}
              text="حذف فیلتر"
              type={buttonType.error}
              onClick={clearFiltersHandle}
            />
            <Button
              loading={deleteButtonLoading}
              disabled={deleteButtonLoading}
              text="خروجی اکسل"
              type={buttonType.info}
              icon={<ExportOutlined />}
              onClick={(event) => downloadCSV("users", users.items)}
            />
          </div>
        </div>
        {users?.items && users?.items.length > 0 && (
          <>
            <Table
              setPageSize={setPageSize}
              blur={loading}
              setloader={setLoading}
              getdata={getAllUsers}
              setdata={setUsers}
              pagination={users.pagination}
              className="users-table"
              data={users.items}
              column={columns}
              filters={query}
            />
          </>
        )}
      </Card>
    </Page>
  );
};
