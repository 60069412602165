import { Page } from 'components/page/page'
import './audit-log.scss'
import { Card } from 'components/card/card'
import { useEffect, useState } from 'react'
import { Input } from 'components/input/input'
import { Button } from 'components/button/button'
import { AuditLogAll } from 'core/domain/audit-log/audit-log'
import { PaginationList } from 'core/domain'
import { Table } from 'components/table/table'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { getAllAuditLogs } from 'core/repositories/audit-log'
import moment from 'jalali-moment'
import { Tooltip } from 'antd'

export const AuditLog = () => {
    const [cardLoading, setCardLoading] = useState<boolean>(false)
    const [pageSize, setPageSize] = useState<number>(10);
    const [logs, setLogs] = useState<PaginationList<AuditLogAll>>()

    useEffect(() => {
        getAllAuditLogs(setCardLoading, setLogs, 1, pageSize);
        console.log(logs)
    }, [])

    const navigate = useNavigate()

    const columns: any[] = [
        {
            name: "تاریخ",
            id: "createdOn",
            selector: (row: AuditLogAll, rowIndex: number) => row?.createdOn,
            cell: (row: AuditLogAll) => <span>{moment.utc(row?.createdOn).locale("fa").local().format("HH:mm")}{" "}
                {moment
                    .utc(row?.createdOn)
                    .locale("fa")
                    .local()
                    .format("YYYY/MM/DD")}
            </span>,
            width: "40%",
        },
        {
            name: "توسط",
            id: "modifiedBy",
            selector: (row: AuditLogAll) => row?.modifiedBy,
            cell: (row: AuditLogAll) => (
                <span className='en'>{row?.modifiedBy}</span>
            ),
            width: "40%",
        },

        {
            name: "عملیات",
            id: "action",
            cell: (row: AuditLogAll) => (
                <div className='actions'>
                    <Tooltip title='جزئیات'>
                        <InfoCircleOutlined onClick={() => navigate(`details/${row.id}`)} />
                    </Tooltip>
                </div>
            ),
        },
    ]

    return (
        <Page
            className='pmx-audit-log-all'
            title='گزارشات حسابرسی'
        >
            <Card
                title='نمایش گزارشات'
                blur={cardLoading}
            >
                <Table
                    setPageSize={setPageSize}
                    
                    blur={cardLoading}
                    setloader={setCardLoading}
                    getdata={getAllAuditLogs}
                    setdata={setLogs}
                    className='audit-log-table'
                    data={logs?.items}
                    column={columns}
                    pagination={logs?.pagination}
                />
            </Card>
        </Page>
    )
}