import {API} from "apis";
import {getViaAuth, responseValidator} from "apis/api";
import {GatewayTransaction, PaginationList} from "core/domain";

export const getAllGatewayTransactions = async (
    setLoading: any,
    setData: any,
    page: number,
    itemsPerPageCount: number,
    query?: string
) => {
    setLoading(true);
    await getViaAuth<PaginationList<GatewayTransaction>>(
        API.orders.getGateWaysTransactions + `/${page}/${itemsPerPageCount}${query || ""}`
    ).then((response: any) => {
        setLoading(false);
        if (responseValidator(response.status)) {
            if (response.data) {

                setData(response.data);
            }
        }
    });
};

export const getGatewayTransactionByTrackingCode = async (
    setLoading: any,
    setData: any,
    trackingId: any
) => {
    setLoading(true);
    await getViaAuth(API.orders.getGateWaysTransaction + `/${trackingId}`).then(
        (response: any) => {
            setLoading(false);
            if (responseValidator(response.status)) {
                setData(response.data);
            } else if (response.status === 404) {
                setData();
            }
        }
    );
};
