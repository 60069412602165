import { API } from "apis";
import { getViaAuth, postViaAuth, responseValidator } from "apis/api";
import { PaginationList, User, UserDetail } from "core/domain";
import moment from "jalali-moment";
import { faNumToEnNum } from "../utils/decimalExtentions";
import toast from "react-hot-toast";

export const getAllUsers = async (
  setLoading: any,
  setData: any,
  page: number,
  itemsPerPageCount: number,
  query?: string,
) => {
  setLoading(true);
  await getViaAuth<PaginationList<User>>(
    API.users.getAll + `/${page}/${itemsPerPageCount}${query || ""}`,
  ).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      if (response.data) {
        setData(response.data);
      }
    }
  });
};

export const getUserById = async (
  setLoading: any,
  setData: any,
  id: string,
) => {
  const options: any = [];

  setLoading(true);
  await getViaAuth<UserDetail>(API.users.getById + `/${id}`).then(
    (response: any) => {
      setLoading(false);
      console.table(response.data);
      if (responseValidator(response.status)) {
        if (response.data) {
          response.data.roles.forEach((i: any, idx: number) => {
            options.push(i.id);
          });
          setData({
            ...response.data,
            roles: options,
            level: {
              label: response.data.level.title,
              value: response.data.level.id,
            },
            birthDate:
              response.data.birthDate &&
              moment(response.data.birthDate).locale("fa").format("YYYY/MM/DD"),
          });
        }
      }
    },
  );
};

export const updateUser = async (setLoading: any, data: UserDetail) => {
  setLoading(true);

  const formData: FormData = new FormData();

  formData.append(
    "selfiePhoto",
    data?.selfiePhoto?.filter((item) => item.type === "file")[0].file,
  );

  formData.append("id", data?.id);
  formData.append("phoneNumber", data?.phoneNumber);
  formData.append("isTwoStepActive", data?.isTwoStepActive.toString());
  data?.firstName && formData.append("firstName", data?.firstName);
  data?.lastName && formData.append("lastName", data?.lastName);
  data?.level?.value && formData.append("level", data?.level.value.toString());
  data?.level && formData.append("level", data?.level.toString());

  data?.emailAddress && formData.append("emailAddress", data?.emailAddress);
  data?.address && formData.append("address", data?.address);
  data?.postalCode && formData.append("postalCode", data?.postalCode);
  data?.nationalCode && formData.append("nationalCode", data?.nationalCode);
  data?.isActive && formData.append("isActive", data?.isActive.toString());

  data?.birthDate &&
    formData.append(
      "birthDate",
      moment(faNumToEnNum(data?.birthDate), "jYYYY/jMM/jDD", "fa")
        .locale("en")
        .format("YYYY/MM/DD"),
    );
  data?.landline && formData.append("landline", data?.landline);
  data?.password && formData.append("password", data?.password);
  formData.append("isVip", data?.isVip.toString());

  if (data?.roles)
    data?.roles?.map((role) => {
      formData.append("roles", role.toString());
    });
  await postViaAuth<User>(API.users.update, formData, true).then(
    (response: any) => {
      setLoading(false);

      if (responseValidator(response.status)) {
        toast.success("عملیات با موفقیت انجام شد.");
      }
    },
  );
};

export const getAllRoles = async (setLoading: any, setData: any) => {
  setLoading(true);
  await getViaAuth(API.roles.getAll).then((response: any) => {
    var arr: any = [];
    setLoading(false);
    if (responseValidator(response.status) && response.data) {
      response.data.forEach((e) => arr.push({ id: e.id, title: e.name }));
      setData([{ id: "0", title: "همه" }, ...arr]);
    }
  });
};

export const getAllUserCategories = async (setLoading: any, setData: any) => {
  setLoading(true);
  await getViaAuth(API.users.getAllCategories).then((response: any) => {
    var arr: any = [];
    setLoading(false);
    if (responseValidator(response.status) && response.data) {
      setData([{ id: 0, title: "همه دسته‌بندی‌ها" }, ...response.data]);
    }
  });
};

export const getAllLevels = async (setLoading: any, setData: any) => {
  setLoading(true);
  await getViaAuth(API.users.levels).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      setData([{ id: "0", title: "همه" }, ...response.data]);
    }
  });
};

export const getUserByPhoneNumber = async (
  setLoading: any,
  setData: any,
  phoneNumber: any,
) => {
  setLoading(true);
  await getViaAuth(API.users.getByPhoneNumber + `/${phoneNumber}`).then(
    (response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setData([response.data]);
      } else if (response.status === 404) {
        setData([]);
      }
    },
  );
};
