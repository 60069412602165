import {getViaAuth, responseValidator} from "../../apis/api";
import {CountBuyAndSellChartProps} from "../domain/overview/count-buy-and-sell";
import {API} from "../../apis";
import {Dispatch, SetStateAction} from "react";
import {PMCountBuyAndSellChartProps} from "../domain/overview/pm-count-buy-and-sell";
import {CryptoCountBuyAndSellChartProps} from "../domain/overview/crypto-count-buy-and-sell";
import {SumBuyAndSellChartProps} from "../domain/overview/sum-buy-and-sell";
import {PMSumBuyAndSellChartProps} from "../domain/overview/pm-sum-buy-and-sell";
import {CryptoSumBuyAndSellChartProps} from "../domain/overview/crypto-sum-buy-and-sell";
import {USDTWithdrawalCountChartProps} from "../domain/overview/usdt-withdrawal-count";
import {SumBuyAndSellCommissionsChartProps} from "../domain/overview/sum-buy-and-sell-commissions";
import {UploadedDocumentsChartProps} from "../domain/overview/uploaded-documents";
import {RegisteredUsersCountChartProps} from "../domain/overview/registered-users-count";
import {AuthenticatedUsersCountChartProps} from "../domain/overview/authenticated-users-count";
import {BalancesProps} from "../domain/overview/balances";
import {FirstBuysChartProps} from "../domain/overview/first-buys";

export const getCountBuyAndSell = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    setData: Dispatch<SetStateAction<CountBuyAndSellChartProps>>,
    query?: string,
) => {
    setLoading(true)
    await getViaAuth<CountBuyAndSellChartProps>(`${API.dashboard.getCountBuyAndSell}${query || ""}`).then(
        (response: any) => {
            setLoading(false)
            if (responseValidator(response.status)) {
                setData(response.data);
            }
        }
    )
};

export const getPMCountBuyAndSell = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    setData: Dispatch<SetStateAction<PMCountBuyAndSellChartProps>>,
    query?: string,
) => {
    setLoading(true)
    await getViaAuth<PMCountBuyAndSellChartProps>(`${API.dashboard.getPMCountBuyAndSell}${query || ""}`).then(
        (response: any) => {
            setLoading(false)
            if (responseValidator(response.status)) {
                setData(response.data);
            }
        }
    )
};

export const getCryptoCountBuyAndSell = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    setData: Dispatch<SetStateAction<CryptoCountBuyAndSellChartProps>>,
    query?: string,
) => {
    setLoading(true)
    await getViaAuth<CryptoCountBuyAndSellChartProps>(`${API.dashboard.getCryptoCountBuyAndSell}${query || ""}`).then(
        (response: any) => {
            setLoading(false)
            if (responseValidator(response.status)) {
                setData(response.data);
            }
        }
    )
};

export const getSumBuyAndSell = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    setData: Dispatch<SetStateAction<SumBuyAndSellChartProps>>,
    query?: string,
) => {
    setLoading(true)
    await getViaAuth<SumBuyAndSellChartProps>(`${API.dashboard.getSumBuyAndSell}${query || ""}`).then(
        (response: any) => {
            setLoading(false)
            if (responseValidator(response.status)) {
                setData(response.data);
            }
        }
    )
};

export const getPMSumBuyAndSell = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    setData: Dispatch<SetStateAction<PMSumBuyAndSellChartProps>>,
    query?: string,
) => {
    setLoading(true)
    await getViaAuth<PMSumBuyAndSellChartProps>(`${API.dashboard.getPMSumBuyAndSell}${query || ""}`).then(
        (response: any) => {
            setLoading(false)
            if (responseValidator(response.status)) {
                setData(response.data);
            }
        }
    )
};

export const getCryptoSumBuyAndSell = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    setData: Dispatch<SetStateAction<CryptoSumBuyAndSellChartProps>>,
    query?: string,
) => {
    setLoading(true)
    await getViaAuth<CryptoSumBuyAndSellChartProps>(`${API.dashboard.getCryptoSumBuyAndSell}${query || ""}`).then(
        (response: any) => {
            setLoading(false)
            if (responseValidator(response.status)) {
                setData(response.data);
            }
        }
    )
};

export const getUSDTWithdrawalCount = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    setData: Dispatch<SetStateAction<USDTWithdrawalCountChartProps>>,
    query?: string,
) => {
    setLoading(true)
    await getViaAuth<USDTWithdrawalCountChartProps>(`${API.dashboard.getUSDTWithdrawalCount}${query || ""}`).then(
        (response: any) => {
            setLoading(false)
            if (responseValidator(response.status)) {
                setData(response.data);
            }
        }
    )
};

export const getSumBuyAndSellCommissions = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    setData: Dispatch<SetStateAction<SumBuyAndSellCommissionsChartProps>>,
    query?: string,
) => {
    setLoading(true)
    await getViaAuth<SumBuyAndSellCommissionsChartProps>(`${API.dashboard.getSumBuyAndSellCommissions}${query || ""}`).then(
        (response: any) => {
            setLoading(false)
            if (responseValidator(response.status)) {
                setData(response.data);
            }
        }
    )
};

export const getUploadedDocuments = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    setData: Dispatch<SetStateAction<UploadedDocumentsChartProps>>,
    query?: string,
) => {
    setLoading(true)
    await getViaAuth<UploadedDocumentsChartProps>(`${API.dashboard.getUploadedDocumentsChart}${query || ""}`).then(
        (response: any) => {
            setLoading(false)
            if (responseValidator(response.status)) {
                setData(response.data);
            }
        }
    )
};

export const getRegisteredUsersCount = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    setData: Dispatch<SetStateAction<RegisteredUsersCountChartProps>>,
    query?: string,
) => {
    setLoading(true)
    await getViaAuth<RegisteredUsersCountChartProps>(`${API.dashboard.getRegisteredUsersCount}${query || ""}`).then(
        (response: any) => {
            setLoading(false)
            if (responseValidator(response.status)) {
                setData(response.data);
            }
        }
    )
};

export const getAuthenticatedUsersCount = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    setData: Dispatch<SetStateAction<AuthenticatedUsersCountChartProps>>,
    query?: string,
) => {
    setLoading(true)
    await getViaAuth<AuthenticatedUsersCountChartProps>(`${API.dashboard.getAuthenticatedUsersCount}${query || ""}`).then(
        (response: any) => {
            setLoading(false)
            if (responseValidator(response.status)) {
                setData(response.data);
            }
        }
    )
};

export const getBalances = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    setData: Dispatch<SetStateAction<BalancesProps>>,
    query?: string,
) => {
    setLoading(true)
    await getViaAuth<BalancesProps>(`${API.dashboard.getBalances}${query || ""}`).then(
        (response: any) => {
            setLoading(false)
            if (responseValidator(response.status)) {
                setData(response.data);
            }
        }
    )
};

export const getFirstBuys = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    setData: Dispatch<SetStateAction<FirstBuysChartProps>>,
    query?: string,
) => {
    setLoading(true)
    await getViaAuth<FirstBuysChartProps>(`${API.dashboard.getFirstBuys}${query || ""}`).then(
        (response: any) => {
            setLoading(false)
            if (responseValidator(response.status)) {
                setData(response.data);
            }
        }
    )
};