import './balances.scss';
import {FC} from "react";
import {BalancesProps} from "../../../../../core/domain/overview/balances";
import {digitSperator} from "../../../../../core/utils/decimalExtentions";
import {ReactComponent as PMIcon} from 'assets/icons/overview/PM.svg'
import {ReactComponent as USDTIcon} from 'assets/icons/overview/USDT.svg'
import {ReactComponent as IRTIcon} from 'assets/icons/overview/IRT.svg'
import {Spin} from "antd";

interface Props {
    balancesData: BalancesProps
    balancesLoading: boolean
}

export const Balances: FC<Props> = ({balancesData, balancesLoading}) => {
    return (
        <Spin spinning={balancesLoading}>
            <div
                className={"pmx-balances"}
            >
                <div className={"balance"}>
                    <span><IRTIcon/> موجودی ریالی</span>
                    <span>{digitSperator(balancesData?.irtBalance)}</span>
                </div>
                <div className={"balance"}>
                    <span><USDTIcon/> موجودی تتر</span>
                    <span>{digitSperator(balancesData?.usdtBalance)}</span>
                </div>
                <div className={"balance"}>
                    <span><PMIcon/> موجودی پرفکت مانی</span>
                    <span>{digitSperator(balancesData?.pmBalance)}</span>
                </div>
            </div>
        </Spin>
    )
}