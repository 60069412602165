import './remove-modal.scss'
import * as React from "react";
import {Dispatch, FC, SetStateAction, useState} from "react";
import {Modal} from "../../../../../components/modal/modal";
import {Button, buttonType} from "../../../../../components/button/button";
import {UserSavedIBans} from "../../../../../core/domain/users-saved-ibans/users-saved-ibans";
import {removeIBan} from "../../../../../core/repositories/users-saved-ibans";

interface Props {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    item: UserSavedIBans;
    onClose: () => void;
}

export const RemoveIBanModal: FC<Props> = ({isOpen, setIsOpen, item, onClose}) => {
    const [loading, setLoading] = useState<boolean>(false)

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} className={"pmx-remove-wallet-modal"}>
            <p>
                آیا از حذف شماره شبای ثبت شده ی {item?.fullName} مطمئن هستید؟
            </p>
            <div>
                <Button type={buttonType.error} text={"بله حذف شود"} loading={loading} onClick={() => removeIBan(setLoading, {id: item.id}, onClose)} />
                <Button type={buttonType.outlined} text={"انصراف"} onClick={() => setIsOpen(false)} />
            </div>
        </Modal>
    )
}