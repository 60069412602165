import * as React from "react";
import { useEffect, useState } from "react";
import Sidebar from "components/sidebar/sidebar";
import { SidebarItems } from "core/domain/commons/menu";
import "./dashboard.scss";
import MobileNavigation from "components/mobile-navigation/mobile-navigation";
import { useMediaQuery } from "react-responsive";
import { isLogin } from "apis/api";
import { useLocation, useNavigate } from "react-router-dom";
import { setMenuItem } from "core/redux/actions";
import { useAppDispatch } from "core/redux/hooks";
import { WaitingCounts } from "../../../core/domain/dashboard/dashboard";
import { getWaitingCounts } from "../../../core/repositories/dashboard";

interface Props {
  children: React.ReactNode;
}

export const Dashboard = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  var menusJson = localStorage.getItem("menuItems");
  var menus = JSON.parse(menusJson);

  const [waitingCountsLoading, setWaitingCountsLoading] =
    useState<boolean>(false);
  const [waitingCounts, setWaitingCounts] = React.useState<
    WaitingCounts | undefined
  >(undefined);

  function getFilteredItemsByPaths(pathsArray) {
    // Extract 'path' values from the pathsArray
    if (!pathsArray) return SidebarItems;
    const validPaths = pathsArray.map((item) => item.path);
    // Filter the main items where link matches any of the valid paths
    var filteredItems = SidebarItems.filter((item) =>
      validPaths.includes(item.link),
    );
    filteredItems = filteredItems.concat(
      SidebarItems.filter(
        (q) => q.childrens && q.childrens.filter((w) => w.link == validPaths),
      ),
    );
    filteredItems.sort((a, b) => a.id - b.id);
    return filteredItems;
  }

  useEffect(() => {
    if (!isLogin()) navigate("/signin");

    const path = location.pathname;
    var item = SidebarItems.filter(
      (q) => q.link.toLowerCase() === path.toLowerCase(),
    );
    if (item.length === 0)
      item = SidebarItems.filter(
        (q) => q.secondaryLinks?.filter((w) => w.startsWith(path)),
      );

    dispatch(setMenuItem(item[0].id));
  }, []);

  useEffect(() => {
    !waitingCounts &&
      getWaitingCounts(setWaitingCountsLoading, setWaitingCounts);
  }, []);

  const isMobile = useMediaQuery({ query: "(max-width: 820px)" });
  return (
    <div className="pmx-dashboard">
      <Sidebar
        items={getFilteredItemsByPaths(menus)}
        waitingCounts={waitingCounts}
      />
      {waitingCounts && <main>{props.children}</main>}
      {isMobile && (
        <MobileNavigation items={SidebarItems} waitingCounts={waitingCounts} />
      )}
    </div>
  );
};
