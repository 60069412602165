import './remove-modal.scss'
import * as React from "react";
import {Dispatch, FC, SetStateAction, useState} from "react";
import {UserSavedWallets} from "../../../../../core/domain/users-saved-wallets/users-saved-wallets";
import {Modal} from "../../../../../components/modal/modal";
import {Button, buttonType} from "../../../../../components/button/button";
import {removeWallet} from "../../../../../core/repositories/users-saved-wallets";

interface Props {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    item: UserSavedWallets;
    onClose: () => void;
}

export const RemoveWalletModal: FC<Props> = ({isOpen, setIsOpen, item, onClose}) => {
    const [loading, setLoading] = useState<boolean>(false)

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} className={"pmx-remove-wallet-modal"}>
            <p>
                آیا از حذف کیف پول ثبت شده ی {item?.fullName} مطمئن هستید؟
            </p>
            <div>
                <Button type={buttonType.error} text={"بله حذف شود"} loading={loading} onClick={() => removeWallet(setLoading, {id: item.id}, onClose)} />
                <Button type={buttonType.outlined} text={"انصراف"} onClick={() => setIsOpen(false)} />
            </div>
        </Modal>
    )
}