import { API } from "apis";
import { getViaAuth, postViaAuth, responseValidator } from "apis/api";
import { Category, Topic, Content } from "core/domain";
import { Dispatch, SetStateAction } from "react";
import { AddTopicBody } from "../../routes/pages/dashboard/content-management/topics/add/add-topic";
import { EditTopicBody } from "../../routes/pages/dashboard/content-management/topics/edit/edit-topic";
import { AddCategoryBody } from "../../routes/pages/dashboard/content-management/categories/add/add-category";
import { EditCategoryBody } from "../../routes/pages/dashboard/content-management/categories/edit/edit-category";
import { AddContentBody } from "../../routes/pages/dashboard/content-management/contents/add/add-content";
import { EditContentBody } from "../../routes/pages/dashboard/content-management/contents/edit/edit-content";

export const getAllTopics = async (
  setLoading: any,
  setData: any,
  page: number,
  itemsPerPageCount: number,
  onComplete: (firstTopic: Topic) => void,
  query?: string,
) => {
  setLoading(true);
  await getViaAuth<Topic[]>(
    API.content.getAllTopics + `/${page}/${itemsPerPageCount}${query || ""}`,
  ).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      if (response.data) {
        setData(response.data);
        response.data.items[0] && onComplete(response.data.items[0]);
      }
    }
  });
};

// export const getTopicById = async (
//   setLoading: any,
//   setData: any,
//   setPermissionKeys: any,
//   setMenuItemKeys: any,
//   id: string,
// ) => {
//   setLoading(true);
//   await getViaAuth<Topic[]>(API.content.getTopicById + `/${id}`).then(
//     (response: any) => {
//       setLoading(false);
//       if (responseValidator(response.status)) {
//         if (response.data) {
//           setData(response.data);
//           setPermissionKeys(
//             response.data.claims.map((permission) => permission),
//           );
//           setMenuItemKeys(response.data.menuItems.map((menuItem) => menuItem));
//         }
//       }
//     },
//   );
// };

export const getAllCategories = async (
  setLoading: any,
  setData: any,
  page: number,
  itemsPerPageCount: number,
  onComplete: (firstCategory: Category) => void,
  query?: string,
) => {
  setLoading(true);
  await getViaAuth<Category[]>(
    API.content.getAllCategories +
      `/${page}/${itemsPerPageCount}${query || ""}`,
  ).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      console.log(response.data);
      if (response.data) {
        setData(response.data);
        response.data.items[0] && onComplete(response.data.items[0]);
      }
    }
  });
};
export const getAllContents = async (
  setLoading: any,
  setData: any,
  page: number,
  itemsPerPageCount: number,
  query?: string,
) => {
  setLoading(true);
  await getViaAuth<Content[]>(
    API.content.getAllContent + `/${page}/${itemsPerPageCount}${query || ""}`,
  ).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      if (response.data) {
        setData(response.data);
      }
    }
  });
};

export const getTopicById = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
  id: string,
  setData: Dispatch<SetStateAction<EditTopicBody>>,
) => {
  setLoading(true);
  await getViaAuth(`${API.content.getTopicById}/${id}`).then(
    (response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setData(response.data);
      }
    },
  );
};

export const addTopic = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
  data: AddTopicBody,
  onComplete: () => void,
) => {
  setLoading(true);
  await postViaAuth(API.content.addTopic, data).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      onComplete();
    }
  });
};

export const editTopic = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
  data: EditTopicBody,
  onComplete: () => void,
) => {
  setLoading(true);
  await postViaAuth(API.content.editTopic, data).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      onComplete();
    }
  });
};

export const addCategory = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
  data: AddCategoryBody,
  onComplete: () => void,
) => {
  setLoading(true);
  await postViaAuth(API.content.addCategory, data).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      onComplete();
    }
  });
};

export const editCategory = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
  data: EditCategoryBody,
  onComplete: () => void,
) => {
  setLoading(true);
  await postViaAuth(API.content.editCategory, data).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      onComplete();
    }
  });
};

export const getCategoryById = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
  id: string,
  setData: Dispatch<SetStateAction<EditCategoryBody>>,
) => {
  setLoading(true);
  await getViaAuth(`${API.content.getCategoryById}/${id}`).then(
    (response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setData(response.data);
      }
    },
  );
};

export const addContent = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
  data: AddContentBody,
  onComplete: () => void,
) => {
  setLoading(true);
  await postViaAuth(API.content.addContent, data).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      onComplete();
    }
  });
};

export const editContent = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
  data: EditContentBody,
  onComplete: () => void,
) => {
  setLoading(true);
  await postViaAuth(API.content.editContent, data).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      onComplete();
    }
  });
};

export const getContentById = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
  id: string,
  setData: Dispatch<SetStateAction<EditContentBody>>,
) => {
  setLoading(true);
  await getViaAuth(`${API.content.getContentById}/${id}`).then(
    (response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setData(response.data);
      }
    },
  );
};
