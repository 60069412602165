import * as React from "react";
import "./manage-markets.scss";
import { Page } from "components/page/page";
import { Card } from "components/card/card";
import { Table } from "components/table/table";
import { PaginationList, Market } from "core/domain";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  getAllMarkets,
  updateMarket,
} from "../../../../../core/repositories/manage-market";
import { Button, buttonType } from "components/button/button";
import { Input } from "components/input/input";
import { Switch, Tag, Tooltip } from "antd";
import moment from "jalali-moment";
import { getIconPath } from "../../../../../core/utils/getIconPath";
import { getPageNum } from "../../../../../core/utils/paggingHelper";
import { IsPersianChar } from "../../../../../core/utils/stringExtentions";

interface EditableCellProps<T> {
  cell: T;
}

interface FilterValues {
  search: string;
}

const empty_filters = {
  search: "",
};

export const ManageMarkets: React.FC = () => {
  var itemsPerPageCount = 20;

  const [markets, setMarkets] = React.useState<PaginationList<Market>>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
  const [deleteButtonLoading, setDeleteButtonLoading] =
    React.useState<boolean>(false);
  const [query, setQuery] = React.useState<string>("");
  const [filterValues, setFilterValues] =
    React.useState<FilterValues>(empty_filters);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [isEditing, setIsEditing] = React.useState<boolean>();
  const [selectedItem, setSelectedItem] = React.useState<Market>();
  const [nameCellValue, setNameCellValue] = React.useState("");

  const navigate = useNavigate();

  const onNameValueChange = (value: string) => {
    if (value != "") value = value.toString();

    if (value != "" && !IsPersianChar(value)) return;

    setNameCellValue(value);

    var item = selectedItem;
    var newObj = item && {
      ...item,
      name: value,
    };

    setSelectedItem(newObj);
  };

  const EditableNameCell: React.FC<EditableCellProps<Market>> = (props) =>
    isEditing ? (
      selectedItem?.symbol === props.cell.symbol ? (
        <Input
          className={`editable-cell-input `}
          value={selectedItem?.name?.toString()}
          onChange={(e) => onNameValueChange(e.target.value)}
          name="editing"
          dir="ltr"
          placeholder={"نام"}
          autoFocus
        />
      ) : (
        <>{props.cell.name}</>
      )
    ) : (
      <>{props.cell.name}</>
    );

  const onEditMarketClick = (item: Market) => {
    setSelectedItem(item);
    setNameCellValue(selectedItem?.name?.toString());
    setIsEditing(true);
  };

  const onUpdateMarketComplete = () => {
    getAllMarkets(setLoading, setMarkets, getPageNum(), pageSize, query);
    setIsEditing(false);
    setSelectedItem(undefined);
  };

  const onEditMarketSubmit = (item: Market) => {
    if (isEditing && selectedItem?.symbol !== "") {
      updateMarket(
        setLoading,
        setMarkets,
        {
          symbol: item.symbol,
          name: nameCellValue ?? item.name,
          isBuyActive: item.isBuyActive,
          isSellActive: item.isSellActive,
        },
        getPageNum(),
        query,
        setIsEditing,
        setNameCellValue,
      );
    }
  };

  const filterHandle = (clearFilter?: boolean) => {
    var _query = "";

    if (filterValues.search)
      _query += `${_query.includes("?") ? "&" : "?"}keywords=${
        filterValues.search
      }`;

    setQuery(query);
    getAllMarkets(
      clearFilter
        ? setDeleteButtonLoading
        : _query !== ""
        ? setButtonLoading
        : setLoading,
      setMarkets,
      1,
      pageSize,
      clearFilter ? "" : clearFilter ? "" : _query,
    );
  };

  const isActiveHandle = (item: Market, checked: boolean) => {
    setSelectedItem(item);
    updateMarket(
      setLoading,
      setMarkets,
      { symbol: item.symbol, isActive: checked },
      getPageNum(),
      query,
    );
  };

  React.useEffect(() => {
    filterHandle();
  }, []);

  const columns: any[] = [
    {
      name: "نام ارز",
      id: "name",
      selector: (row: Market) => row && <EditableNameCell cell={row} />,
      width: "20%",
    },
    {
      name: "نماد",
      id: "symbol",
      selector: (row: Market) => row.symbol,
      cell: (row: Market) => (
        <>
          <img src={getIconPath(row?.symbol ?? "")} alt={row.symbol} />
          <span>{row?.symbol}</span>
        </>
      ),
      width: "20%",
    },
    {
      name: "خرید",
      id: "isBuyActive",
      cell: (record: Market) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={record?.isBuyActive}
          onChange={(checked) =>
            updateMarket(
              setLoading,
              setMarkets,
              {
                isSellActive: record.isSellActive,
                isBuyActive: checked,
                symbol: record.symbol,
                name: record.name,
              },
              getPageNum(),
              query,
            )
          }
        />
      ),
    },
    {
      name: "فروش",
      id: "isSellActive",
      cell: (record: Market) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={record?.isSellActive}
          onChange={(checked) =>
            updateMarket(
              setLoading,
              setMarkets,
              {
                isBuyActive: record.isBuyActive,
                isSellActive: checked,
                symbol: record.symbol,
                name: record.name,
              },
              getPageNum(),
              query,
            )
          }
        />
      ),
    },

    {
      name: "عملیات",
      id: "action",
      cell: (row: Market) => (
        <div className="actions">
          <Tooltip placement="top" title="اصلاح محتوای ارز">
            <EditOutlined onClick={() => navigate(`edit/${row.symbol}`)} />
          </Tooltip>
          <Tooltip placement="top" title="اصلاح ارز">
            {isEditing ? (
              selectedItem?.symbol === row.symbol ? (
                <CheckOutlined
                  className={`edit-priority-icon `}
                  onClick={() => onEditMarketSubmit(row)}
                />
              ) : (
                <></>
              )
            ) : (
              <EditOutlined onClick={() => onEditMarketClick(row)} />
            )}
          </Tooltip>
        </div>
      ),
    },
  ];
  return (
    <Page className="pmx-markets" title="مدیریت ارز ها">
      <Card title="لیست ارز ها" blur={loading}>
        <div className="filter-row">
          <div className="fields">
            <Input
              value={filterValues.search}
              className="search"
              placeholder="جستجو کنید ..."
              onPressEnter={filterHandle}
              name="search"
              onChange={(e) =>
                setFilterValues({ ...filterValues, search: e.target.value })
              }
            />
            {/* <Dropdown
              defaultSelected={marketLevels[0]}
              items={marketLevels}
              onChangeHandle={(value) =>
                setSelectedLevel(
                  marketLevels.filter((item) => item.id === value)[0]
                )
              }
            /> */}
          </div>
          <div className="buttons">
            <Button
              loading={buttonLoading}
              disabled={buttonLoading}
              text="اعمال فیلتر"
              type={buttonType.success}
              onClick={() => filterHandle()}
            />
            <Button
              type={buttonType.info}
              text="افزودن تاپیک"
              icon={<PlusCircleFilled />}
              onClick={() => navigate("add")}
            />
          </div>
        </div>
        {markets && markets.items && markets?.items.length > 0 && (
          <>
            <Table
              setPageSize={setPageSize}
              blur={loading}
              setloader={setLoading}
              getdata={getAllMarkets}
              setdata={setMarkets}
              className="markets-table"
              data={markets.items}
              column={columns}
              filters={query}
              pagination={markets.pagination}
            />
          </>
        )}
      </Card>
    </Page>
  );
};
