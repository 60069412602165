import {Dispatch, SetStateAction} from "react";
import {WaitingCounts} from "../domain/dashboard/dashboard";
import {getViaAuth, responseValidator} from "../../apis/api";
import {API} from "../../apis";

export const getWaitingCounts = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    setData: Dispatch<SetStateAction<WaitingCounts>>,
) => {
    setLoading(true)
    getViaAuth(API.orders.waitingCount).then((response: any) => {
        setLoading(false)
        if (responseValidator(response.status)) {
            setData(response.data)
        } else {
            setData(
                {
                    buyWaitingForAcceptReceipt: 0,
                    sellWaitingForAcceptReceipt: 0,
                    tradeWaitingForAcceptReceipt: 0
                }
            )
        }
    })
}