import { FC, SetStateAction, useEffect, useState } from "react";
import { Input } from "../../../../../../../components/input/input";
import "./faq.scss";
import { EditMarketFaq } from "../../../../../../../core/domain";
import { editMarketFaqs } from "../../../../../../../core/repositories/manage-market";
import { Card } from "../../../../../../../components/card/card";
import {
  Button,
  buttonType,
} from "../../../../../../../components/button/button";
import { Link, useLocation } from "react-router-dom";
import { Collapse } from "antd";
import {
  CheckCircleFilled,
  DownCircleFilled,
  DownOutlined,
  EditFilled,
  MinusCircleFilled,
  PlusCircleFilled,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import * as React from "react";
import { get, responseValidator } from "../../../../../../../apis/api";
import { API } from "../../../../../../../apis";

const initial_faq = {
  id: "",
  question: "",
  answer: "",
};

export const EditMarketFaqs: FC = (props) => {
  const [newFaqValue, setNewFaqValue] = useState<EditMarketFaq>(initial_faq);
  const [faqs, setFaqs] = useState<EditMarketFaq[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isAddingNewFaq, setIsAddingNewFaq] = useState(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [selectedFaq, setSelectedFaq] = useState<EditMarketFaq>();

  const location = useLocation();
  const marketSymbol =
    location.pathname.split("/")[location.pathname.split("/").length - 1];

  const removeFaqFromListHandle = (comId: string) => {
    if (!comId) {
      console.warn("Faq ID is not provided.");
      return; // Early return if comId is not provided
    }

    const filteredFaqs = faqs?.filter((e) => e.id.toString() !== comId);

    if (filteredFaqs) {
      setFaqs(filteredFaqs);
    }
  };
  const editFaqHandle = (com: EditMarketFaq) => {};

  const onAddNewFaqClickHandle = () => {
    let newFaqs = faqs;
    !newFaqValue.question ||
      !newFaqValue.answer ||
      newFaqs.push({ ...newFaqValue, id: "" });
    setFaqs(newFaqs);
    setIsAddingNewFaq(false);
  };

  async function getMarketFaqsBySymbol() {
    setLoading(true);
    await get(
      API.marketContent.getMarketFaqsBySymbolAdmin + `/${marketSymbol}`,
    ).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status) && response.data) {
        const newfaqs = [];
        response.data.data.map((e) =>
          newfaqs.push({
            key: e.id,
            label: e.question,
            children: (
              <p className={"faq-item"} key={e.id}>
                <div className={"head"}></div>({e.answer}):
                <br />
                {e.text}
                <br />
              </p>
            ),
          }),
        );

        setFaqs(response.data.data);
      }
    });
  }

  const handleEditFaqs = () => {
    editMarketFaqs(setButtonLoading, faqs, marketSymbol);
  };

  const onSaveFaqClickHandle = () => {
    const updatedFaqs = faqs.map((faq) =>
      faq.id === newFaqValue.id ? newFaqValue : faq,
    );

    // Update the faqs state with the new list
    setFaqs(updatedFaqs);
    setIsEditing(false);
  };

  useEffect(() => {
    getMarketFaqsBySymbol();
  }, []);

  const onEditClickHandle = () => {
    selectedFaq && setIsEditing(true);
    setIsAddingNewFaq(false);
    setNewFaqValue(selectedFaq);
  };

  const handleEditorChange = (content, editor) => {
    setFaqs((prevBody) => ({ ...prevBody, text: content }));
  };

  return (
    <Card className={"faqs-stage"} blur={loading}>
      <div className={"head"}>
        <span className={"add-faq-icon"}>
          {isAddingNewFaq ? (
            <div className={"add-head"}>
              <span>ذخیره پرسش و پاسخ</span>
              <CheckCircleFilled
                onClick={() => {
                  onAddNewFaqClickHandle();
                }}
              />
            </div>
          ) : (
            <div className={"add-head"}>
              <span>اضافه کردن پرسش و پاسخ</span>

              <PlusCircleFilled
                onClick={() => {
                  setNewFaqValue(initial_faq);
                  setIsAddingNewFaq(true);
                  setIsEditing(false);
                }}
              />
            </div>
          )}
        </span>
      </div>

      {isAddingNewFaq && (
        <div className={"edit-faq-input"}>
          <h4>اضافه کردن نظر</h4>

          <div className={"textarea"}>
            <label>متن سوال</label>
            <TextArea
              name="attachedMessage"
              value={newFaqValue?.question}
              onChange={(e) =>
                setNewFaqValue({
                  ...newFaqValue,
                  question: e.target.value,
                })
              }
            />
          </div>
          <div className={"textarea"}>
            <label>متن جواب</label>
            <TextArea
              name="attachedMessage"
              value={newFaqValue?.answer}
              onChange={(e) =>
                setNewFaqValue({
                  ...newFaqValue,
                  answer: e.target.value,
                })
              }
            />
          </div>
        </div>
      )}

      <div className={"faqs"}>
        {faqs?.map((faq) => (
          <div className={"faq-item"} key={faq.id}>
            <div className={"head"}>
              <span className={"question"}>{faq.question}</span>
              <span className={"remove-faq"}>
                <MinusCircleFilled
                  onClick={() => removeFaqFromListHandle(faq.id)}
                />
              </span>
              <span className={"edit-faq"}>
                {selectedFaq?.id === faq?.id ? (
                  isEditing ? (
                    <CheckCircleFilled
                      onClick={() =>
                        selectedFaq && newFaqValue && onSaveFaqClickHandle()
                      }
                      className={"check-icon"}
                    />
                  ) : (
                    <EditFilled onClick={() => onEditClickHandle()} />
                  )
                ) : (
                  <></>
                )}
              </span>
              <span
                className={`toggle-faq ${
                  selectedFaq && selectedFaq?.id === faq.id ? "active" : ""
                }`}
              >
                <DownCircleFilled
                  onClick={(event) => {
                    if (!selectedFaq) {
                      setSelectedFaq(faq);
                    } else {
                      setSelectedFaq(undefined);
                      setIsEditing(false);
                      setIsAddingNewFaq(false);
                    }
                  }}
                />
              </span>
            </div>
            {selectedFaq && selectedFaq?.id === faq.id && (
              <>
                <div className={"faq-text"}>
                  <b>سوال: </b>
                  {faq.question}
                </div>
                <div className={"faq-text"}>
                  <b>پاسخ: </b>
                  {faq.answer}
                </div>
              </>
            )}
            {isEditing && selectedFaq?.id === faq.id && (
              <div className={"edit-faq-input"}>
                <h4>ویرایش پرسش و پاسخ</h4>
                <div className={"textarea"}>
                  <label>متن سوال</label>
                  <TextArea
                    name="attachedMessage"
                    value={newFaqValue?.question}
                    onChange={(e) =>
                      setNewFaqValue({
                        ...newFaqValue,
                        question: e.target.value,
                      })
                    }
                  />
                </div>
                <div className={"textarea"}>
                  <label>متن پاسخ</label>
                  <TextArea
                    name="attachedMessage"
                    value={newFaqValue?.answer}
                    onChange={(e) =>
                      setNewFaqValue({
                        ...newFaqValue,
                        answer: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      <Button
        type={buttonType.info}
        text={"ویرایش پرسش و پاسخ ها"}
        onClick={handleEditFaqs}
        loading={buttonLoading}
        disabled={buttonLoading}
      />
      <Link to={"/dashboard/content/manage-markets"}>
        <Button
          type={buttonType.primary}
          text={"بازگشت به صفحه محتواهای ارزها"}
        />
      </Link>
    </Card>
  );
};
