import * as React from "react";
import "./gateway-transactions.scss";
import {Page} from "components/page/page";
import {Card} from "components/card/card";
import {Table} from "components/table/table";
import {GatewayTransaction} from "core/domain";
import {useNavigate} from "react-router-dom";
import {Button, buttonType} from "components/button/button";
import {Input} from "components/input/input";
import {PaginationList} from "../../../../core/domain/commons/common";
import {getPageNum} from "core/utils/paggingHelper";
import moment from "jalali-moment";
import {getAllGatewayTransactions} from "../../../../core/repositories/gateway-transaction";
import {digitSperator} from "../../../../core/utils/decimalExtentions";

interface FilterValues {
    search: string;
}

const empty_filters = {
    search: "",
};

export const GatewayTransactions: React.FC = () => {
    var itemsPerPageCount = 20;

    const [gatewayTransactions, setGatewayTransactions] =
        React.useState<PaginationList<GatewayTransaction>>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
    const [deleteButtonLoading, setDeleteButtonLoading] =
        React.useState<boolean>(false);
    const [filterValues, setFilterValues] =
        React.useState<FilterValues>(empty_filters);
    const [pageSize, setPageSize] = React.useState<number>(10);
    const [query, setQuery] = React.useState<string>("");

    const navigate = useNavigate();

    const clearFiltersHandle = () => {
        setFilterValues(empty_filters);
        filterHandle(true);
    };

    const filterHandle = (clearFilter?: boolean) => {
        var _query = "";

        if (filterValues.search)
            _query += `${_query.includes("?") ? "&" : "?"}trackId=${
                filterValues.search
            }`;

        setQuery(_query);
        getAllGatewayTransactions(
            clearFilter
                ? setDeleteButtonLoading
                : _query !== ""
                    ? setButtonLoading
                    : setLoading,
            setGatewayTransactions,
            getPageNum(),
            pageSize,
            clearFilter ? "" : _query
        );
    };

    React.useEffect(() => {
        getAllGatewayTransactions(
            setLoading,
            setGatewayTransactions,
            getPageNum(),
            pageSize
        );
    }, []);

    const columns: any[] = [
        {
            name: "کد پیگیری",
            id: "trackId",
            selector: (row: GatewayTransaction) => row.trackId,
            width: "15%",
        },
        {
            name: "شماره کارت",
            id: "cardNumber",
            selector: (row: GatewayTransaction) => row.cardNumber,
            width: "15%",
        },
        {
            name: "مقدار",
            id: "amount",
            selector: (row: GatewayTransaction) => digitSperator(row.amount),
            width: "15%",
        },
        {
            name: "کد سفارش",
            id: "orderId",
            selector: (row: GatewayTransaction) => row.orderId,
            width: "15%",
        },
        {
            name: "توضیحات",
            id: "description",
            selector: (row: GatewayTransaction) => row.description,
            width: "15%",
        },
        {
            name: "تاریخ پرداخت",
            id: "paidAt",
            width: "15%",
            selector: (row: GatewayTransaction) => row.paidAt,
            cell: (record: GatewayTransaction) => (
                <span>
          {moment.utc(record?.paidAt).locale("fa").local().format("HH:mm")}{" "}
                    {moment.utc(record?.paidAt).locale("fa").local().format("YYYY/MM/DD")}{" "}
        </span>
            ),
        },
    ];

    return (
        <Page className='pmx-zibal-transactions' title='تراکنش های درگاه فعال'>
            <Card title='لیست تراکنش ها' blur={loading}>
                <div className='filter-row'>
                    <div className='fields'>
                        <Input
                            value={filterValues.search}
                            className='search'
                            placeholder='جستجو کنید ...'
                            name='search'
                            onPressEnter={filterHandle}
                            onChange={(e) =>
                                setFilterValues({...filterValues, search: e.target.value})
                            }
                        />
                        {/* <Dropdown
              defaultSelected={zibalTransactionLevels[0]}
              items={zibalTransactionLevels}
              onChangeHandle={(value) =>
                setSelectedLevel(
                  zibalTransactionLevels.filter((item) => item.id === value)[0]
                )
              }
            /> */}
                    </div>
                    <div className='buttons'>
                        <Button
                            loading={buttonLoading}
                            disabled={buttonLoading}
                            text='اعمال فیلتر'
                            type={buttonType.success}
                            onClick={() => filterHandle()}
                        />
                    </div>
                </div>
                {gatewayTransactions?.items &&
                    gatewayTransactions?.items.length > 0 && (
                        <>
                            <Table
                                blur={loading}
                                setloader={setLoading}
                                getdata={getAllGatewayTransactions}
                                setdata={setGatewayTransactions}
                                pagination={gatewayTransactions.pagination}
                                className='zibal-transactions-table'
                                data={gatewayTransactions.items}
                                column={columns}
                                filters={query}
                                setPageSize={setPageSize}
                            />
                        </>
                    )}
            </Card>
        </Page>
    );
};
