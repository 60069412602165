import * as React from "react";
import "./user-by-phone-number.scss";
import { Page } from "components/page/page";
import { Card } from "components/card/card";
import { Table } from "components/table/table";
import { UserByPhoneNumberType } from "core/domain";
import { Badge } from "components";
import { EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  getAllLevels,
  getAllRoles,
  getAllUsers,
  getUserByPhoneNumber,
} from "core/repositories/user";
import { Button, buttonType } from "components/button/button";
import { Input } from "components/input/input";
// import { ClientSidePagination } from "components/pagination/pagination";
import { Dropdown } from "../../../../components/dropdown/dropdown";
import {
  Enum,
  EnumAsString,
  PaginationList,
} from "../../../../core/domain/commons/common";
import moment from "jalali-moment";
import { getPageNum } from "core/utils/paggingHelper";

interface FilterValues {
  search: string;
}

const defaultSelectedRole: EnumAsString = {
  id: "0",
  title: "همه نقش ",
};
const defaultSelectedLevels: EnumAsString = {
  id: "0",
  title: "همه سطوح",
};
const defaultStringSelected: EnumAsString = {
  id: "0",
  title: "همه",
};

const authMediaIsConfirmedTypes = [
  {
    id: 0,
    title: "همه وضعیت سلفی",
  },
  {
    id: 1,
    title: "سلفی تایید شده",
  },
  {
    id: 2,
    title: "سلفی تایید نشده",
  },
];

const isActiveTypes: Enum[] = [
  {
    id: 0,
    title: "همه وضعیت فعالیت",
  },
  {
    id: 1,
    title: "کاربر فعال",
  },
  {
    id: 2,
    title: "کاربر غیرفعال",
  },
];

const isActiveTypeDefualtValue = {
  id: 0,
  title: "همه وضعیت فعالیت",
};
const authMediaIsConfirmedTypesDefualtValue = {
  id: 0,
  title: "همه وضعیت سلفی",
};
const empty_filters = {
  search: "",
};

export const SearchUserByPhoneNumber: React.FC = () => {
  const [users, setUsers] = React.useState<UserByPhoneNumberType[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);

  const [query, setQuery] = React.useState<string>("");
  const [filterValues, setFilterValues] =
    React.useState<FilterValues>(empty_filters);
  const [pageSize, setPageSize] = React.useState<number>(10);

  const navigate = useNavigate();

  const clearFiltersHandle = () => {
    setFilterValues(empty_filters);
    filterHandle(true);
  };

  const filterHandle = (clearFilter?: boolean) => {
    var _query = "";

    if (filterValues.search) _query += `${filterValues.search}`;

    setQuery(_query);

    getUserByPhoneNumber(
      _query !== "" ? setButtonLoading : setLoading,
      setUsers,
      _query
    );
  };

  React.useEffect(() => {
    fetches();
  }, []);

  async function fetches() {}

  const columns: any[] = [
    {
      name: "نام و نام‌ خانوادگی",
      id: "fullName",
      selector: (row: UserByPhoneNumberType, rowIndex: number) =>
        row.fullName || "-",
      width: "20%",
    },
    {
      name: "کد ملی",
      id: "nationalCode",
      selector: (row: UserByPhoneNumberType) => row?.nationalCode,
      cell: (row: UserByPhoneNumberType) => (
        <span className='en'>{row?.nationalCode || "-"}</span>
      ),
      width: "10%",
    },
    {
      name: "موبایل",
      id: "phoneNumber",
      selector: (row: UserByPhoneNumberType) => row?.phoneNumber,
      cell: (row: UserByPhoneNumberType) => (
        <span className='en'>{row?.phoneNumber || "-"}</span>
      ),
      width: "10%",
    },
    {
      name: "تاریخ عضویت",
      id: "joinedDate",
      selector: (row: UserByPhoneNumberType) => row?.joinedDate,
      cell: (row: UserByPhoneNumberType) => (
        <span>{moment(row?.joinedDate).format("jYYYY/MM/DD")}</span>
      ),
      width: "10%",
    },
    {
      name: "وضعیت",
      id: "state",
      width: "10%",
      selector: (row: UserByPhoneNumberType) => row.isActive,
      cell: (row: UserByPhoneNumberType) => (
        <Badge
          text={row.isActive ? "فعال" : "غیرفعال"}
          type={row.isActive ? "success" : "error"}
        />
      ),
    },
    {
      name: "سطح کاربر",
      id: "level",
      width: "20%",
      selector: (row: UserByPhoneNumberType) => row?.level?.title,
      cell: (row: UserByPhoneNumberType) =>
        row.level?.title && <Badge text={row?.level?.title} type={"info"} />,
    },
    {
      name: "وضعیت تایید سلفی",
      id: "authMediaIsConfirmed",
      width: "10%",
      selector: (row: UserByPhoneNumberType) => row?.authMediaSurveyIsConfirmed,
      cell: (row: UserByPhoneNumberType) => (
        <Badge
          text={
            row?.authMediaSurveyIsConfirmed === true
              ? "تایید شده"
              : "تایید نشده"
          }
          type={row.authMediaSurveyIsConfirmed === true ? "success" : "error"}
        />
      ),
    },
    {
      name: "عملیات",
      id: "action",
      width: "10%",
      cell: (row: UserByPhoneNumberType) => (
        <div className='actions'>
          <EditOutlined
            onClick={() => {
              navigate(`/dashboard/users/edit/${row.id}`);
            }}
          />
        </div>
      ),
    },
  ];
  return (
    <Page
      className='pmx-user-by-phone-number'
      title='جستجوی کاربر با شماره موبایل'
    >
      <Card title='نمایش کاربر' blur={loading}>
        <div className='filter-row'>
          <div className='fields'>
            <Input
              value={filterValues.search}
              className='search'
              placeholder='شماره موبایل کاربر را وارد کنید'
              name='search'
              onPressEnter={filterHandle}
              onChange={(e) =>
                setFilterValues({ ...filterValues, search: e.target.value })
              }
            />
          </div>
          <div className='buttons'>
            <Button
              loading={buttonLoading}
              disabled={buttonLoading}
              text='اعمال فیلتر'
              type={buttonType.success}
              onClick={() => filterHandle()}
            />
          </div>
        </div>

        <>
          <Table
            setPageSize={setPageSize}
            blur={loading}
            setloader={setLoading}
            getdata={getAllUsers}
            setdata={setUsers}
            className='users-table'
            data={users}
            column={columns}
            filters={query}
          />
        </>
      </Card>
    </Page>
  );
};
