import * as React from "react";
import {DoubleLeftOutlined, DoubleRightOutlined, DownOutlined, LeftOutlined, RightOutlined,} from "@ant-design/icons";
import {Button, buttonType} from "components/button/button";
import "./pagination.scss";
import {useSearchParams} from "react-router-dom";
import {digitSperator} from "../../core/utils/decimalExtentions";
import {Dropdown, Menu} from "antd";

const {useState} = React;

type Props = {
    data: any[];
    setdata: any;
    getdata: any;
    setloader: any;
    page: number;
    totalRows: number;
    size: number;
    query?: string;
    setPageSize: React.Dispatch<React.SetStateAction<number>>;
};

const pageSizeNumbers = [
    {id: 10, title: "10"},
    {id: 20, title: "20"},
    {id: 50, title: "50"},
    {id: 100, title: "100"},
    {id: 500, title: "500"},
    {id: 1000, title: "1000"},

];

export const Pagination: React.FC<Props> = ({
                                                totalRows,
                                                getdata,
                                                setloader,
                                                page,
                                                data,
                                                setdata,
                                                size,
                                                query,
                                                setPageSize,
                                            }) => {
    const [totalPage, setTotalPage] = useState<number>(0);
    const [minPageLimit, setMinPageLimit] = useState<number>(0);
    const [maxPageLimit, setMaxPageLimit] = useState<number>(0);

    const [searchParams, setSearchParams] = useSearchParams();

    const onPrevPage = () => {
        page !== 1 && getdata(setloader, setdata, page - 1, size, query);
    };
    const onNextPage = () => {
        page !== totalPage && getdata(setloader, setdata, page + 1, size, query);
    };

    React.useEffect(() => {
        setTotalPage(Math.ceil(totalRows / size));
    }, []);

    React.useEffect(() => {
        totalPage && page > totalPage - 4
            ? setMinPageLimit(totalPage - 4)
            : page < 5
                ? setMinPageLimit(1)
                : setMinPageLimit(page);

        totalPage && page > totalPage - 4
            ? setMaxPageLimit(totalPage)
            : page < 5
                ? setMaxPageLimit(5)
                : setMaxPageLimit(page + 4);

        setSearchParams({["page"]: page.toString()});
    }, [page]);

    const onPageSizeChange = (size: number) => {
        setPageSize && setPageSize(size);
        getdata(setloader, setdata, page, size, query);
    };

    return (
        <div className='pmx-pagination'>
            <div className='pagination-row'>
                <DoubleRightOutlined
                    className={`${page == 1 || totalPage <= 1 ? "disabled" : ""}`}
                    onClick={() =>
                        page != 1 && getdata(setloader, setdata, 1, size, query)
                    }
                />
                <RightOutlined
                    onClick={() => onPrevPage()}
                    className={`${page == 1 || totalPage <= 1 ? "disabled" : ""}`}
                />
                <div className='pages'>
                    {minPageLimit > 1 && <Button type={buttonType.outlined} text='...'/>}
                    {Array.from(
                        {length: Math.ceil(totalRows / size)},
                        (item, index) => index + 1
                    ).map((pageItem) => (
                        <>
                            {pageItem >= minPageLimit && pageItem <= maxPageLimit && (
                                <Button
                                    onClick={() =>
                                        getdata(setloader, setdata, pageItem, size, query)
                                    }
                                    text={pageItem.toString()}
                                    type={
                                        pageItem === page ? buttonType.info : buttonType.outlined
                                    }
                                />
                            )}
                        </>
                    ))}
                    {totalPage > maxPageLimit && (
                        <Button type={buttonType.outlined} text='...'/>
                    )}
                </div>
                <LeftOutlined
                    className={`${page == totalPage || totalPage <= 1 ? "disabled" : ""}`}
                    onClick={() => onNextPage()}
                />
                <DoubleLeftOutlined
                    className={`${page == totalPage || totalPage <= 1 ? "disabled" : ""}`}
                    onClick={() =>
                        page != totalPage &&
                        getdata(setloader, setdata, totalPage, size, query)
                    }
                />
                <Dropdown
                    trigger={["click", "hover"]}
                    overlay={
                        <Menu>
                            {pageSizeNumbers &&
                                pageSizeNumbers.map((pageSize, index) => (
                                    <Menu.Item
                                        key={index}
                                        onClick={() => onPageSizeChange(pageSize.id)}
                                    >
                                        {pageSize.title}
                                    </Menu.Item>
                                ))}
                        </Menu>
                    }
                    placement='bottom'
                >
                    <Button
                        icon={<DownOutlined/>}
                        type={buttonType.outlined}
                        text={size.toString()}
                    />
                </Dropdown>
            </div>
            {totalPage > 1 && (
                <div className='foot'>
                    نمایش <b>{(page - 1) * size}</b> تا{" "}
                    <b>{page == totalPage ? totalRows : page * size}</b> از{" "}
                    <b>{digitSperator(totalRows)}</b> ردیف
                </div>
            )}
        </div>
    );
};
