import {Dispatch, SetStateAction} from "react";
import {getViaAuth, postViaAuth, put, responseValidator} from "../../apis/api";
import {PaginationList, } from "../domain";
import {API} from "../../apis";
import {
    AddUserSavedIBansBody,
    EditUserSavedIBansBody, RemoveUserSavedIBansBody,
    UserSavedIBans
} from "../domain/users-saved-ibans/users-saved-ibans";

export const getAllIBans = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    setData: Dispatch<SetStateAction<any>>,
    page: number,
    pageSize: number,
    query?: string
) => {
    setLoading(true)
    await getViaAuth<PaginationList<UserSavedIBans>>(
        API.userSavedIBans.getAll + `/${page}/${pageSize}${query || ""}`,
    ).then((response: any) => {
        setLoading(false);
        if (responseValidator(response.status)) {
            if (response.data) {
                setData(response.data);
            }
        }
    });
}

export const addIBan = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    data: AddUserSavedIBansBody,
    onComplete: () => void,
) => {
    setLoading(true)
    await postViaAuth(API.userSavedIBans.add, data).then((response: any) => {
        setLoading(false)
        if (responseValidator(response.status)) {
            onComplete()
        }
    })
}

export const editIBan = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    data: EditUserSavedIBansBody,
    onComplete: () => void
) => {
    setLoading(true)
    await postViaAuth(API.userSavedIBans.edit, data).then((response: any) => {
        setLoading(false)
        if (responseValidator(response.status)) {
            onComplete()
        }
    })
}

export const removeIBan = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    data: RemoveUserSavedIBansBody,
    onComplete: () => void
)=> {
    setLoading(true)
    await postViaAuth(API.userSavedIBans.remove, data).then((response: any) => {
        setLoading(false)
        if (responseValidator(response.status)) {
            onComplete()
        }
    })
}

export const getIBanById = (
    setLoading: Dispatch<SetStateAction<boolean>>,
    id: string,
    setData: Dispatch<SetStateAction<any>>,
    onComplete: () => void
) => {
    setLoading(true)
    getViaAuth(`${API.userSavedIBans.getById}/${id}`).then((response: any) => {
        setLoading(false)
        if (responseValidator(response.status)) {
            setData(response.data)
            onComplete()
        }
    })
}