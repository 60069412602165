import * as React from "react";
import "./badge.scss";

interface Props {
  className?: string;
  type: "success" | "error" | "primary" | "info" | "secondary" | "waiting";
  text: string | number;
  icon?: string;
}

export const Badge: React.FC<Props> = (props) => {
  return (
    <div
      className={`pmx-badge${
        props.className && props.className !== "" ? props.className : ""
      }`}
    >
      <div className={props.type}>
        {props.icon && <img src={props.icon} />}
        <span>{props.text}</span>
      </div>
    </div>
  );
};
