export function convertArrayOfObjectsToCSV(array: any) {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(array[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;


            if (item[key] && typeof (item[key]) === "object" && (Object.hasOwn(item[key], "title"))) {
                result += item[key].title;
            } else
                result += item[key];
            // eslint-disable-next-line no-plusplus
            ctr++;
        });
        result += lineDelimiter;
    });

    return result;
}

export function downloadCSV(fileName: string, data: any[]) {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(data);
    if (csv == null) return;

    const filename = `${fileName}.csv`;

    if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
}
