import * as React from "react";
import "./contents.scss";
import { Page } from "components/page/page";
import { Card } from "components/card/card";
import { Table } from "components/table/table";
import { Content, PaginationList, Topic } from "core/domain";
import { Badge } from "components";
import {
  CopyOutlined,
  EditOutlined,
  InfoCircleOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { getAllContents } from "core/repositories/content";
import { Button, buttonType } from "components/button/button";
import { Input } from "components/input/input";
import { Tag } from "antd";
import moment from "jalali-moment";

interface FilterValues {
  search: string;
}

const empty_filters = {
  search: "",
};

export const Contents: React.FC = () => {
  var itemsPerPageCount = 20;

  const [contents, setContents] = React.useState<PaginationList<Content>>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
  const [deleteButtonLoading, setDeleteButtonLoading] =
    React.useState<boolean>(false);
  const [query, setQuery] = React.useState<string>("");
  const [filterValues, setFilterValues] =
    React.useState<FilterValues>(empty_filters);
  const [pageSize, setPageSize] = React.useState<number>(10);

  const navigate = useNavigate();

  const filterHandle = (clearFilter?: boolean) => {
    var _query = "";

    if (filterValues.search)
      _query += `${_query.includes("?") ? "&" : "?"}keywords=${
        filterValues.search
      }`;

    setQuery(query);
    getAllContents(
      clearFilter
        ? setDeleteButtonLoading
        : _query !== ""
        ? setButtonLoading
        : setLoading,
      setContents,
      1,
      pageSize,
      clearFilter ? "" : clearFilter ? "" : _query,
    );
  };

  React.useEffect(() => {
    filterHandle();
  }, []);

  const columns: any[] = [
    {
      name: "عنوان",
      id: "title",
      selector: (row: Content) => row.title,
      width: "20%",
    },
    {
      name: "توضیحات",
      id: "description",
      selector: (row: Content) => row.description,
      width: "128px",
    },

    {
      name: "تاریخ ایجاد",
      id: "createdAt",
      selector: (row: Topic) => row?.createdAt || "",
      sortable: true,
      width: "15%",
      cell: (row: Topic) => (
        <span>
          {moment
            .utc(row?.createdAt)
            .locale("fa")
            .local()
            .format("HH:mm")}{" "}
          {moment
            .utc(row?.createdAt)
            .locale("fa")
            .local()
            .format("YYYY/MM/DD")}{" "}
        </span>
      ),
    },
    {
      name: "تاریخ آخرین بروزرسانی",
      id: "lastModifiedDate",
      selector: (row: Topic) => row?.lastModifiedDate || "",
      sortable: true,
      width: "15%",
      cell: (row: Topic) =>
        row.lastModifiedDate ? (
          <span>
            {moment
              .utc(row?.lastModifiedDate)
              .locale("fa")
              .local()
              .format("HH:mm")}{" "}
            {moment
              .utc(row?.lastModifiedDate)
              .locale("fa")
              .local()
              .format("YYYY/MM/DD")}{" "}
          </span>
        ) : (
          "-"
        ),
    },

    {
      name: "عملیات",
      id: "action",
      cell: (row: Content) => (
        <div className="actions">
          <EditOutlined onClick={() => navigate(`edit/${row.id}`)} />
        </div>
      ),
    },
  ];
  return (
    <Page className="pmx-contents" title="محتوا ها">
      <Card title="لیست محتوا ها" blur={loading}>
        <div className="filter-row">
          <div className="fields">
            <Input
              value={filterValues.search}
              className="search"
              placeholder="جستجو کنید ..."
              onPressEnter={filterHandle}
              name="search"
              onChange={(e) =>
                setFilterValues({ ...filterValues, search: e.target.value })
              }
            />
            {/* <Dropdown
              defaultSelected={contentLevels[0]}
              items={contentLevels}
              onChangeHandle={(value) =>
                setSelectedLevel(
                  contentLevels.filter((item) => item.id === value)[0]
                )
              }
            /> */}
          </div>
          <div className="buttons">
            <Button
              loading={buttonLoading}
              disabled={buttonLoading}
              text="اعمال فیلتر"
              type={buttonType.success}
              onClick={() => filterHandle()}
            />
            <Button
              type={buttonType.info}
              text="افزودن محتوا"
              icon={<PlusCircleFilled />}
              onClick={() => navigate("add")}
            />
          </div>
        </div>
        {contents && contents.items && contents?.items.length > 0 && (
          <>
            <Table
              setPageSize={setPageSize}
              blur={loading}
              setloader={setLoading}
              getdata={getAllContents}
              setdata={setContents}
              className="contents-table"
              data={contents.items}
              column={columns}
              filters={query}
              pagination={contents.pagination}
            />
          </>
        )}
      </Card>
    </Page>
  );
};
