import './first-buys.scss';
import { FC, useEffect, useState } from 'react';
import moment from "jalali-moment";
import { Card } from "../../../../../../components/card/card";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import {FirstBuysChartProps} from "../../../../../../core/domain/overview/first-buys";

Exporting(Highcharts);
ExportData(Highcharts);

interface Props {
    chartData: FirstBuysChartProps;
    chartLoading: boolean;
}

interface FormattedData {
    dateTime: string;
    value: number;
}

export const FirstBuys: FC<Props> = ({ chartData, chartLoading }) => {
    const [options, setOptions] = useState<Highcharts.Options>({});

    useEffect(() => {
        const formatData = (registeredUserCount: { dateTime: string, value: number }[]): FormattedData[] => {
            const formattedData: { [key: string]: FormattedData } = {};

            registeredUserCount.forEach((item) => {
                const date = moment(item.dateTime).locale("fa").local().format("jYYYY/MM/DD");
                if (!formattedData[date]) {
                    formattedData[date] = { dateTime: date, value: 0 };
                }
                formattedData[date].value = item.value;
            });

            return Object.values(formattedData);
        };

        if (chartData) {
            const combinedData = formatData(chartData.firstBuyUsers);
            const dates = combinedData.map(item => item.dateTime);
            const values = combinedData.map(item => item.value);

            setOptions({
                chart: {
                    style: {
                        fontFamily: "YekanBakh",
                        fontSize: '16px'
                    }
                },
                lang: {
                    thousandsSep: ',',
                    downloadPNG: 'PNG دانلود',
                    downloadJPEG: 'JPEG دانلود',
                    downloadPDF: 'PDF دانلود',
                    downloadSVG: 'SVG دانلود',
                    downloadCSV: 'CSV دانلود',
                    downloadXLS: 'XLS دانلود'
                },
                tooltip: {
                    style: {
                        fontSize: "16px"
                    },
                    formatter: function() {
                        return '<b>' + this.x + '</b><br>' +
                            '<span style="color:' + this.series.color + '"><span>' + this.series.name + '</span>: ' + '<b>' + Highcharts.numberFormat(this.y, 0, '.', ',') + '</b>' + '</span>';
                    }
                },
                title: {
                    text: 'خرید های اول'
                },
                xAxis: {
                    categories: dates
                },
                yAxis: {
                    title: {
                        text: 'تعداد'
                    },
                    labels: {
                        formatter: function () {
                            const value = this.value as number;
                            if (value >= 1_000_000) {
                                return (value / 1_000_000) + ' میلیون';
                            } else if (value >= 1_000) {
                                return (value / 1_000) + ' هزار';
                            } else {
                                return value.toString();
                            }
                        },
                        style: {
                            fontFamily: 'YekanBakh',
                            direction: 'rtl',
                        },
                    }
                },
                series: [{
                    name: 'خرید های اول',
                    data: values,
                    type: 'spline',
                    color: '#4CAF50'
                }],
                exporting: {
                    enabled: true,
                    buttons: {
                        contextButton: {
                            menuItems: [
                                'downloadPNG',
                                'downloadJPEG',
                                'downloadPDF',
                                'downloadSVG',
                                'downloadCSV',
                                'downloadXLS'
                            ],
                        }
                    }
                },
                credits: {
                    enabled: false
                }
            });
        }
    }, [chartData]);

    return (
        <Card title='خرید های اول' blur={chartLoading} className={"pmx-first-buys"}>
            {chartData && (
                <HighchartsReact highcharts={Highcharts} options={options} />
            )}
        </Card>
    );
};
