import * as React from "react";
import "./mobile-navigation.scss";
import { ReactComponent as ReceiptIcon } from "assets/icons/dash-line-icons/receipt.svg";
import { ReactComponent as CartIcon } from "assets/icons/dash-line-icons/cart.svg";
import { ReactComponent as CandyBoxMenuIcon } from "assets/icons/dash-line-icons/candy box menu.svg";
import { Link } from "react-router-dom";
import { RootState } from "core/redux/store";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { MenuItem } from "core/domain/commons/common";
import { setMenuItem, setSidebar } from "core/redux/actions";
import MobileNavigationbar from "../mobile-navigationbar/mobile-navigationbar";
import { UserOutlined } from "@ant-design/icons";
import {WaitingCounts} from "../../core/domain/dashboard/dashboard";
import {Simulate} from "react-dom/test-utils";
import waiting = Simulate.waiting;

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  items: MenuItem[];
  waitingCounts?: WaitingCounts;
}

const navigationItems = [
  {
    id: 2,
    name: "کاربران",
    icon: <UserOutlined />,
    link: "/dashboard/users",
  },
  {
    id: -1,
    name: "منو",
    icon: <CandyBoxMenuIcon />,
    className: "menu-button",
  },
  {
    id: 6,
    name: "سفارشات",
    icon: <ReceiptIcon />,
    link: "/dashboard/orders",
  },
];
const MobileNavigation: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const onMenuButtonClickHandle = (item: any) =>
    !item.link
      ? props.state.sidebar == "open"
        ? dispatch(setSidebar("close"))
        : dispatch(setSidebar("open"))
      : dispatch(setMenuItem(item.id));
  return (
    <div
      className={`mobile-navigation mobile-show ${
        props.state.sidebar == "open" ? "active" : ""
      }`}
    >
      {navigationItems.map((item, index) => (
        <Link
          to={item.link || ""}
          className={`item ${item.className || ""} ${
            props.state.menu == item.id ? "active" : ""
          }`}
          onClick={() => onMenuButtonClickHandle(item)}
        >
          <div className="icon">{item.icon}</div>
          <span>{item.name}</span>
        </Link>
      ))}
      <MobileNavigationbar waitingCounts={props.waitingCounts}/>
    </div>
  );
};

export default connector(MobileNavigation);
