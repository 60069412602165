import './crypto-count-buy-and-sell.scss';
import { FC, useEffect, useState } from 'react';
import moment from "jalali-moment";
import { Card } from "../../../../../../components/card/card";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import { CryptoCountBuyAndSellChartProps } from "../../../../../../core/domain/overview/crypto-count-buy-and-sell";

Exporting(Highcharts);
ExportData(Highcharts);

interface Props {
    chartData: CryptoCountBuyAndSellChartProps;
    chartLoading: boolean;
}

interface FormattedData {
    dateTime: string;
    buys: number;
    sells: number;
}

export const CryptoCountBuyAndSell: FC<Props> = ({ chartData, chartLoading }) => {
    const [options, setOptions] = useState<Highcharts.Options>({});

    useEffect(() => {
        const formatData = (buys: { dateTime: string, value: number }[], sells: { dateTime: string, value: number }[]): FormattedData[] => {
            const formattedData: { [key: string]: FormattedData } = {};

            buys.forEach((item) => {
                const date = moment(item.dateTime).locale("fa").local().format("jYYYY/MM/DD");
                if (!formattedData[date]) {
                    formattedData[date] = { dateTime: date, buys: 0, sells: 0 };
                }
                formattedData[date].buys = item.value;
            });

            sells.forEach((item) => {
                const date = moment(item.dateTime).locale("fa").local().format("jYYYY/MM/DD");
                if (!formattedData[date]) {
                    formattedData[date] = { dateTime: date, buys: 0, sells: 0 };
                }
                formattedData[date].sells = item.value;
            });

            return Object.values(formattedData);
        };

        if (chartData) {
            const combinedData = formatData(chartData.cryptoBuys, chartData.cryptoSells);
            const dates = combinedData.map(item => item.dateTime);
            const buys = combinedData.map(item => item.buys);
            const sells = combinedData.map(item => item.sells);

            setOptions({
                chart: {
                    style: {
                        fontFamily: "YekanBakh",
                        fontSize: '16px'
                    }
                },
                lang: {
                    thousandsSep: ',',
                    downloadPNG: 'PNG دانلود',
                    downloadJPEG: 'JPEG دانلود',
                    downloadPDF: 'PDF دانلود',
                    downloadSVG: 'SVG دانلود',
                    downloadCSV: 'CSV دانلود',
                    downloadXLS: 'XLS دانلود'
                },
                tooltip: {
                    style: {
                        fontSize: "16px"
                    },
                    formatter: function() {
                        return '<b>' + this.x + '</b><br>' +
                            '<span style="color:' + this.series.color + '"><span>' + this.series.name + '</span>: ' + '<b>' + Highcharts.numberFormat(this.y, 0, '.', ',') + '</b>' + '</span>';
                    }
                },
                title: {
                    text: 'تعداد خرید و فروش های رمز ارزی'
                },
                xAxis: {
                    categories: dates
                },
                yAxis: {
                    title: {
                        text: 'تعداد'
                    },
                    labels: {
                        formatter: function () {
                            const value = this.value as number;
                            if (value >= 1_000_000) {
                                return (value / 1_000_000) + ' میلیون';
                            } else if (value >= 1_000) {
                                return (value / 1_000) + ' هزار';
                            } else {
                                return value.toString();
                            }
                        },
                        style: {
                            fontFamily: 'YekanBakh',
                            direction: 'rtl',
                        },
                    }
                },
                series: [
                    {
                        name: 'خرید',
                        data: buys,
                        type: 'spline',
                        color: '#4CAF50'
                    },
                    {
                        name: 'فروش',
                        data: sells,
                        type: 'spline',
                        color: '#F44336'
                    }
                ],
                exporting: {
                    enabled: true,
                    buttons: {
                        contextButton: {
                            menuItems: [
                                'downloadPNG',
                                'downloadJPEG',
                                'downloadPDF',
                                'downloadSVG',
                                'downloadCSV',
                                'downloadXLS'
                            ],
                        }
                    }
                },
                credits: {
                    enabled: false
                }
            });
        }
    }, [chartData]);

    return (
        <Card title='تعداد خرید و فروش های رمز ارزی' blur={chartLoading} className={"pmx-crypto-count-buy-and-sell"}>
            {chartData && (
                <HighchartsReact highcharts={Highcharts} options={options} />
            )}
        </Card>
    );
};
