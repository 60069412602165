import './edit-modal.scss'
import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { Modal } from "../../../../../components/modal/modal";
import { Button, buttonType } from "../../../../../components/button/button";
import { Input } from "../../../../../components/input/input";
import {EditUserSavedIBansBody, UserSavedIBans} from "../../../../../core/domain/users-saved-ibans/users-saved-ibans";
import {editIBan} from "../../../../../core/repositories/users-saved-ibans";

interface Props {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    item: UserSavedIBans;
    onClose: () => void;
}

export const EditIBanModal: FC<Props> = ({ isOpen, setIsOpen, item, onClose }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<EditUserSavedIBansBody>({
        id: item?.id || '',
        label: item?.label || '',
        iBan: item?.iBan
    });

    useEffect(() => {
        setData({
            id: item?.id || '',
            label: item?.label || '',
            iBan: item?.iBan || '',
        });
    }, [item]);

    const handleInputChange = (field: keyof EditUserSavedIBansBody) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setData(prevData => ({
            ...prevData,
            [field]: e.target.value || ''
        }));
    };

    const handleSubmit = () => {
        setLoading(true);
        editIBan(setLoading, data, onClose);
    };

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} className={"pmx-edit-iban-modal"}>
            <p>
                ویرایش شماره شبای کاربر {item?.fullName}
            </p>
            <Input
                value={data.label}
                label={"عنوان شماره شبا"}
                placeholder={"عنوان شماره شبا"}
                onChange={handleInputChange('label')}
            />
            <Input
                value={data.iBan}
                label={"شماره شبا بدون IR"}
                placeholder={"شماره شبا بدون IR"}
                onChange={handleInputChange('iBan')}
            />
            <div>
                <Button type={buttonType.info} text={"ویرایش"} loading={loading} onClick={handleSubmit} />
                <Button type={buttonType.outlined} text={"انصراف"} onClick={() => setIsOpen(false)} />
            </div>
        </Modal>
    );
};
