import * as React from "react";
import "./sign-in.scss";
import { Button, buttonType } from "components/button/button";

interface Props {
  children: React.ReactNode;
}

export const SignIn: React.FC<Props> = ({ children }) => {
  var currentPage =
    window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ] || "";

  return (
    <div className={`pmx-signin `}>
      <div className='left'>
        <div className={`content`}>{children} </div>
      </div>
    </div>
  );
};
