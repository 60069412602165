import * as React from "react";
import { useRef } from "react";
import "./edit.scss";
import { getViaAuth, responseValidator } from "apis/api";
import { Col, Row, Select, Spin, Switch } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserById, updateUser } from "core/repositories/user";
import { API } from "apis";
import { Input, inputType } from "components/input/input";
import { Button, buttonType } from "components/button/button";
import { DefaultOptionType } from "antd/es/select";
import { UserDetail } from "core/domain";
import { Card } from "components/card/card";
import Dragger from "antd/es/upload/Dragger";
import { toast } from "react-hot-toast";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  FileOutlined,
} from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import { ReactComponent as CalendarIcon } from "assets/icons/dash-line-icons/calendar.svg";

import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { Page } from "../../../../../components/page/page";

const { useState, useEffect } = React;

export const EditUser: React.FC = () => {
  // States
  const [isCardLoading, setIsCardLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserDetail>();
  const [roles, setRoles] = useState<DefaultOptionType[]>([]);
  const [attachmentFile, setAttachmentFile] = useState<any>();
  const [levels, setLevels] = useState<DefaultOptionType[]>([]);
  const [isPasswordHidden, setIsPasswordHidden] = useState<boolean>(true);
  const [oldRoles, setOldRoles] = useState<any[]>();
  const fileUploadProps = {
    name: "file-uploader",
    className: "file-uploader",
    onRemove: (file: any) => {
      setUserData({
        ...userData,
        selfiePhoto: userData?.selfiePhoto?.filter(
          (m: any) => m.type !== "file",
        ),
      });
    },
    beforeUpload: (file: RcFile) => {
      const isCorrectFormat =
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg";
      if (!isCorrectFormat) toast.error(`فرمت تصویر آپلود شده صحیح نمی باشد.`);
      else
        setUserData({
          ...userData,
          selfiePhoto: [
            { file: file, type: "file", name: file.name, uid: file.uid },
          ],
        });

      return false;
    },
    multiple: false,
    file: userData?.selfiePhoto,
  };

  const navigate = useNavigate();
  const location = useLocation();
  const userId =
    location.pathname.split("/")[location.pathname.split("/").length - 1];
  const datePickerRef = useRef();

  const getRoles = async () =>
    await getViaAuth(API.roles.getAll).then((response: any) => {
      var arr: any = [];
      setIsCardLoading(false);
      if (responseValidator(response.status)) {
        response.data.forEach((i: any, idx: number) => {
          arr.push({ label: i.name, value: i.id });
        });
        setRoles(arr);
      }
    });
  const getLevels = async () =>
    await getViaAuth(API.users.levels).then((response: any) => {
      var arr: any = [];
      setIsCardLoading(false);
      if (responseValidator(response.status)) {
        response.data.forEach((i: any, idx: number) => {
          arr.push({ label: i.title, value: i.levelType });
        });
        setLevels(arr);
      }
    });

  async function fetchData() {
    await getUserById(setIsCardLoading, setUserData, userId);

    getLevels();
    getRoles();
  }

  const editUserHandle = async () => {
    const arr: any = [];
    userData.roles.map((i) => arr.push(i));
    updateUser(setLoading, {
      ...userData,
      roles: arr,
    });
  };
  useEffect(() => {
    var rolesTemp = [];
    if (roles?.length > 0 && userData?.roles?.length > 0) {
      userData.roles.map((role: any) => {
        rolesTemp.push(roles.filter((q) => q.value === role)[0]);
      });
      setOldRoles(rolesTemp);
    }
  }, [userData?.roles, roles]);
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (userData) {
      levels.map((level, index) => {
        if (userData.level.value === level.value) {
          setUserData({
            ...userData,
            level: { label: level.label, value: level.value },
          });
        }
      });
    }
  }, [userData]);

  return (
    <Page
      title="تغییر اطلاعات کاربر"
      className="pmx-dashboard-users-edit"
      loading={isCardLoading}
    >
      <Card title="تغییر اطلاعات کاربر">
        {userData && (
          <form>
            <Row>
              <Col>
                <Input
                  label="نام"
                  placeholder="نام کاربر را وارد کنید"
                  name="firstName"
                  value={userData?.firstName || ""}
                  onChange={(e) =>
                    setUserData({ ...userData, firstName: e.target.value })
                  }
                />
              </Col>
              <Col>
                <Input
                  label="نام خانوادگی"
                  placeholder="نام خانوادگی کاربر را وارد کنید"
                  name="lastName"
                  value={userData?.lastName || ""}
                  onChange={(e) =>
                    setUserData({ ...userData, lastName: e.target.value })
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Input
                  label="شماره تماس"
                  placeholder="شماره تماس کاربر را وارد کنید"
                  name="phoneNumber"
                  value={userData?.phoneNumber || ""}
                  onChange={(e) =>
                    setUserData({ ...userData, phoneNumber: e.target.value })
                  }
                />
              </Col>
              <Col>
                <Input
                  label="ایمیل"
                  placeholder="ایمیل کاربر را وارد کنید"
                  name="emailAddress"
                  value={userData?.emailAddress || ""}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      emailAddress: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Input
                  label="شماره تلفن ثابت"
                  placeholder="شماره تلفن ثابت کاربر را وارد کنید"
                  name="landline"
                  value={userData?.landline || ""}
                  onChange={(e) =>
                    setUserData({ ...userData, landline: e.target.value })
                  }
                />
              </Col>
              <Col className="has-label">
                <label>تاریخ تولد</label>
                <DatePicker
                  className="date-picker"
                  value={userData.birthDate}
                  calendar={persian}
                  ref={datePickerRef}
                  locale={persian_fa}
                  render={
                    <div>
                      <Input
                        value={userData.birthDate}
                        icon={<CalendarIcon />}
                        placeholder="تاریخ تولد"
                      />
                    </div>
                  }
                  onChange={(e) => {
                    setUserData({ ...userData, birthDate: e.format() });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  label="کد ملی"
                  placeholder="کد ملی کاربر را وارد کنید"
                  name="nationalCode"
                  value={userData?.nationalCode || ""}
                  onChange={(e) =>
                    setUserData({ ...userData, nationalCode: e.target.value })
                  }
                />
              </Col>
              <Col>
                <Input
                  label="رمز عبور جدید"
                  placeholder="رمز عبور جدید کاربر را وارد کنید"
                  name="password"
                  type={inputType.password}
                  htmlType={isPasswordHidden ? "password" : "text"}
                  icon={
                    isPasswordHidden ? (
                      <EyeOutlined onClick={() => setIsPasswordHidden(false)} />
                    ) : (
                      <EyeInvisibleOutlined
                        onClick={() => setIsPasswordHidden(true)}
                      />
                    )
                  }
                  value={userData?.password || ""}
                  onChange={(e) =>
                    setUserData({ ...userData, password: e.target.value })
                  }
                  maxLength={64}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  label="کد پستی"
                  placeholder="کد پستی کاربر را وارد کنید"
                  name="firstName"
                  value={userData?.postalCode || ""}
                  onChange={(e) =>
                    setUserData({ ...userData, postalCode: e.target.value })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="select">
                  <label>نقش های کاربر</label>
                  <Select
                    mode="multiple"
                    allowClear
                    value={oldRoles}
                    placeholder="انتخاب کنید"
                    onChange={(value, options) => {
                      setUserData({ ...userData, roles: value });
                    }}
                    options={roles}
                  />
                </div>
              </Col>
              <Col>
                <div className="select">
                  <label>سطح کاربر</label>
                  <Select
                    value={userData.level}
                    placeholder="انتخاب کنید"
                    onChange={(value, options) => {
                      console.log(value);
                      setUserData({ ...userData, level: value });
                    }}
                    defaultValue={userData.level}
                    options={levels}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="switch-col">
                {" "}
                <label>نوع کاربر : </label>
                <Switch
                  checked={userData?.isVip}
                  checkedChildren={"کاربر vip"}
                  unCheckedChildren={"کاربر عادی"}
                  onChange={(checked) =>
                    setUserData({
                      ...userData,
                      isVip: checked,
                    })
                  }
                />
              </Col>
              <Col className="switch-col">
                {" "}
                <label>وضعیت کاربر : </label>
                <Switch
                  checked={userData?.isActive}
                  checkedChildren={"فعال"}
                  unCheckedChildren={"غیر فعال"}
                  onChange={(checked) =>
                    setUserData({
                      ...userData,
                      isActive: checked,
                    })
                  }
                />
              </Col>
              <Col className="switch-col">
                {" "}
                <label>وضعیت ورود دو مرحله ای :</label>
                <Switch
                  checked={userData?.isTwoStepActive}
                  checkedChildren={"روشن"}
                  unCheckedChildren={"خاموش"}
                  onChange={(checked) =>
                    setUserData({
                      ...userData,
                      isTwoStepActive: checked,
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Dragger {...fileUploadProps}>
                <button
                  className="upload-button"
                  onClick={(el) => el.preventDefault()}
                >
                  <FileOutlined />
                  <p>آپلود تصویر سلفی</p>
                </button>
              </Dragger>
            </Row>

            <div className="buttons">
              <Button
                htmlType="button"
                type={buttonType.info}
                text="اصلاح"
                onClick={() => editUserHandle()}
                loading={loading}
              />

              <Button
                htmlType="button"
                type={buttonType.outlined}
                text="بازگشت"
                onClick={() => navigate(-1)}
              />
            </div>
          </form>
        )}
      </Card>
    </Page>
  );
};
