import './edit-content.scss'
import {Page} from "../../../../../../components/page/page";
import {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {editContent, getAllCategories, getContentById} from "../../../../../../core/repositories/content";
import toast from "react-hot-toast";
import {Input} from "../../../../../../components/input/input";
import {Button, buttonType} from "../../../../../../components/button/button";
import {Card} from "../../../../../../components/card/card";
import {Dropdown} from "../../../../../../components/dropdown/dropdown";
import {Category, PaginationList} from "../../../../../../core/domain";
import {Editor} from "@tinymce/tinymce-react";
import {TinyEditorAPIKey} from "../../../../../../core/constants/constants";

export interface EditContentBody {
    id?: string;
    categoryId?: string,
    title?: string,
    englishTitle?: string;
    description?: string;
}

export const EditContent = () => {

    const [categories, setCategories] = useState<PaginationList<Category>>();
    const [body, setBody] = useState<EditContentBody>({
        title: "",
        englishTitle: "",
        description: "",
    })
    const [loading, setLoading] = useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    const handleEditcontent = () => {
        editContent(setButtonLoading, body, () => toast.success("محتوا با موفقیت ویرایش شد"))
    }

    useEffect(() => {
        getContentById(setLoading, contentId, setBody)
        getAllCategories(setLoading, setCategories, 1, 999, () => {
        })
    }, []);

    const location = useLocation()
    const contentId =
        location.pathname.split("/")[location.pathname.split("/").length - 1];

    const formattedCategories = categories?.items.map(topic => ({id: topic.id, title: topic.title}))

    const handleEditorChange = (content, editor) => {
        setBody(prevBody => ({...prevBody, description: content}));
    };

    return (
        <Page className={"pmx-edit-content"} title={"ویرایش محتوا"}>
            <Card blur={loading}>
                {formattedCategories &&
                    <>
                        <label>موضوع دسته بندی جهت ویرایش</label>
                        <Dropdown items={formattedCategories}
                                  defaultSelected={formattedCategories.find(category => category.id === body.categoryId)}
                                  onChangeHandle={(e: string) => setBody({...body, categoryId: e})}/>
                    </>
                }
                <Input value={body?.title} placeholder={"نام محتوا جدید"} label={"نام محتوا جهت ویرایش"}
                       onChange={(e) => setBody({...body, title: e.target.value})}/>
                <Input value={body?.englishTitle} placeholder={"نام انگلیسی محتوا جدید"}
                       label={"نام انگلیسی محتوا جهت ویرایش"}
                       onChange={(e) => setBody({...body, englishTitle: e.target.value})}
                       dir={!body.englishTitle ? "rtl" : "ltr"}/>
                <label>توضیحات محتوای جدید</label>
                <Editor
                    apiKey={TinyEditorAPIKey}
                    init={{
                        plugins: 'accordion anchor autolink autoresize autosave charmap code codesample directionality emoticons fullscreen help image importcss insertdatetime link lists liststyle media nonbreaking pagebreak preview quickbars save searchreplace table visualblocks visualchars wordcount',
                        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat | preview fullscreen code',
                    }}
                    value={body?.description}
                    onEditorChange={(a, editor) => handleEditorChange(a, editor)}
                />
                <Button type={buttonType.info} text={"ویرایش محتوا"} onClick={handleEditcontent}
                        loading={buttonLoading}
                        disabled={buttonLoading}/>
                <Link to={"/dashboard/content/contents"}>
                    <Button type={buttonType.primary} text={"بازگشت به صفحه ی محتوا ها"}/>
                </Link>
            </Card>
        </Page>
    )
}