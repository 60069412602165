import {API} from "apis";
import {getViaAuth, postViaAuth, responseValidator} from "apis/api";
import {PaginationList} from "core/domain";
import {Order} from "core/domain/order/order";
import {toast} from "react-hot-toast";
import {Dispatch, SetStateAction} from "react";

export const getAllOrders = async (
    setLoading: any,
    setData: any,
    page: number,
    itemsPerPageCount: number,
    query?: string
) => {
    setLoading(true);
    await getViaAuth<PaginationList<Order>>(
        API.orders.getAll + `/${page}/${itemsPerPageCount}${query || ""}`
    ).then((response: any) => {
        setLoading(false);
        if (responseValidator(response.status)) {
            if (response.data) {
                setData(response.data);
            }
        }
    });
};

export const getAllOrderTypes = async (setLoading: any, setData: any) => {
    setLoading(true);
    await getViaAuth(API.orders.orderTypes).then((response: any) => {
        setLoading(false);
        if (responseValidator(response.status)) {
            setData(response.data);
        }
    });
};
export const getAllOrderStateTypes = async (setLoading: any, setData: any) => {
    setLoading(true);
    await getViaAuth(API.orders.orderStates).then((response: any) => {
        setLoading(false);
        if (responseValidator(response.status)) {
            setData([{id: -1, title: "همه"}, ...response.data]);
        }
    });
};

export const getAllMarketTypes = async (setLoading: any, setData: any) => {
    setLoading(true);
    await getViaAuth(API.orders.marketTypes).then((response: any) => {
        setLoading(false);
        if (responseValidator(response.status)) {
            setData([{id: 0, title: "همه ارز ها"}, ...response.data]);
        }
    });
};

export const changeOrderStatus = async (
    setLoading: any,
    data: any,
    setIsOpenFalse: any
) => {
    setLoading(true);
    postViaAuth<Order>(API.orders.edit, data).then((data) => {
        setLoading(false);
        if (responseValidator(data.status)) {
            toast.success("عملیات با موفقیت انجام شد.");
            setIsOpenFalse();
        }
    });
};

export const confirmAndPayoutOrder = async (
    setLoading: any,
    data: any,
    setIsOpenFalse: any,
    getData: any
) => {
    setLoading(true);
    postViaAuth<Order>(API.orders.confirmAndPayout, data).then((data) => {
        setLoading(false);
        if (responseValidator(data.status)) {
            toast.success("عملیات با موفقیت انجام شد.");
            setIsOpenFalse();
            getData();
        }
    });
};

export const getOrderDetailByTrackingCode = async (
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setData: React.Dispatch<React.SetStateAction<any>>,
    setTimer: React.Dispatch<React.SetStateAction<any>>,
    trackingId: string,
) => {
    setLoading(true);
    await getViaAuth(API.orders.searchByTrackingCode + `/${trackingId}`).then(
        (response: any) => {
            setLoading(false);
            if (responseValidator(response.status)) {
                setData(response.data);
                setTimer(new Date(response.data.paymentDate).getTime() + 72 * 60 * 60 * 1000)
            } else if (response.status === 404) {
                setData(undefined);
            }
        }
    );
};

export const getOrderByTrackingCode = async (
    setLoading: any,
    setData: any,
    trackingId: any
) => {
    setLoading(true);
    await getViaAuth(API.orders.searchByTrackingCode + `/${trackingId}`).then(
        (response: any) => {
            setLoading(false);
            if (responseValidator(response.status)) {
                setData([response.data]);
            } else if (response.status === 404) {
                setData([]);
            }
        }
    );
};

export const payoutPmWithTimer = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    data: { trackingCode: string },
    onComplete: () => void,
) => {
    setLoading(true);
    await postViaAuth(API.orders.payoutPmWithTimer, data).then((response) => {
        setLoading(false)
        if (responseValidator(response.status)) {
            onComplete()
        }
    })
}
