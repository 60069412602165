import * as React from "react";
import "./referral-withdrawals.scss";
import {Page} from "components/page/page";
import {Card} from "components/card/card";
import {Table} from "components/table/table";
import {ReferralWidthrawalState, ReferralWithdrawal} from "core/domain";
import {Badge} from "components";
import {CloseOutlined, CopyOutlined, DollarCircleOutlined, EditOutlined, WarningOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {getAllReferralWithdrawals, getReferralWithdrawalStates,} from "core/repositories/referral";
import {Button, buttonType} from "components/button/button";
import {Input} from "components/input/input";
import {Enum, PaginationList} from "../../../../core/domain/commons/common";
import moment from "jalali-moment";
import {digitSperator} from "core/utils/decimalExtentions";
import {copyToClipboard} from "core/utils/copyToClipboard";
import {Dropdown} from "components/dropdown/dropdown";
import {Tooltip} from "antd";
import {postViaAuth, responseValidator} from "../../../../apis/api";
import {API} from "../../../../apis";
import toast from "react-hot-toast";

interface FilterValues {
    search: string;
    state: Enum;
}

const defaultSelected = {
    id: -1,
    title: "همه",
};

const empty_filters = {
    search: "",
    state: defaultSelected,
};

export const ReferralWithdrawals: React.FC = () => {
    var itemsPerPageCount = 20;

    const [referralWithdrawals, setReferralWithdrawals] =
        React.useState<PaginationList<ReferralWithdrawal>>();
    const [withdrawalStates, setWithdrawalStates] = React.useState<Enum[]>([
        defaultSelected,
    ]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
    const [query, setQuery] = React.useState<string>("");
    const [deleteButtonLoading, setDeleteButtonLoading] =
        React.useState<boolean>(false);
    const [filterValues, setFilterValues] =
        React.useState<FilterValues>(empty_filters);
    const [pageSize, setPageSize] = React.useState<number>(10);

    const navigate = useNavigate();

    const clearFiltersHandle = () => {
        setFilterValues(empty_filters);
        filterHandle(true);
    };

    const filterHandle = (clearFilter?: boolean) => {
        var _query = "";

        if (filterValues.search)
            _query += `${_query.includes("?") ? "&" : "?"}keywords=${
                filterValues.search
            }`;
        if (filterValues.state?.id !== -1)
            _query += `${_query.includes("?") ? "&" : "?"}state=${
                filterValues.state?.id
            }`;

        setQuery(query);
        getAllReferralWithdrawals(
            clearFilter
                ? setDeleteButtonLoading
                : _query !== ""
                    ? setButtonLoading
                    : setLoading,
            setReferralWithdrawals,
            1,
            pageSize,
            clearFilter ? "" : _query
        );
    };

    const fetch = async () => {
        await getReferralWithdrawalStates(setLoading, setWithdrawalStates);
        filterHandle();
    };

    React.useEffect(() => {
        fetch();
    }, []);

    const columns: any[] = [
        {
            name: "نام کامل",
            id: "fullName",
            selector: (row: ReferralWithdrawal) => row.fullName,
            width: "10%",
        },
        {
            name: "موبایل",
            id: "phoneNumber",
            selector: (row: ReferralWithdrawal) => row.phoneNumber,
            width: "10%",
        },
        {
            name: "مقدار",
            id: "amount",
            selector: (row: ReferralWithdrawal) => digitSperator(row.amount),
            width: "10%",
        },
        {
            name: "شماره کارت",
            id: "cardNumber",
            selector: (row: ReferralWithdrawal) => row.bank.cardNumber,
            width: "13%",
            cell: (row: ReferralWithdrawal) => (
                <span className='en copiable'>
          {row?.bank.cardNumber}
                    <CopyOutlined
                        onClick={() => copyToClipboard(row.bank.cardNumber, "شماره کارت")}
                    />
        </span>
            ),
        },
        {
            name: "شماره شبا",
            id: "iBan",
            selector: (row: ReferralWithdrawal) => row.bank.iBan,
            width: "16%",
            cell: (row: ReferralWithdrawal) => (
                <span className='en copiable'>
          {row?.bank.iBan}
                    <CopyOutlined
                        onClick={() => copyToClipboard(row.bank.iBan, "شماره شبا")}
                    />
        </span>
            ),
        },
        {
            name: "نام بانک",
            id: "bankName",
            selector: (row: ReferralWithdrawal) => row.bank.bankName,
            width: "7%",
        },
        {
            name: "وضعیت",
            id: "state",
            width: "12%",
            selector: (row: ReferralWithdrawal) => row.state,
            cell: (row: ReferralWithdrawal) => (
                <Badge
                    text={row.state.title}
                    type={
                        row.state.title.includes("انجام شده") ?
                            "success" :
                            row.state.title.includes("در انتظار")
                                ? "waiting"
                                : (row.state.title.includes("پرداخت نشده") || row.state.title.includes("کنسل"))
                                    ? "error" : "error"

                    }
                />
            ),
        },

        {
            name: "تاریخ ایجاد",
            id: "createdOn",
            selector: (row: ReferralWithdrawal) => row?.createdOn || "",
            sortable: true,
            width: "15%",
            cell: (row: ReferralWithdrawal) => (
                <span>
          {moment.utc(row?.createdOn).locale("fa").local().format("YYYY/MM/DD")}{" "}
                    {moment.utc(row?.createdOn).locale("fa").local().format("HH:mm")}
        </span>
            ),
        },

        {
            name: "عملیات",
            id: "action",
            cell: (row: ReferralWithdrawal) => (
                <div className='actions'>
                    <EditOutlined onClick={() => console.log(1)}/>

                    <Tooltip title="تایید و واریز">
                        <DollarCircleOutlined
                            onClick={() => changeStateHandler(row.id, ReferralWidthrawalState.Succeed)}/>
                    </Tooltip>
                    <Tooltip title="عدم تایید و لغو">
                        <CloseOutlined onClick={() => changeStateHandler(row.id, ReferralWidthrawalState.Canceled)}/>
                    </Tooltip>
                    <Tooltip title="عودت وجه">
                        <WarningOutlined onClick={() => changeStateHandler(row.id, ReferralWidthrawalState.Refounded)}/>
                    </Tooltip>
                </div>

            ),
        },
    ];

    function changeStateHandler(id: string, state: ReferralWidthrawalState) {
        postViaAuth(API.referral.confirmWithdrawalReferral, {
            widthrawalReferralId: id,
            state: state
        }).then(
            (response) => {
                setLoading(false);
                if (responseValidator(response.status)) {
                    toast.success("عملیات با موفقیت انجام شد");
                    fetch();
                }
            }
        );
    }

    return (
        <Page className='pmx-referral-withdrawals' title='همکاران ما'>
            <Card title='لیست درخواست برداشت های رفرال' blur={loading}>
                <div className='filter-row'>
                    <div className='fields'>
                        <Input
                            value={filterValues.search}
                            className='search'
                            placeholder='جستجو کنید ...'
                            onPressEnter={filterHandle}
                            name='search'
                            onChange={(e) =>
                                setFilterValues({...filterValues, search: e.target.value})
                            }
                        />
                        {withdrawalStates.length > 0 && (
                            <Dropdown
                                items={withdrawalStates}
                                onChangeHandle={(value) =>
                                    setFilterValues({
                                        ...filterValues,
                                        state: withdrawalStates.find((l) => l.id === value),
                                    })
                                }
                                selectedOption={filterValues.state}
                            />
                        )}
                    </div>
                    <div className='buttons'>
                        <Button
                            loading={buttonLoading}
                            disabled={buttonLoading}
                            text='اعمال فیلتر'
                            type={buttonType.success}
                            onClick={() => filterHandle()}
                        />
                        <Button
                            loading={deleteButtonLoading}
                            disabled={deleteButtonLoading}
                            text='حذف فیلتر'
                            type={buttonType.error}
                            onClick={clearFiltersHandle}
                        />
                    </div>
                </div>
                {referralWithdrawals?.items &&
                    referralWithdrawals?.items.length > 0 && (
                        <>
                            <Table
                                setPageSize={setPageSize}
                                blur={loading}
                                setloader={setLoading}
                                getdata={getAllReferralWithdrawals}
                                setdata={setReferralWithdrawals}
                                pagination={referralWithdrawals.pagination}
                                className='referral-withdrawals-table'
                                data={referralWithdrawals.items}
                                column={columns}
                                filters={query}
                            />
                        </>
                    )}
            </Card>
        </Page>
    );
};
