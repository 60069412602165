// local storage interface
import { Token } from "../domain/commons/common";

export const authToken = {
  key: "accessToken",
  get: function (): string | null {
    const data = localStorage.getItem(authToken.key);
    if (data) {
      return "Bearer " + data;
    }
    return null;
  },
  set: (value: Token) => {
    localStorage.setItem(authToken.key, JSON.stringify(value));
  },
  remove: () => {
    localStorage.removeItem(authToken.key);
  },
};
