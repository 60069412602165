import {Enum} from "../commons/common";

export interface Order {
    orderId: string;
    authMediaSurveyIsConfirmed: boolean;
    userId: string;
    trackingCode: string;
    sourceSymbol: string;
    destinationSymbol: string;
    sourceAmount: number;
    destinationAmount: number;
    creationDate: string;
    state: Enum;
    type: string;
    fullName: string;
    phoneNumber: string;
}

export interface OrderTrackingCodeType {
    orderId: string;
    trackingCode: string;
    sourceSymbol: string;
    destinationSymbol: string;
    sourceAmount: number;
    destinationAmount: number;
    fullName: string;
    state: Enum;
    creationDate: string;
    paymentDate: string;
    txid: string;
    voucher: string;
    voucherActivation: string;
    orderIdentifier: string;
    destinationMoneyAccount: string;
    digitalRecept: string;
}

export interface OrderDetail {
    orderId: string;
    trackingCode: string;
    sourceSymbol: string;
    phoneNumber: string;
    destinationSymbol: string;
    sourceAmount: number;
    destinationAmount: number;
    fullName: string;
    state: Enum;
    creationDate: string;
    batchNumber: string;
    paymentDate: string;
    iBan: string;
    txid: string;
    voucher: string;
    voucherActivation: string;
    orderIdentifier: string;
    destinationMoneyAccount: string;
    destinationWalletAddress: string;
    digitalRecept: string;
    destinationNetworkName: string;
    authMediaSurveyIsConfirmed: boolean
}

export enum OrderState {
    NotPaidByUser,
    Cancelled,
    Rejected,
    PayingByUser,
    PaidByUser,
    WaitingForAdmin,
    WaitingForExchangeDeposit,
    Success,
    WaitingForSelfieUpload,
    WaitingForPictureAndTime,
    WaitingFor72h,
    WaitingForHashAccept,
    WaitingForAdminAccept,
    WaitingForCheckPictureAndTime,
    Returned = -2,
    Expired = -1
}
