import * as React from "react";
import {Button, buttonType} from "components/button/button";
import ReactCodeInput from "react-verification-code-input";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {ReactComponent as LeftArray} from "assets/icons/signin/left-array.svg";
import "./code.scss";
import {ToEn} from "core/utils/decimalExtentions";
import {getViaAuth, postGeneric, responseValidator} from "apis/api";
import {API} from "apis";
import {LoginResult} from "../login-with-password/login-with-passowrd";
import {useNavigate} from "react-router-dom";
import moment from "moment";

interface Props {
}

export const Code: React.FC<Props> = () => {
    const [codeValue, setCodeValue] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>();
    const [error, setError] = React.useState<string>("");

    const navigate = useNavigate();

    const phone = localStorage.getItem("phone") || "";

    function confirmCode(code: string) {
        setLoading(true);
        postGeneric<LoginResult>(API.users.verify2Step, {
            phoneNumber: phone,
            code: code,
        })
            .then(async (res) => {
                setLoading(false);
                if (responseValidator(res.status)) {
                    res.data?.access_token &&
                    localStorage.setItem("accessToken", res.data?.access_token);
                    res.data?.refresh_token &&
                    localStorage.setItem("refresh", res.data?.refresh_token);
                    localStorage.setItem("date", moment.utc().toString());
                    res.data?.expires_in &&
                    localStorage.setItem("expires_in", res.data?.expires_in.toString());
                    localStorage.setItem(
                        "isAuthorized",
                        res.data?.isAuthorized.toString()
                    );
                    localStorage.setItem(
                        "level",
                        res.data?.isTwoFactorEnabled.toString()
                    );
                    res.data?.roles &&
                    localStorage.setItem("roles", res.data?.roles.join(","));
                    localStorage.setItem("token_type ", res.data?.token_type);

                    await getViaAuth(API.roles.getMineMenuItems).then((res: any) => {
                        if (responseValidator(res.status)) {
                            res.data && localStorage.setItem('menuItems', JSON.stringify(res?.data))
                        }
                    })

                    navigate("/dashboard/users");
                }
            })
            .catch((err) => {
                navigate("/signin");
            });
    }

    return (
        <div className='code'>
            <LeftArray onClick={() => navigate("/signin")}/>
            <div className='title'>
                <p>کد تایید 2 مرحله‌ای را وارد کنید</p>
            </div>

            <div className='signin-code-main'>
                <ReactCodeInput
                    className={`signin-code-input ${error ? "has-error" : ""}`}
                    fieldWidth={60}
                    fieldHeight={60}
                    values={codeValue.split("")}
                    autoFocus={true}
                    onChange={(value) => {
                        setCodeValue(ToEn(value));
                    }}
                    fields={6}
                    onComplete={(e) => confirmCode(e)}
                />

                <div className='signin-code-stats'>
                    <div className='signin-code-errors'>
                        {error ? (
                            <>
                                <QuestionCircleOutlined/>
                                <span>{error}</span>
                            </>
                        ) : (
                            <span></span>
                        )}
                    </div>

                    {!error && <span></span>}
                </div>
            </div>
            <Button
                disabled={codeValue.length != 6}
                type={buttonType.info}
                loading={loading}
                text='تایید'
                onClick={() => confirmCode(codeValue)}
            />
            <div className='footer-text'>
                <p>
                    شماره وارد شده متعلق به شما نیست؟{" "}
                    <span onClick={() => navigate("/signin")}>تصحیح شماره</span>
                </p>
            </div>
        </div>
    );
};
