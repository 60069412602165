import { get, postViaAuth, responseValidator } from "../../apis/api";
import { API } from "../../apis";
import {
  EditMarketCommentBody,
  EditMarketContentBody,
  EditMarketFaqBody,
} from "../domain";
import { message } from "antd";
import { Dispatch, SetStateAction } from "react";
import toast from "react-hot-toast";
import { SERVER_URL } from "../constants/constants";

export async function getAllMarkets(
  setLoading: any,
  setData: any,
  page: number,
  itemsPerPageCount: number,
  query?: string,
) {
  setLoading(true);
  await get(
    API.market.getAllMarketV2 + `/${page}/${itemsPerPageCount}${query}`,
  ).then((response: any) => {
    setLoading(false);

    if (responseValidator(response.status) && response.data) {
      // setData(response.data.data);
      setData(response.data.data);
    }
  });
}

export const updateMarket = (
  setloader: any,
  setdata: any,
  market: any,
  page: number,
  query: string,
  setisediting?: any,
  setvalue?: any,
) => {
  setloader(true);
  postViaAuth(API.market.editV2, market).then((response: any) => {
    setloader(false);
    setisediting && setisediting(false);
    setvalue && setvalue(false);
    if (responseValidator(response.status)) {
      message.success("ارز با موفقیت ویرایش شد");
      getAllMarkets(setloader, setdata, page, 10, query);
    }
  });
};

function base64ToArrayBuffer(base64: any) {
  var binaryString = atob(base64.split(",")[1]);
  var bytes = new Uint8Array(binaryString.length);
  for (var i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

export const getMarketContentBySymbol = async (
  setLoading: any,
  setData: any,
  symbol: string = "",
) => {
  setLoading(true);
  await get(
    API.marketContent.getMarketContentBySymbolAdmin + `/${symbol}`,
  ).then(async (response: any) => {
    let responseData: any = {};
    setLoading(false);
    if (responseValidator(response.status) && response.data) {
      Object.assign(responseData, {
        coinColor: response.data.data.coinColor,
        description1: response.data.data.description1,
        description2: response.data.data.description2,
        content: response.data.data.content,
        coin3DImageUrl: `${SERVER_URL}${response.data.data.coin3DImageUrl}`,
        heroBackGroundImageUrl: `${SERVER_URL}${response.data.data.heroBackGroundImageUrl}`,
      });

      console.log(responseData, "1");
      setData(responseData);

      if (response.data.data.coin3DImageUrl) {
        const base64 = await fetch(responseData.coin3DImageUrl)
          .then((response) => response.blob())
          .then((blob) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            return new Promise((res) => {
              reader.onloadend = () => {
                res(reader.result);
              };
            });
          });
        responseData.coin3DImageUrlFile = base64;
        const file = new File(
          [base64ToArrayBuffer(base64)],
          `${
            response.data.data.coin3DImageUrl
              .split("/")
              [response.data.data.coin3DImageUrl.split("/").length - 1].split(
                ".",
              )[0]
          }`,
        );
        console.log(file);
        setData({ ...responseData, coin3DImageFile: file });
        console.log(base64);
        console.log(responseData, "2");
      }
    }
  });
};

export const editMarketContent = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
  data: EditMarketContentBody,
  symbol: string = "",
) => {
  setLoading(true);

  const formData: FormData = new FormData();

  data?.description1 && formData.append("description1", data?.description1);
  data?.description2 && formData.append("description2", data?.description2);
  data?.content && formData.append("content", data?.content);
  formData.append(
    "coin3DImageUrl",
    data?.coin3DImageUrl?.filter((item) => item.type === "file")[0].file,
  );
  data?.description1 && formData.append("description1", data?.description1);
  data?.coinColor && formData.append("coinColor", data?.coinColor);
  data?.heroBackGroundImageUrl &&
    formData.append(
      "heroBackGroundImageUrl",
      data?.heroBackGroundImageUrl?.filter((item) => item.type === "file")[0]
        .file,
    );
  symbol && formData.append("symbol", symbol);

  await postViaAuth(API.marketContent.updateMarketContent, formData, true).then(
    (response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        toast.success("محتوای ارز با موفقیت ویرایش شد");
      }
    },
  );
};

export const editMarketComments = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
  data: EditMarketCommentBody[],
  symbol: string = "",
) => {
  setLoading(true);
  await postViaAuth(API.marketContent.updateComments, {
    symbol,
    commentDtos: data,
  }).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      toast.success("نظرات ارز با موفقیت ویرایش شد");
    }
  });
};

export const editMarketFaqs = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
  data: EditMarketFaqBody[],
  symbol: string = "",
) => {
  setLoading(true);
  await postViaAuth(API.marketContent.updateFaqs, {
    symbol,
    faqDtos: data,
  }).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      toast.success("پرسش و پاسخ ها با موفقیت ویرایش شد");
    }
  });
};
