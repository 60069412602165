import * as React from "react";
import "./button.scss";
import { Path } from "react-router-dom";
import { PmxComponent } from "core/components/PmxComponenet";
import { LoadingOutlined } from "@ant-design/icons";

export enum buttonType {
  "success",
  "error",
  "primary",
  "info",
  "secondary",
  "outlined",
  "warning",
  "cancel",
  white,
}

interface Props extends PmxComponent {
  disabled?: boolean;
  type: buttonType;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  text?: string;
  icon?: React.ReactNode;
  htmlType?: any;
  link?: string | Partial<Path>;
  url?: string;
}

export const Button: React.FC<Props> = (props) => {
  return (
    <div className={`pmx-button ${props.className ?? ""}`}>
      <button
        type={props.htmlType}
        className={`${buttonType[props.type]} ${props.disabled && "disable"}`}
        disabled={props.disabled || props.loading}
        onClick={props.onClick}
      >
        {!props.loading && <span>{props.text}</span>}
        {props.icon}
        {props.loading ? <LoadingOutlined /> : <></>}
      </button>
    </div>
  );
};
