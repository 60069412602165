import {Page} from 'components/page/page'
import './add-topic.scss'
import {Card} from "../../../../../../components/card/card";
import {Input} from "../../../../../../components/input/input";
import {useState} from "react";
import {Button, buttonType} from "../../../../../../components/button/button";
import {addTopic} from "../../../../../../core/repositories/content";
import toast from "react-hot-toast";
import {Link} from "react-router-dom";

export interface AddTopicBody {
    title?: string,
    englishTitle?: string;
    description?: string;
}

export const AddTopic = () => {
    const [body, setBody] = useState<AddTopicBody>({
        title: "",
        englishTitle: "",
        description: "",
    })
    const [loading, setLoading] = useState<boolean>(false);

    const handleAddTopic = () => {
        const onComplete = () => {
            setBody({
                title: "",
                englishTitle: "",
                description: ""
            })
            toast.success("تاپیک با موفقیت اضافه شد")
        }
        addTopic(setLoading, body, onComplete)
    }

    return (
        <Page className="pmx-add-topic" title="ایجاد تاپیک جدید">
            <Card>
                <Input value={body?.title} placeholder={"نام تاپیک جدید"} label={"نام تاپیک جدید"}
                       onChange={(e) => setBody({...body, title: e.target.value})}/>
                <Input value={body?.englishTitle} placeholder={"نام انگلیسی تاپیک جدید"} label={"نام انگلیسی تاپیک جدید"}
                       onChange={(e) => setBody({...body, englishTitle: e.target.value})}
                       dir={!body.englishTitle ? "rtl" : "ltr"}/>
                <Input value={body?.description} placeholder={"توضیحات تاپیک جدید"} label={"توضیحات تاپیک جدید"}
                       onChange={(e) => setBody({...body, description: e.target.value})}/>
                <Button type={buttonType.info} text={"ایجاد"} onClick={handleAddTopic} loading={loading}
                        disabled={loading || body.title.length === 0 || body.englishTitle.length === 0}/>
                <Link to={"/dashboard/content/topics"}>
                    <Button type={buttonType.primary} text={"بازگشت به صفحه ی تاپیک ها"} />
                </Link>
            </Card>
        </Page>
    )
}