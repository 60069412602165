import {Enum} from "../commons/common";

export interface ReferralInfo {
    referralCode: string;
    fullName: string;
    phoneNumber: string;
    createdOn: string;
    balance: number;
}

export interface ReferralWithdrawal {
    id: string,
    fullName: string;
    phoneNumber: string;
    amount: number;
    bank: {
        iBan: string;
        cardNumber: string;
        bankName: string;
        englishBankName: string;
    };
    state: Enum;
    createdOn: string;
}

export interface ReferralAction {
    referralFullName: string;
    invitedUserFullName: string;
    orderAmount: number;
    actionAmount: number;
    type: Enum;
    orderTrackingCode: string;
    createdOn: string;
}

export enum ReferralWidthrawalState {
    Canceled = 1,
    Succeed,
    WaitingForAdmin,
    Refounded
}
