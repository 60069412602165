import * as React from "react";
import "./order-by-tracking-code.scss";
import {Page} from "components/page/page";
import {Card} from "components/card/card";
import {Table} from "components/table/table";
import {Order} from "core/domain";
import {Badge} from "components";
import {CopyOutlined, DollarCircleOutlined, EditOutlined, InfoCircleOutlined,} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {confirmAndPayoutOrder, getAllOrderStateTypes, getOrderByTrackingCode,} from "core/repositories/order";
import {Button, buttonType} from "components/button/button";
import {Input} from "components/input/input";
// import { ClientSidePagination } from "components/pagination/pagination";
import {Enum} from "../../../../core/domain/commons/common";
import moment from "jalali-moment";
import {digitSperator} from "core/utils/decimalExtentions";
import {copyToClipboard} from "core/utils/copyToClipboard";
import {getIconPath} from "core/utils/getIconPath";
import {OrderEditModal} from "./edit-modal/edit-modal";
import {OrderDetailModal} from "./order-detail/order-detail";
import {Modal} from "components/modal/modal";

interface FilterValues {
    search: string;
}

const empty_filters = {
    search: "",
};

export const SearchOrderByTrackingCode: React.FC = () => {
    const [detailModalIsOpen, setDetailModalIsOpen] =
        React.useState<boolean>(false);

    const [selectedItem, setSelcetedItem] = React.useState<Order>();
    const [confirmButtonLoading, setConfirmButtonLoading] =
        React.useState<boolean>(false);
    const [orderStateTypes, setOrderStateTypes] = React.useState<Enum[]>([]);
    const [query, setQuery] = React.useState<string>("");
    const [editModalIsOpen, setEditModalIsOpen] = React.useState<boolean>(false);
    const [orders, setOrders] = React.useState<Order[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
    const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
    const [payoutModalIsOpen, setPayoutModalIsOpen] =
        React.useState<boolean>(false);
    const [filterValues, setFilterValues] =
        React.useState<FilterValues>(empty_filters);
    const [pageSize, setPageSize] = React.useState<number>(10);

    const navigate = useNavigate();

    const filterHandle = () => {
        var _query = "";

        if (filterValues.search) _query += `${filterValues.search}`;

        setQuery(_query);
        getOrderByTrackingCode(
            _query !== "" ? setButtonLoading : setLoading,
            setOrders,
            _query
        );
    };

    React.useEffect(() => {
        getAllOrderStateTypes(setLoading, setOrderStateTypes)
    }, []);

    async function fetches() {
        filterHandle();
    }

    const onPayoutIconClickHandle = (item) => {
        setSelcetedItem(item);
        setPayoutModalIsOpen(true);
    };

    const columns: any[] = [
        {
            name: "شناسه",
            id: "trackingCode",
            selector: (row: Order) => row.trackingCode,
            width: "14%",
            cell: (row: Order) => (
                <span className='en copiable'>
          {row.trackingCode}
                    <CopyOutlined
                        onClick={() => copyToClipboard(row.trackingCode, "شناسه سفارش")}
                    />
        </span>
            ),
        },
        {
            name: "نام",
            id: "fullName",
            selector: (row: Order) => row.fullName,
            width: "9%",
        },
        {
            name: "موبایل",
            id: "phoneNumber",
            selector: (row: Order) => row.phoneNumber,
            width: "8%",
        },
        {
            name: "جفت ارز",
            id: "pair",
            selector: (row: Order) => row?.sourceSymbol + row.destinationSymbol,
            cell: (row: Order) => (
                <div className='cell pair'>
                    <img src={getIconPath(row.sourceSymbol.toLowerCase())}/>
                    <span className='en'>{row.sourceSymbol}</span>
                    {` `} / {` `}
                    <span className='en'>{row.destinationSymbol}</span>
                    <img src={getIconPath(row.destinationSymbol.toLowerCase())}/>
                </div>
            ),
            width: "12%",
        },
        {
            name: "مبلغ مبدا",
            id: "sourceAmount",
            selector: (row: Order) =>
                row.sourceSymbol.toLowerCase() === "irt"
                    ? digitSperator(row.sourceAmount.toFixed(0))
                    : row.sourceAmount,
            width: "7%",
        },
        {
            name: "مبلغ مقصد",
            id: "destinationAmount",
            selector: (row: Order) =>
                row.destinationSymbol.toLowerCase() === "irt"
                    ? digitSperator(row.destinationAmount.toFixed(0))
                    : row.destinationAmount,
            width: "8%",
        },
        {
            name: "تاریخ ثبت سفارش",
            id: "dateTime",
            selector: (row: Order) => row?.creationDate || "",
            sortable: true,
            width: "10%",
            cell: (row: Order) => (
                <span>
          {moment.utc(row?.creationDate).locale("fa").local().format("HH:mm")}{" "}
                    {moment
                        .utc(row?.creationDate)
                        .locale("fa")
                        .local()
                        .format("YYYY/MM/DD")}{" "}
        </span>
            ),
        },
        {
            name: "وضعیت",
            id: "state",
            width: "15%",
            selector: (row: Order) => row.state,
            cell: (row: Order) => (
                <Badge
                    text={row.state.title}
                    type={
                        row?.state?.id === 7
                            ? "success"
                            : row?.state?.title.includes("ناموفق")
                                ? "error"
                                : row?.state?.title.includes("در حال انجام")
                                    ? "waiting"
                                    : row?.state?.title.includes("پرداخت نشده") || row?.state.id === 1
                                        ? "error"
                                        : "waiting"
                    }
                />
            ),
        },

        {
            name: "عملیات",
            id: "action",
            width: "10%",
            cell: (row: Order) => (
                <div className='actions'>
                    <EditOutlined
                        onClick={() => {
                            setSelcetedItem(row);
                            setEditModalIsOpen(true);
                        }}
                    />

                    <DollarCircleOutlined onClick={() => onPayoutIconClickHandle(row)}/>

                    <InfoCircleOutlined
                        onClick={() => {
                            setSelcetedItem(row);
                            setDetailModalIsOpen(true);
                        }}
                    />
                </div>
            ),
        },
    ];
    return (
        <Page
            className='pmx-orders-by-tracking-code'
            title='جستجوی سفارش با کد پیگیری'
        >
            {selectedItem && (
                <OrderEditModal
                    orderStates={orderStateTypes}
                    isOpen={editModalIsOpen}
                    setIsOpen={setEditModalIsOpen}
                    item={selectedItem}
                    onClose={() => filterHandle()}
                />
            )}
            <Modal
                setIsOpen={setPayoutModalIsOpen}
                isOpen={payoutModalIsOpen}
                className='confirm-payout-modal'
            >
                <p>
                    آیا از تایید و تسویه تراکنش کاربر {selectedItem?.fullName} اطمینان
                    دارید؟
                </p>

                <div className='buttons'>
                    <Button
                        type={buttonType.info}
                        text='بله'
                        onClick={() =>
                            confirmAndPayoutOrder(
                                setConfirmButtonLoading,
                                {trackingCode: selectedItem?.trackingCode},
                                () => setPayoutModalIsOpen(false),
                                () => filterHandle()
                            )
                        }
                        loading={confirmButtonLoading}
                    />
                    <Button
                        type={buttonType.error}
                        text='خیر'
                        onClick={() => setPayoutModalIsOpen(false)}
                    />
                </div>
            </Modal>
            <Card title='نمایش سفارش' blur={loading}>
                {selectedItem && (
                    <OrderDetailModal
                        selectedRow={selectedItem}
                        isOpen={detailModalIsOpen}
                        setIsOpen={setDetailModalIsOpen}
                        trackingId={selectedItem?.trackingCode}
                    />
                )}

                <div className='filter-row'>
                    <div className='fields'>
                        <Input
                            value={filterValues.search}
                            className='search'
                            placeholder='کد پیگیری سفارش را وارد کنید'
                            name='search'
                            onPressEnter={filterHandle}
                            onChange={(e) =>
                                setFilterValues({...filterValues, search: e.target.value})
                            }
                        />
                    </div>
                    <div className='buttons'>
                        <Button
                            loading={buttonLoading}
                            disabled={buttonLoading}
                            text='جستجو'
                            type={buttonType.success}
                            onClick={() => filterHandle()}
                        />
                    </div>
                </div>
                {
                    <>
                        <Table
                            setPageSize={setPageSize}
                            blur={loading}
                            setloader={setLoading}
                            getdata={() => console.log('test')}
                            setdata={setOrders}
                            className='orders-table'
                            data={orders}
                            column={columns}
                            filters={query}
                        />
                    </>
                }
            </Card>
        </Page>
    );
};
