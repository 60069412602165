import * as React from "react";
import "./input.scss";
import { PmxComponent } from "core/components/PmxComponenet";
import { formatCardNumber } from "core/utils/bankDetecter";

export enum inputType {
  "text",
  "cardNumber",
  "password",
}

interface Props extends PmxComponent {
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value: string;
  icon?: React.ReactNode;
  placeholder: string;
  htmlType?: any;
  maxLength?: number;
  type?: inputType;
  dir?: "ltr" | "rtl";
  onPressEnter?: () => void;

  required?: boolean;
  label?: string;
  name?: string;
  prefix?: React.ReactNode;
  autoFocus?: boolean;
  suffix?: React.ReactNode;
}

export const Input: React.FC<Props> = (props) => {
  return (
    <div className={`pmx-input ${props.className ?? ""}`}>
      {props.label && (
        <label htmlFor={props.className || ""}>{props.label}</label>
      )}
      {props.prefix && <div className={"prefix"}>{props.prefix}</div>}
      <input
        type={props.htmlType ?? "text"}
        value={
          props.type === inputType.cardNumber
            ? formatCardNumber(props.value || "")
            : props.value
        }
        onKeyUp={(event) =>
          event.key === "Enter" &&
          props.onPressEnter &&
          !props.disabled &&
          !props.disabled &&
          props.onPressEnter()
        }
        onChange={props.onChange}
        maxLength={props.maxLength}
        required={props.required}
        placeholder={props.placeholder || ""}
        autoFocus={props.autoFocus}
        dir={props.dir || "rtl"}
      />
      {props.suffix && <div className={"suffix"}>{props.suffix}</div>}
      {props.icon && <div className="adornments">{props.icon}</div>}
    </div>
  );
};
