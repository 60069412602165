import * as React from "react";
import "./bank-accounts.scss";
import {Page} from "components/page/page";
import {Card} from "components/card/card";
import {Table} from "components/table/table";
import {BankAccount} from "core/domain";
import {Badge} from "components";
import {CopyOutlined, DeleteOutlined, ExportOutlined, PlusCircleFilled,} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {deleteBankAccount, getAllBankAccounts,} from "core/repositories/bank-account";
import {Button, buttonType} from "components/button/button";
import {Input} from "components/input/input";
// import { ClientSidePagination } from "components/pagination/pagination";
import {PaginationList} from "../../../../core/domain/commons/common";
import {getPageNum} from "core/utils/paggingHelper";
import {copyToClipboard} from "core/utils/copyToClipboard";
import {formatCardNumber} from "core/utils/bankDetecter";
import {Modal} from "components/modal/modal";
import {downloadCSV} from "../../../../core/utils/export-to-file";

interface FilterValues {
    search: string;
}

const empty_filters = {
    search: "",
};

export const BankAccounts: React.FC = () => {
    const [bankAccounts, setBankAccounts] =
        React.useState<PaginationList<BankAccount>>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
    const [deleteModalButtonLoading, setDeleteModalButtonLoading] =
        React.useState<boolean>(false);
    const [deleteButtonLoading, setDeleteButtonLoading] =
        React.useState<boolean>(false);
    const [filterValues, setFilterValues] =
        React.useState<FilterValues>(empty_filters);
    const [query, setQuery] = React.useState<string>("");
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [selectedItem, setSelectedItem] = React.useState<BankAccount>();
    const [pageSize, setPageSize] = React.useState<number>(10);

    const navigate = useNavigate();

    const clearFiltersHandle = () => {
        setFilterValues(empty_filters);
        filterHandle(true);
    };

    const filterHandle = (clearFilter?: boolean) => {
        var _query = "";

        if (filterValues.search)
            _query += `${_query.includes("?") ? "&" : "?"}keywords=${
                filterValues.search
            }`;

        setQuery(_query);
        getAllBankAccounts(
            clearFilter
                ? setDeleteButtonLoading
                : _query !== ""
                    ? setButtonLoading
                    : setLoading,
            setBankAccounts,
            1,
            pageSize,
            clearFilter ? "" : _query
        );
    };

    const openDeleteModal = (item: BankAccount) => {
        setSelectedItem(item);
        setIsOpen(true);
    };

    const closeDeleteModal = () => {
        setSelectedItem(undefined);
        setIsOpen(false);
    };

    React.useEffect(() => {
        getAllBankAccounts(setLoading, setBankAccounts, getPageNum(), pageSize);
    }, []);

    const columns: any[] = [
        {
            name: "شماره صاحب کارت",
            id: "owner",
            selector: (row: BankAccount) => row.owner,
            width: "15%",
        },
        {
            name: "نام کامل کاربر",
            id: "fullName",
            selector: (row: BankAccount) => row.fullName,
            width: "15%",
        },
        {
            name: "شماره کارت",
            id: "cardNumber",
            selector: (row: BankAccount) => row.cardNumber,
            width: "15%",
            cell: (row: BankAccount) => (
                <span className='en copiable'>
          {formatCardNumber(row?.cardNumber)}
                    <CopyOutlined
                        onClick={() => copyToClipboard(row.cardNumber, "شماره کارت")}
                    />
        </span>
            ),
        },
        {
            name: "نام بانک",
            id: "bankName",
            selector: (row: BankAccount) => row.bankName,
            width: "15%",
        },

        {
            name: "وضعیت",
            id: "state",
            width: "15%",
            selector: (row: BankAccount) => row.state.id,
            cell: (row: BankAccount) => (
                <Badge
                    text={row.state.title}
                    type={
                        row.state.id === 2
                            ? "error"
                            : row.state.id === 3
                                ? "waiting"
                                : "success"
                    }
                />
            ),
        },
        {
            name: "عملیات",
            id: "action",
            width: "10%",
            cell: (row: BankAccount) => (
                <div className='actions'>
                    <DeleteOutlined
                        onClick={() => {
                            openDeleteModal(row);
                        }}
                    />
                </div>
            ),
        },
    ];
    const onDeleteCompelete = () => {
        setIsOpen(false);
        getAllBankAccounts(
            setLoading,
            setBankAccounts,
            getPageNum(),
            pageSize,
            query
        );
        setSelectedItem(undefined);
    };

    return (
        <Page className='pmx-bank-accounts' title='مدیریت حساب های بانکی'>
            <Modal
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                className='confirm-delete-card-modal'
            >
                <p>
                    آیا از حذف کارت بانکی کاربر{" " + selectedItem?.fullName} اطمینان
                    دارید؟
                </p>

                <div className='buttons'>
                    <Button
                        type={buttonType.info}
                        text='بله'
                        loading={deleteModalButtonLoading}
                        onClick={() =>
                            deleteBankAccount(
                                setDeleteModalButtonLoading,
                                {id: selectedItem.id},
                                onDeleteCompelete
                            )
                        }
                    />
                    <Button
                        type={buttonType.error}
                        text='خیر'
                        onClick={() => closeDeleteModal()}
                    />
                </div>
            </Modal>
            <Card title='لیست حساب های بانکی' blur={loading}>
                <Button
                    type={buttonType.info}
                    text='افزودن حساب بانکی توسط ادمین'
                    icon={<PlusCircleFilled/>}
                    onClick={() => navigate("create")}
                />
                <div className='filter-row'>
                    <div className='fields'>
                        <Input
                            value={filterValues.search}
                            className='search'
                            placeholder='جستجو کنید ...'
                            name='search'
                            onPressEnter={filterHandle}
                            onChange={(e) =>
                                setFilterValues({...filterValues, search: e.target.value})
                            }
                        />

                    </div>
                    <div className='buttons'>
                        <Button
                            loading={buttonLoading}
                            disabled={buttonLoading}
                            text='اعمال فیلتر'
                            type={buttonType.success}
                            onClick={() => filterHandle()}
                        />
                        <Button
                            loading={deleteButtonLoading}
                            disabled={deleteButtonLoading}
                            text='خروجی اکسل'
                            type={buttonType.info}
                            icon={<ExportOutlined/>}
                            onClick={(event) => downloadCSV("bankaccounts", bankAccounts.items)}
                        />
                    </div>
                </div>
                {bankAccounts?.items && bankAccounts?.items.length > 0 && (
                    <>
                        <Table
                            setPageSize={setPageSize}
                            blur={loading}
                            setloader={setLoading}
                            getdata={BankAccounts}
                            setdata={BankAccounts}
                            pagination={bankAccounts.pagination}
                            className='bank-accounts-table'
                            data={bankAccounts.items}
                            column={columns}
                            filters={query}
                        />
                    </>
                )}
            </Card>
        </Page>
    );
};
