import { API } from "apis";
import { getViaAuth, responseValidator } from "apis/api";
import {
  PaginationList,
  ReferralAction,
  ReferralInfo,
  ReferralWithdrawal,
} from "core/domain";

export const getAllReferralInfos = async (
  setLoading: any,
  setData: any,
  page: number,
  itemsPerPageCount: number,
  query?: string
) => {
  setLoading(true);
  await getViaAuth<PaginationList<ReferralInfo>>(
    API.referral.getAllReferralInfos +
      "/" +
      page +
      "/" +
      itemsPerPageCount +
      `${query || ""}`
  ).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      if (response.data) {
        setData(response.data);
      }
    }
  });
};

export const getAllReferralWithdrawals = async (
  setLoading: any,
  setData: any,
  page: number,
  itemsPerPageCount: number,
  query?: string
) => {
  setLoading(true);
  await getViaAuth<PaginationList<ReferralWithdrawal>>(
    API.referral.getAllReferralWithdrawals +
      "/" +
      page +
      "/" +
      itemsPerPageCount +
      `${query || ""}`
  ).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      if (response.data) {
        setData(response.data);
      }
    }
  });
};

export const getAllReferralActions = async (
  setLoading: any,
  setData: any,
  page: number,
  itemsPerPageCount: number,
  query?: string
) => {
  setLoading(true);
  await getViaAuth<PaginationList<ReferralAction>>(
    API.referral.getAllReferralActions +
      "/" +
      page +
      "/" +
      itemsPerPageCount +
      `${query || ""}`
  ).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      if (response.data) {
        setData(response.data);
      }
    }
  });
};

export const getReferralWithdrawalStates = async (
  setLoading: any,
  setData: any
) => {
  setLoading(true);
  await getViaAuth(API.referral.getReferralWithdrawalStates).then(
    (response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        setData([{ id: -1, title: "همه" }, ...response.data]);
      }
    }
  );
};
