import './edit-modal.scss'
import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import {
    EditUserSavedWalletsBody,
    UserSavedWallets
} from "../../../../../core/domain/users-saved-wallets/users-saved-wallets";
import { Modal } from "../../../../../components/modal/modal";
import { Button, buttonType } from "../../../../../components/button/button";
import { editWallet } from "../../../../../core/repositories/users-saved-wallets";
import { Input } from "../../../../../components/input/input";

interface Props {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    item: UserSavedWallets;
    onClose: () => void;
}

export const EditWalletModal: FC<Props> = ({ isOpen, setIsOpen, item, onClose }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<EditUserSavedWalletsBody>({
        id: item?.id || '',
        label: item?.label || '',
        walletAddress: item?.walletAddress || '',
        network: item?.network || ''
    });

    useEffect(() => {
        setData({
            id: item?.id || '',
            label: item?.label || '',
            walletAddress: item?.walletAddress || '',
            network: item?.network || ''
        });
    }, [item]);

    const handleInputChange = (field: keyof EditUserSavedWalletsBody) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setData(prevData => ({
            ...prevData,
            [field]: e.target.value || ''
        }));
    };

    const handleSubmit = () => {
        setLoading(true);
        editWallet(setLoading, data, onClose);
    };

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} className={"pmx-edit-wallet-modal"}>
            <p>
                ویرایش کیف پول کاربر {item?.fullName}
            </p>
            <Input
                value={data.label}
                label={"عنوان کیف پول"}
                placeholder={"عنوان کیف پول"}
                onChange={handleInputChange('label')}
            />
            <Input
                value={data.walletAddress}
                label={"آدرس کیف پول"}
                placeholder={"آدرس کیف پول"}
                onChange={handleInputChange('walletAddress')}
            />
            <Input
                value={data.network}
                label={"شبکه کیف پول"}
                placeholder={"شبکه کیف پول"}
                onChange={handleInputChange('network')}
            />
            <div>
                <Button type={buttonType.info} text={"ویرایش"} loading={loading} onClick={handleSubmit} />
                <Button type={buttonType.outlined} text={"انصراف"} onClick={() => setIsOpen(false)} />
            </div>
        </Modal>
    );
};
