import { API } from "apis";
import { getViaAuth, responseValidator } from "apis/api";
import { Role } from "core/domain";

export const getAllRoles = async (
  setLoading: any,
  setData: any,
  query?: string
) => {
  setLoading(true);
  await getViaAuth<Role[]>(API.roles.getAll + `${query || ""}`).then(
    (response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        if (response.data) {
          setData(response.data);
        }
      }
    }
  );
};

export const getRoleById = async (
  setLoading: any,
  setData: any,
  setPermissionKeys: any,
  setMenuItemKeys: any,
  id: string
) => {
  setLoading(true);
  await getViaAuth<Role[]>(API.roles.getById + `?id=${id}`).then(
    (response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        if (response.data) {
          setData(response.data);
          setPermissionKeys(response.data.claims.map((permission) => permission));
          setMenuItemKeys(response.data.menuItems.map((menuItem) => menuItem));
        }
      }
    }
  );
};
