import {Page} from 'components/page/page'
import './add-content.scss'
import {Card} from "../../../../../../components/card/card";
import {Input} from "../../../../../../components/input/input";
import {useEffect, useState} from "react";
import {Button, buttonType} from "../../../../../../components/button/button";
import {addContent, getAllCategories} from "../../../../../../core/repositories/content";
import toast from "react-hot-toast";
import {Link} from "react-router-dom";
import {Dropdown} from "../../../../../../components/dropdown/dropdown";
import {Category, PaginationList} from "../../../../../../core/domain";
import {Editor} from "@tinymce/tinymce-react";
import {Spin} from "antd";
import {TinyEditorAPIKey} from "../../../../../../core/constants/constants";

export interface AddContentBody {
    categoryId?: string;
    title?: string,
    englishTitle?: string;
    description?: string;
}

export const AddContent = () => {
    const [categories, setCategories] = useState<PaginationList<Category>>();
    const [body, setBody] = useState<AddContentBody>({
        title: "",
        englishTitle: "",
        description: "",
    })
    const [loading, setLoading] = useState<boolean>(false);
    const [addButtonLoading, setAddButtonLoading] = useState<boolean>(false)

    const handleAddContent = () => {
        const onComplete = () => {
            setBody({
                categoryId: formattedCategories[0].id,
                title: "",
                englishTitle: "",
                description: ""
            })
            toast.success("محتوا با موفقیت اضافه شد")
        }
        addContent(setAddButtonLoading, body, onComplete)
    }

    useEffect(() => {
        const onComplete = (firstCategory: Category) => setBody({...body, categoryId: firstCategory.id})
        getAllCategories(setLoading, setCategories, 1, 999, onComplete)
    }, []);

    const formattedCategories = categories?.items.map(topic => ({id: topic.id, title: topic.title}))

    const handleEditorChange = (content, editor) => {
        setBody(prevBody => ({...prevBody, description: content}));
    };

    return (
        <Page className="pmx-add-content" title="ایجاد محتوای جدید">
            <Card>
                <label>موضوع دسته بندی محتوای جدید</label>
                {loading ? <Spin/> :
                    formattedCategories &&
                    <Dropdown items={formattedCategories}
                              onChangeHandle={(e: string) => setBody({...body, categoryId: e})} loading={loading}
                              selectedOption={{
                                  id: body?.categoryId,
                                  title: formattedCategories.find(topic => topic.id === body?.categoryId)?.title,
                              }}
                    />

                }
                <Input value={body?.title} placeholder={"نام محتوای جدید"} label={"نام محتوای جدید"}
                       onChange={(e) => setBody({...body, title: e.target.value})}/>
                <Input value={body?.englishTitle} placeholder={"نام انگلیسی محتوای جدید"}
                       label={"نام انگلیسی محتوای جدید"}
                       onChange={(e) => setBody({...body, englishTitle: e.target.value})}
                       dir={!body.englishTitle ? "rtl" : "ltr"}/>

                <label>توضیحات محتوای جدید</label>
                <Editor
                    apiKey={TinyEditorAPIKey}
                    init={{
                        plugins: 'accordion anchor autolink autoresize autosave charmap code codesample directionality emoticons fullscreen help image importcss insertdatetime link lists liststyle media nonbreaking pagebreak preview quickbars save searchreplace table visualblocks visualchars wordcount',
                        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat | preview fullscreen code',
                    }}
                    value={body?.description}
                    onEditorChange={(a, editor) => handleEditorChange(a, editor)}
                />
                <Button type={buttonType.info} text={"ایجاد"} onClick={handleAddContent}
                        disabled={loading || !body.categoryId || body.categoryId.length === 0 || body.title.length === 0 || body.englishTitle.length === 0 || body.description.length === 0}
                        loading={addButtonLoading}/>
                <Link to={"/dashboard/content/contents"}>
                    <Button type={buttonType.primary} text={"بازگشت به صفحه ی محتوا ها"}/>
                </Link>
            </Card>
        </Page>
    )
}
