import React from "react";
import "core/styles/globals.scss";
import {BrowserRouter} from "react-router-dom";
import {PmxDashboard} from "./routes/pmx-dashboard";
import {Provider} from "react-redux";
import {store} from "core/redux/store";
import {Toaster} from "react-hot-toast";
import {Helmet} from "react-helmet";

function App() {
    return (
        <Provider store={store}>
            <Toaster position={"top-right"}/>
            <Helmet>
                <title>پی ام ایکس چنج </title>

                <meta name='description' content='پی ام ایکس چنج | پنل ادمین'/>
                <link rel='icon' type='image/jpg' href='favicon.jpg' sizes='16x16'/>

            </Helmet>
            <title>پی ام ایکس چنج </title>
            <meta name='description' content='پی ام ایکس چنج | پنل ادمین'/>

            <BrowserRouter>
                <PmxDashboard/>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
