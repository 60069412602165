import { Page } from "components/page/page";
import "./track-voucher.scss";
import { Card } from "components/card/card";
import { useState } from "react";
import { TrackVoucherResponse } from "core/domain/track-voucher/track-voucher";
import { getVoucherInfo } from "core/repositories/track-voucher";
import { Input } from "components/input/input";
import { Button, buttonType } from "components/button/button";
import moment from "jalali-moment";

export const TrackVoucher = () => {
  const [voucherCode, setVoucherCode] = useState<string>();
  const [voucherInfo, setVoucherInfo] = useState<TrackVoucherResponse>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleTrackVoucher = async () => {
    await getVoucherInfo(setLoading, setVoucherInfo, voucherCode);
  };

  return (
    <Page className="pmx-track-voucher" title="استعلام ووچر پرفکت مانی">
      <Card className="voucher-tracking" title="استعلام ووچر پرفکت مانی">
        <Input
          value={voucherCode}
          placeholder="کد ووچر"
          onChange={(e) => setVoucherCode(e.target.value)}
          onPressEnter={handleTrackVoucher}
        />
        <Button
          loading={loading}
          disabled={loading || voucherCode === ""}
          text="جستجو"
          type={buttonType.success}
          onClick={handleTrackVoucher}
        />
      </Card>
      {voucherInfo ? (
        <Card title="اطلاعات ووچر" className="voucher-info">
          <section>
            <div className="row">
              <p>اکانت فعال شده:</p>
              <span className="en">{voucherInfo?.payeeAccount || "-"}</span>
            </div>
            <div className="row">
              <p>بچ:</p>
              <span className="en">{voucherInfo?.batch || "-"}</span>
            </div>
            <div className="row">
              <p>تاریخ فعالسازی:</p>
              <span className="en">
                {moment(voucherInfo?.activatedDateTime).isValid()
                  ? moment
                      .utc(voucherInfo.activatedDateTime)
                      .locale("fa")
                      .local()
                      .format("HH:mm:ss - jYYYY/jMM/jDD")
                  : "-"}
              </span>
            </div>
            <div className="row">
              <p>مقدار:</p>
              <span className="en">{voucherInfo?.amount || "-"}</span>
            </div>
            <div className="row">
              <p>کد ووچر:</p>
              <span className="en">{voucherInfo?.voucherCode || "-"}</span>
            </div>
          </section>
        </Card>
      ) : null}
    </Page>
  );
};
