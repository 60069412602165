import { Page } from 'components/page/page'
import './audit-log-get-by-id.scss'
import { Card } from 'components/card/card'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getAuditLogById } from 'core/repositories/audit-log'
import { AuditLogById as AuditLog } from 'core/domain/audit-log/audit-log'
import { Row } from 'antd'
import moment from 'jalali-moment'

export const AuditLogById = () => {
    const [cardLoading, setCardLoading] = useState<boolean>(false)
    const [log, setLog] = useState<AuditLog>()

    const navigate = useNavigate();
    const location = useLocation();
    const auditLogId =
        location.pathname.split("/")[location.pathname.split("/").length - 1];

    useEffect(() => {
        getAuditLogById(setCardLoading, setLog, auditLogId)
    }, [])

    return (
        <Page
            className='pmx-audit-log-get-by-id'
            title='جزئیات گزارش حسابرسی'
        >
            <Card title='نمایش گزارش' blur={cardLoading}>
                <div className='log-info'>
                    <Row>
                        <span>توسط:</span>
                        <p>{log?.modifiedBy}</p>
                    </Row>

                    <Row>
                        <span>تاریخ:</span>
                        {log?.createdOn && (
                            <p>
                                {moment(log.createdOn)
                                    .utc()
                                    .locale("fa")
                                    .local()
                                    .format("jYYYY/MM/DD")}
                                {`-`}
                                {moment(log.createdOn)
                                    .utc()
                                    .local()
                                    .format("HH:mm")}
                            </p>
                        )}
                    </Row>
                    <Row>
                        <span>قبل:</span>
                        <p className='en'> {log?.before} </p>
                    </Row>
                    <Row>
                        <span>بعد:</span>
                        <p className='en'> {log?.after} </p>
                    </Row>
                    <Row>
                        <span>نام عملیات:</span>
                        <p> {log?.actionName} </p>
                    </Row>
                </div>
            </Card>
        </Page>
    )
}