import './edit-category.scss'
import {Page} from "../../../../../../components/page/page";
import {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {editCategory, getAllTopics, getCategoryById} from "../../../../../../core/repositories/content";
import toast from "react-hot-toast";
import {Input} from "../../../../../../components/input/input";
import {Button, buttonType} from "../../../../../../components/button/button";
import {Card} from "../../../../../../components/card/card";
import {Dropdown} from "../../../../../../components/dropdown/dropdown";
import {PaginationList, Topic} from "../../../../../../core/domain";

export interface EditCategoryBody {
    id?: string;
    topicId?: string,
    title?: string,
    englishTitle?: string;
    description?: string;
}

export const EditCategory = () => {

    const [topics, setTopics] = useState<PaginationList<Topic>>();
    const [body, setBody] = useState<EditCategoryBody>({
        title: "",
        englishTitle: "",
        description: "",
    })
    const [loading, setLoading] = useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [Category, setCategory] = useState<EditCategoryBody>()

    const handleEditCategory = () => {
        editCategory(setButtonLoading, body, () => toast.success("دسته بندی با موفقیت ویرایش شد"))
    }

    useEffect(() => {
        getCategoryById(setLoading, categoryId, setBody)
        getAllTopics(setLoading, setTopics, 1, 999, () => {})
    }, []);

    const location = useLocation()
    const categoryId =
        location.pathname.split("/")[location.pathname.split("/").length - 1];

    const formattedTopics = topics?.items.map(topic =>  ({id: topic.id, title: topic.title}))

    return (
        <Page className={"pmx-edit-category"} title={"ویرایش دسته بندی"}>
            <Card blur={loading}>
                {formattedTopics &&
                    <>
                <label>موضوع تاپیک دسته بندی جهت ویرایش</label>
                    <Dropdown items={formattedTopics} defaultSelected={formattedTopics.find(topic => topic.id === body.topicId)} onChangeHandle={(e: string) => setBody({...body, topicId: e})}/>
                    </>
                }
                <Input value={body?.title} placeholder={"نام دسته بندی جدید"} label={"نام دسته بندی جهت ویرایش"}
                       onChange={(e) => setBody({...body, title: e.target.value})}/>
                <Input value={body?.englishTitle} placeholder={"نام انگلیسی دسته بندی جدید"}
                       label={"نام انگلیسی دسته بندی جهت ویرایش"}
                       onChange={(e) => setBody({...body, englishTitle: e.target.value})}
                       dir={!body.englishTitle ? "rtl" : "ltr"}/>
                <Input value={body?.description} placeholder={"توضیحات دسته بندی جدید"}
                       label={"توضیحات دسته بندی جهت ویرایش"}
                       onChange={(e) => setBody({...body, description: e.target.value})}/>
                <Button type={buttonType.info} text={"ویرایش دسته بندی"} onClick={handleEditCategory} loading={buttonLoading}
                        disabled={buttonLoading}/>
                <Link to={"/dashboard/content/categories"}>
                    <Button type={buttonType.primary} text={"بازگشت به صفحه ی دسته بندی ها"}/>
                </Link>
            </Card>
        </Page>
    )
}