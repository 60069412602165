import {RootState} from "core/redux/store";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {MenuItem} from "core/domain/commons/common";
import React from "react";
import SidebarItem from "./sidebar-item/sidebar-item";
import logo from "assets/images/logo-main.png";
import {ReactComponent as Logout} from "assets/icons/dash-line-icons/logout.svg";

import "./sidebar.scss";
import {Modal} from "components/modal/modal";
import {Button, buttonType} from "components/button/button";
import {useNavigate} from "react-router-dom";
import {VERSION} from "../../core/constants/constants";
import {WaitingCounts} from "../../core/domain/dashboard/dashboard";


const mapState = (state: RootState) => ({
    state: state,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface PropTypes extends PropsFromRedux {
    items: MenuItem[];
    waitingCounts?: WaitingCounts;
}

const {useState, useEffect} = React;

const Sidebar: React.FC<PropTypes> = ({state, items, waitingCounts}) => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const navigate = useNavigate();

    const totalOrderCounts = waitingCounts ? waitingCounts.buyWaitingForAcceptReceipt + waitingCounts.sellWaitingForAcceptReceipt + waitingCounts.tradeWaitingForAcceptReceipt : 0

    return (
        <div className='pmx-sidebar'>
            <Modal setIsOpen={setIsOpen} isOpen={isOpen} className='logout-modal'>
                <p>آیا از خارج شدن از حسابتان اطمینان دارید؟</p>

                <div className='buttons'>
                    <Button
                        type={buttonType.info}
                        text='بله'
                        onClick={() => navigate("/signin")}
                    />
                    <Button
                        type={buttonType.error}
                        text='خیر'
                        onClick={() => setIsOpen(false)}
                    />
                </div>
            </Modal>
            <div className='sidebar-head'>
                <img src={logo}/>
            </div>

            <div className='sidebar-main desktop-show'>
                <div className='menu'>
                    {items?.map((item: MenuItem) => {
                        return (
                            <SidebarItem
                                id={item.id}
                                title={item.title}
                                icon={item.icon}
                                link={item.link}
                                isActive={item.isActive}
                                itemKey={item.itemKey}
                                childrens={item.childrens}
                                filled={item.filled ? item.filled : false}
                                waitingCounts={waitingCounts}
                            />
                        );
                    })}
                </div>
            </div>
            <div className='sidebar-footer'>
                <SidebarItem
                    className='red'
                    id={100}
                    title={"خروج"}
                    icon={<Logout/>}
                    link={""}
                    isActive={false}
                    filled={false}
                    onClickHandle={() => setIsOpen(true)}
                />
                <p className='en'>V{VERSION}</p>
            </div>
        </div>
    );
};
export default connector(Sidebar);
