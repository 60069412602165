import './sell.scss'
import {Page} from "../../../../../../components/page/page";
import {Modal} from "../../../../../../components/modal/modal";
import {Button, buttonType} from "../../../../../../components/button/button";
import {
    confirmAndPayoutOrder,
    getAllMarketTypes,
    getAllOrders, getAllOrderStateTypes,
    getAllOrderTypes
} from "../../../../../../core/repositories/order";
import {Card} from "../../../../../../components/card/card";
import {OrderDetailModal} from "../../order-detail/order-detail";
import {OrderEditModal} from "../../edit-modal/edit-modal";
import {Input} from "../../../../../../components/input/input";
import {Dropdown} from "../../../../../../components/dropdown/dropdown";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import {CopyOutlined, DollarCircleOutlined, EditOutlined, ExportOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {downloadCSV} from "../../../../../../core/utils/export-to-file";
import {Table} from "../../../../../../components/table/table";
import * as React from "react";
import {Enum, Order, PaginationList} from "../../../../../../core/domain";
import {useNavigate} from "react-router-dom";
import moment from "jalali-moment";
import {digitSperator, faNumToEnNum} from "../../../../../../core/utils/decimalExtentions";
import {copyToClipboard} from "../../../../../../core/utils/copyToClipboard";
import {getIconPath} from "../../../../../../core/utils/getIconPath";
import {Badge} from "../../../../../../components";
import {ReactComponent as CalendarIcon} from "assets/icons/dash-line-icons/calendar.svg";

interface FilterValues {
    search: string;
    state: Enum;
    type?: Enum;
    startDate: string;
    endDate: string;
    marketType?: Enum
}

const empty_filters = {
    search: "",
    state: {
        id: 5,
        title: "",
    },
    type: {
        id: 1,
        title: "",
    },
    marketType: {
        id: 0,
        title: "همه ارز ها"
    },
    startDate: moment().locale("fa").subtract(10, 'days').format('jYYYY/jMM/jDD'),
    endDate: "",
};


export const SellsWaitingForAcceptReceipt = () => {
    const [detailModalIsOpen, setDetailModalIsOpen] =
        React.useState<boolean>(false);

    const startDateRef = React.useRef();
    const endDateRef = React.useRef();

    const [selectedItem, setSelectedItem] = React.useState<Order>();
    const [confirmButtonLoading, setConfirmButtonLoading] =
        React.useState<boolean>(false);
    const [query, setQuery] = React.useState<string>("");
    const [editModalIsOpen, setEditModalIsOpen] = React.useState<boolean>(false);
    const [orderTypes, setOrderTypes] = React.useState<Enum[]>([]);
    const [marketTypes, setMarketTypes] = React.useState<Enum[]>([]);
    const [orderStateTypes, setOrderStateTypes] = React.useState<Enum[]>([
        {
            id: -2,
            title: "همه وضعیت",
        },
    ]);
    const [orders, setOrders] = React.useState<PaginationList<Order>>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
    const [deleteButtonLoading, setDeleteButtonLoading] =
        React.useState<boolean>(false);
    const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
    const [payoutModalIsOpen, setPayoutModalIsOpen] =
        React.useState<boolean>(false);
    const [filterValues, setFilterValues] =
        React.useState<FilterValues>(empty_filters);
    const [pageSize, setPageSize] = React.useState<number>(10);

    const navigate = useNavigate();

    const clearFiltersHandle = () => {
        setFilterValues({...empty_filters, startDate: ""});
        filterHandle(true);
    };

    const filterHandle = (clearFilter?: boolean) => {
        let _query = "?type=2&state=5";

        if (filterValues.search)
            _query += `${_query.includes("?") ? "&" : "?"}keywords=${
                filterValues.search
            }`;
        if (filterValues.marketType?.id !== 0)
            _query += `${_query.includes("?") ? "&" : "?"}marketType=${
                filterValues.marketType?.id
            }`;
        if (filterValues.startDate !== "")
            _query += `${_query.includes("?") ? "&" : "?"}startDate=${moment(
                faNumToEnNum(filterValues?.startDate),
                "jYYYY/jMM/jDD",
                "fa"
            )
                .locale("en")
                .format("YYYY/MM/DD")}`;
        if (filterValues.endDate !== "")
            _query += `${_query.includes("?") ? "&" : "?"}endDate=${moment(
                faNumToEnNum(filterValues?.endDate),
                "jYYYY/jMM/jDD",
                "fa"
            )
                .locale("en")
                .format("YYYY/MM/DD")}`;

        setQuery(_query);

        getAllOrders(
            clearFilter
                ? setDeleteButtonLoading
                : _query !== ""
                    ? setButtonLoading
                    : setLoading,
            setOrders,
            1,
            pageSize,
            clearFilter ? "?type=1&state=5" : _query
        );
    };

    React.useEffect(() => {
        fetches();
    }, []);

    async function fetches() {
        // const today = moment().locale('fa');
        // const startDate = today.subtract(10, 'days').format('jYYYY/jMM/jDD');
        // setFilterValues({ ...filterValues, startDate: startDate });
        await getAllOrderTypes(setLoading, setOrderTypes);
        await getAllMarketTypes(setLoading, setMarketTypes)
        getAllOrderStateTypes(setLoading, setOrderStateTypes);
        filterHandle();
    }

    const onPayoutIconClickHandle = (item) => {
        setSelectedItem(item);
        setPayoutModalIsOpen(true);
    };

    const onInfoIconClickHandle = (item) => {
        setSelectedItem(item);
        setDetailModalIsOpen(true);
    }

    const columns: any[] = [
        {
            name: "شناسه",
            id: "trackingCode",
            selector: (row: Order) => row.trackingCode,
            width: "14%",
            cell: (row: Order) => (
                <span className='en copiable'>
          {row.trackingCode}
                    <CopyOutlined
                        onClick={() => copyToClipboard(row.trackingCode, "شناسه سفارش")}
                    />
        </span>
            ),
        },
        {
            name: "نام",
            id: "fullName",
            selector: (row: Order) => row.fullName,
            width: "9%",
        },
        {
            name: "موبایل",
            id: "phoneNumber",
            selector: (row: Order) => row.phoneNumber,
            width: "8%",
        },
        {
            name: "جفت ارز",
            id: "pair",
            selector: (row: Order) => row?.sourceSymbol + row.destinationSymbol,
            cell: (row: Order) => (
                <div className='cell pair'>
                    <img src={getIconPath(row.sourceSymbol.toLowerCase())}/>
                    <span className='en'>{row.sourceSymbol}</span>
                    {` `} / {` `}
                    <span className='en'>{row.destinationSymbol}</span>
                    <img src={getIconPath(row.destinationSymbol.toLowerCase())}/>
                </div>
            ),
            width: "12%",
        },
        {
            name: "مبلغ مبدا",
            id: "sourceAmount",
            selector: (row: Order) =>
                row.sourceSymbol.toLowerCase() === "irt"
                    ? digitSperator(row.sourceAmount.toFixed(0))
                    : row.sourceAmount,
            width: "7%",
        },
        {
            name: "مبلغ مقصد",
            id: "destinationAmount",
            selector: (row: Order) =>
                row.destinationSymbol.toLowerCase() === "irt"
                    ? digitSperator(row.destinationAmount.toFixed(0))
                    : row.destinationAmount,
            width: "8%",
        },
        {
            name: "تاریخ ثبت سفارش",
            id: "dateTime",
            selector: (row: Order) => row?.creationDate || "",
            sortable: true,
            width: "10%",
            cell: (row: Order) => (
                <span>
             {moment.utc(row.creationDate).local().format("HH:mm")}{" "}
                    {moment
                        .utc(row?.creationDate)
                        .locale("fa")
                        .local()
                        .format("YYYY/MM/DD")}{" "}
        </span>
            ),
        },
        {
            name: "وضعیت",
            id: "state",
            width: "14%",
            selector: (row: Order) => row.state,
            cell: (row: Order) => (
                <Badge
                    text={row.state.title}
                    type={
                        row?.state?.id === 7
                            ? "success"
                            : row?.state?.title.includes("ناموفق")
                                ? "error"
                                : row?.state?.title.includes("در حال انجام")
                                    ? "waiting"
                                    : row?.state?.title.includes("پرداخت نشده") || row?.state.id === 1
                                        ? "error"
                                        : "waiting"
                    }
                />
            ),
        },

        {
            name: "نوع سفارش",
            id: "type",
            width: "8%",
            selector: (row: Order) => row.type,
            cell: (row: Order) => (
                <Badge
                    text={row.type}
                    type={
                        row.type.includes("خرید")
                            ? "success"
                            : row.type.includes("فروش")
                                ? "error"
                                : "info"
                    }
                />
            ),
        },

        {
            name: "عملیات",
            id: "action",
            width: "10%",
            cell: (row: Order) => (
                <div className='actions'>
                    <EditOutlined
                        onClick={() => {
                            setSelectedItem(row);
                            setEditModalIsOpen(true);
                        }}
                    />

                    <DollarCircleOutlined onClick={() => onPayoutIconClickHandle(row)}/>

                    <InfoCircleOutlined
                        onClick={() => onInfoIconClickHandle(row)}
                    />
                </div>
            ),
        },
    ];

    return (
        <Page
            className='pmx-buys-waiting-for-accept-receipt'
            title='فروش های در انتظار تایید'
        >
            <Modal
                setIsOpen={setPayoutModalIsOpen}
                isOpen={payoutModalIsOpen}
                className='confirm-payout-modal'
            >
                <p>
                    آیا از تایید و تسویه تراکنش کاربر {selectedItem?.fullName} اطمینان
                    دارید؟
                </p>

                <div className='buttons'>
                    <Button
                        type={buttonType.info}
                        text='بله'
                        onClick={() =>
                            confirmAndPayoutOrder(
                                setConfirmButtonLoading,
                                {trackingCode: selectedItem?.trackingCode},
                                () => setPayoutModalIsOpen(false),
                                () => getAllOrders(setLoading, setOrders, 1, pageSize, query)
                            )
                        }
                        loading={confirmButtonLoading}
                    />
                    <Button
                        type={buttonType.error}
                        text='خیر'
                        onClick={() => setPayoutModalIsOpen(false)}
                    />
                </div>
            </Modal>
            <Card title='سفارشات' blur={loading}>
                {selectedItem && (
                    <OrderDetailModal
                        selectedRow={selectedItem}
                        isOpen={detailModalIsOpen}
                        setIsOpen={setDetailModalIsOpen}
                        trackingId={selectedItem?.trackingCode}
                    />
                )}
                {selectedItem && orderStateTypes && editModalIsOpen && (
                    <OrderEditModal
                        orderStates={orderStateTypes}
                        isOpen={editModalIsOpen}
                        setIsOpen={setEditModalIsOpen}
                        item={selectedItem}
                        onClose={() => getAllOrders(setLoading, setOrders, 1, pageSize, query)}
                    />
                )}
                <div className='filter-row'>
                    <div className='fields'>
                        <Input
                            value={filterValues.search}
                            className='search'
                            placeholder='جستجو کنید ...'
                            onPressEnter={filterHandle}
                            name='search'
                            onChange={(e) =>
                                setFilterValues({...filterValues, search: e.target.value})
                            }
                        />
                        {marketTypes.length > 0 && (
                            <Dropdown
                                items={marketTypes}
                                onChangeHandle={(value) =>
                                    setFilterValues({
                                        ...filterValues,
                                        marketType: marketTypes.find((l) => l.id === value),
                                    })
                                }
                                selectedOption={filterValues.marketType}
                            />
                        )}

                        <DatePicker
                            className='date-picker'
                            value={filterValues.startDate}
                            calendar={persian}
                            ref={startDateRef}
                            locale={persian_fa}
                            render={
                                <div>
                                    <Input
                                        value={filterValues.startDate}
                                        icon={<CalendarIcon/>}
                                        placeholder='از تاریخ'
                                    />
                                </div>
                            }
                            onChange={(e) => {

                                setFilterValues({...filterValues, startDate: e.format()});
                            }}
                        />
                        <DatePicker
                            className='date-picker'
                            value={filterValues.endDate}
                            calendar={persian}
                            ref={endDateRef}
                            locale={persian_fa}
                            render={
                                <div>
                                    <Input
                                        value={filterValues.endDate}
                                        icon={<CalendarIcon/>}
                                        placeholder='تا تاریخ'
                                    />
                                </div>
                            }
                            onChange={(e) => {
                                setFilterValues({...filterValues, endDate: e.format()});
                            }}
                        />
                    </div>
                    <div className='buttons'>
                        <Button
                            loading={deleteButtonLoading}
                            disabled={deleteButtonLoading}
                            text='خروجی اکسل'
                            type={buttonType.info}
                            icon={<ExportOutlined/>}
                            onClick={(event) => downloadCSV("orders", orders.items)}
                        />
                        <Button
                            loading={buttonLoading}
                            disabled={buttonLoading}
                            text='اعمال فیلتر'
                            type={buttonType.success}
                            onClick={() => filterHandle()}
                        />
                        <Button
                            loading={deleteButtonLoading}
                            disabled={deleteButtonLoading}
                            text='حذف فیلتر'
                            type={buttonType.error}
                            onClick={clearFiltersHandle}
                        />

                    </div>
                </div>
                {orders?.items && orders?.items.length > 0 && (
                    <>
                        <Table
                            setPageSize={setPageSize}
                            blur={loading}
                            setloader={setLoading}
                            getdata={getAllOrders}
                            setdata={setOrders}
                            pagination={orders.pagination}
                            className='orders-table'
                            data={orders.items}
                            column={columns}
                            filters={query}
                        />
                    </>
                )}
            </Card>
        </Page>
    )
}