import * as React from "react";
import "./order-detail.scss";
import {Modal} from "components/modal/modal";
import {ToIranCurrency} from "core/utils/decimalExtentions";
import {Badge} from "components/badge/badge";
import {Order, OrderDetail} from "core/domain/order/order";
import moment from "jalali-moment";
import {Spin} from "antd";
import {getOrderDetailByTrackingCode} from "core/repositories/order";
import {CheckCircleTwoTone, CloseCircleTwoTone, CopyOutlined} from "@ant-design/icons";
import {copyToClipboard} from "../../../../../core/utils/copyToClipboard";
import Countdown from "antd/lib/statistic/Countdown";

interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    trackingId: string;
    selectedRow: Order;
}

export const OrderDetailModal: React.FC<Props> = ({
                                                      isOpen,
                                                      setIsOpen,
                                                      trackingId,
                                                      selectedRow,
                                                  }) => {
    const [loading, setLoading] = React.useState(false);
    const [order, setOrder] = React.useState<OrderDetail>();
    const [timer, setTimer] = React.useState<any>(0);

    const fetchOrderDetailByTrackingCode = async () => {
        if (isOpen == true)
            await getOrderDetailByTrackingCode(
                setLoading,
                setOrder,
                setTimer,
                trackingId,
            );
    };

    React.useEffect(() => {
        fetchOrderDetailByTrackingCode();
    }, [isOpen]);

    return (
        <Modal
            className="pmx-order-track-detail"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
            {loading ? (
                <Spin spinning={loading}/>
            ) : (
                <>
                    <h3>جزئیات سفارش</h3>
                    <div className="row">
                        <span>کد پیگیری</span>
                        <p className=" en">
                            <CopyOutlined
                                onClick={() =>
                                    copyToClipboard(order?.trackingCode, "کد پیگیری")
                                }
                            />
                            {order?.trackingCode}
                        </p>
                    </div>
                    <div className="row">
                        <span>ارز مبدا</span>
                        <p className=" en">{order?.sourceSymbol}</p>
                    </div>
                    <div className="row">
                        <span>ارز مقصد</span>
                        <p className=" en">{order?.destinationSymbol}</p>
                    </div>
                    <div className="row">
                        <span>مقدار واریزی</span>
                        <p>
                            {order?.sourceSymbol === "IRT"
                                ? ToIranCurrency(order?.sourceAmount)
                                : order?.sourceAmount}
                        </p>
                    </div>
                    <div className="row">
                        <span>مقدار دریافتی</span>
                        <p>
                            {order?.destinationSymbol === "IRT"
                                ? ToIranCurrency(order?.destinationAmount)
                                : order?.destinationAmount}
                        </p>
                    </div>

                    {order?.voucher && (
                        <div className="row">
                            <span>کد ووچر</span>
                            <p>{order?.voucher}</p>
                        </div>
                    )}
                    {order?.voucherActivation && (
                        <div className="row">
                            <span>کد فعالساز ووچر</span>
                            <p>{order?.voucherActivation}</p>
                        </div>
                    )}
                    {order?.batchNumber ? (
                        <div className="row">
                            <span>بچ نامبر</span>
                            <p className="en">
                                <CopyOutlined
                                    onClick={() =>
                                        copyToClipboard(order?.batchNumber, "بچ نامبر")
                                    }
                                />
                                {order?.batchNumber}
                            </p>
                        </div>
                    ) : (
                        <></>
                    )}
                    {order?.txid && (
                        <div className="row copy">
                            <span>tx-id</span>
                            <p className="en long-text">{order?.txid}</p>
                            <CopyOutlined
                                onClick={() => copyToClipboard(order?.txid, "tx-id")}
                            />
                        </div>
                    )}
                    {order?.digitalRecept && (
                        <div className="row copy">
                            <span>رسید دیجیتالی</span>
                            <p className={"long-text"}>{order?.digitalRecept}</p>
                            <CopyOutlined
                                onClick={() =>
                                    copyToClipboard(order?.digitalRecept, "رسید دیجیتالی")
                                }
                            />
                        </div>
                    )}
                    {order?.iBan && (
                        <div className="row">
                            <span>شماره شبا</span>
                            <p>{order?.iBan}</p>
                        </div>
                    )}
                    {order?.destinationWalletAddress && (
                        <div className="row">
                            <span>آدرس کیف پول</span>
                            <p className="en">{order?.destinationWalletAddress}</p>
                        </div>
                    )}
                    {selectedRow.fullName.replaceAll(" ", "") !== "" ? (
                        <div className="row">
                            <span>نام کامل کاربر</span>

                            <p>{selectedRow.fullName}{selectedRow.authMediaSurveyIsConfirmed ?
                                <CheckCircleTwoTone twoToneColor="#52c41a"/> :
                                <CloseCircleTwoTone twoToneColor={"red"}/>}</p>


                        </div>
                    ) : (
                        <div className="row">
                            <span>نام کاربری</span>
                            <p>{selectedRow?.phoneNumber}</p>
                        </div>
                    )}
                    {order?.phoneNumber && (
                        <div className="row">
                            <span>شماره کاربر</span>
                            <p>{order?.phoneNumber}</p>
                        </div>
                    )}
                    <div className="row">
                        <span>نوع سفارش</span>
                        <p>
                            {order?.sourceSymbol == "IRT"
                                ? "خرید"
                                : order?.destinationSymbol == "IRT"
                                    ? "فروش"
                                    : "تبدیل"}
                        </p>
                    </div>
                    {order?.destinationMoneyAccount && (
                        <div className="row">
                            <span>اکانت ارز مقصد</span>
                            <p>{order?.destinationMoneyAccount}</p>
                        </div>
                    )}
                    <div className="row">
                        <span>تاریخ و زمان سفارش</span>
                        <p>
                            {moment
                                .utc(order?.creationDate)
                                .local()
                                .locale("fa")
                                .format("YYYY/MM/DD")}{" "}
                            |{" "}
                            {moment
                                .utc(order?.creationDate)
                                .local()
                                .locale("fa")
                                .format("HH:mm")}
                        </p>
                    </div>
                    {(order?.state?.id === 9 || order?.state?.id === 10) && (
                        <div className="row">
                            <span>زمان باقی مانده تا واریز</span>
                            <Countdown value={timer}/>
                            {timer}
                        </div>
                    )}
                    <div className="row">
                        <span>وضعیت</span>
                        <p>
                            <Badge
                                text={order?.state?.title}
                                type={
                                    order?.state?.title.includes("کنسل شده") ||
                                    order?.state?.title.includes("ناموفق")
                                        ? "error"
                                        : order?.state?.title.includes("در حال") ||
                                        order?.state.title.includes("انتظار")
                                            ? "waiting"
                                            : order?.state?.title.includes("نشده")
                                                ? "error"
                                                : "success"
                                }
                            />
                        </p>
                    </div>
                </>
            )}
        </Modal>
    );
};
