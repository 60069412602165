import './overview.scss'
import {Page} from "../../../../components/page/page";
import {CountBuyAndSell} from "./charts/count-buy-and-sell/count-buy-and-sell";
import {PMCountBuyAndSell} from "./charts/pm-count-buy-and-sell/pm-count-buy-and-sell";
import {CryptoCountBuyAndSell} from "./charts/crypto-count-buy-and-sell/crypto-count-buy-and-sell";
import {SumBuyAndSell} from "./charts/sum-buy-and-sell/sum-buy-and-sell";
import {PMSumBuyAndSell} from "./charts/pm-sum-buy-and-sell/pm-sum-buy-and-sell";
import {CryptoSumBuyAndSell} from "./charts/crypto-sum-buy-and-sell/crypto-sum-buy-and-sell";
import {USDTWithdrawalCount} from "./charts/usdt-withdrawal-count/usdt-withdrawal-count";
import {SumBuyAndSellCommissions} from "./charts/sum-buy-and-sell-commissions/sum-buy-and-sell-commissions";
import {Balances} from "./balances/balances";
import {UsersChart} from "./charts/users/users";
import {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {DateFilterValues} from "../../../../core/domain/overview/overview";
import {AuthenticatedUsersCountChartProps} from "../../../../core/domain/overview/authenticated-users-count";
import {CountBuyAndSellChartProps} from "../../../../core/domain/overview/count-buy-and-sell";
import {CryptoCountBuyAndSellChartProps} from "../../../../core/domain/overview/crypto-count-buy-and-sell";
import {CryptoSumBuyAndSellChartProps} from "../../../../core/domain/overview/crypto-sum-buy-and-sell";
import {PMCountBuyAndSellChartProps} from "../../../../core/domain/overview/pm-count-buy-and-sell";
import {PMSumBuyAndSellChartProps} from "../../../../core/domain/overview/pm-sum-buy-and-sell";
import {RegisteredUsersCountChartProps} from "../../../../core/domain/overview/registered-users-count";
import {SumBuyAndSellChartProps} from "../../../../core/domain/overview/sum-buy-and-sell";
import {SumBuyAndSellCommissionsChartProps} from "../../../../core/domain/overview/sum-buy-and-sell-commissions";
import {UploadedDocumentsChartProps} from "../../../../core/domain/overview/uploaded-documents";
import {USDTWithdrawalCountChartProps} from "../../../../core/domain/overview/usdt-withdrawal-count";
import moment from "jalali-moment";
import {faNumToEnNum} from "../../../../core/utils/decimalExtentions";
import {
    getAuthenticatedUsersCount,
    getBalances,
    getCountBuyAndSell,
    getCryptoCountBuyAndSell,
    getCryptoSumBuyAndSell,
    getFirstBuys,
    getPMCountBuyAndSell,
    getPMSumBuyAndSell,
    getRegisteredUsersCount,
    getSumBuyAndSell, getSumBuyAndSellCommissions, getUploadedDocuments, getUSDTWithdrawalCount
} from "../../../../core/repositories/overview";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import {Input} from "../../../../components/input/input";
import {Button, buttonType} from "../../../../components/button/button";
import * as React from "react";
import {ReactComponent as CalendarIcon} from "assets/icons/overview/Calendar.svg";
import {BalancesProps} from "../../../../core/domain/overview/balances";
import {FirstBuysChartProps} from "../../../../core/domain/overview/first-buys";
import {FirstBuys} from "./charts/first-buys/first-buys";

const initialFilters = {
    startDate: moment().subtract(7, 'days').format("jYYYY/jMM/jDD"),
    endDate: ""
}

const emptyFilters = {
    startDate: "",
    endDate: ""
}

export const Overview = () => {
    const [filterValues, setFilterValues] = useState<DateFilterValues>(initialFilters);
    const [query, setQuery] = useState("");
    const [loading, setLoading] = useState<boolean>(false)
    const [submitFilterLoading, setSubmitFilterLoading] = useState<boolean>(false)
    const [clearFilterLoading, setClearFilterLoading] = useState<boolean>(false)
    const [authenticatedUsersCountData, setAuthenticatedUsersCountData] = useState<AuthenticatedUsersCountChartProps>();
    const [countBuyAndSellData, setCountBuyAndSellData] = useState<CountBuyAndSellChartProps>();
    const [cryptoCountBuyAndSellData, setCryptoCountBuyAndSellData] = useState<CryptoCountBuyAndSellChartProps>();
    const [cryptoSumBuyAndSellData, setCryptoSumBuyAndSellData] = useState<CryptoSumBuyAndSellChartProps>();
    const [pmCountBuyAndSellData, setPMCountBuyAndSellData] = useState<PMCountBuyAndSellChartProps>();
    const [pmSumBuyAndSellData, setPMSumBuyAndSellData] = useState<PMSumBuyAndSellChartProps>();
    const [registeredUsersCountData, setRegistersUsersCountData] = useState<RegisteredUsersCountChartProps>();
    const [sumBuyAndSellData, setSumBuyAndSellData] = useState<SumBuyAndSellChartProps>();
    const [sumBuyAndSellCommissionsData, setSumBuyAndSellCommissionsData] = useState<SumBuyAndSellCommissionsChartProps>();
    const [uploadedDocumentsData, setUploadedDocumentsData] = useState<UploadedDocumentsChartProps>();
    const [usdtWithdrawalCountData, setUSDTWithdrawalCountData] = useState<USDTWithdrawalCountChartProps>();
    const [firstBuysData, setFirstBuysData] = useState<FirstBuysChartProps>()
    const [balances, setBalances] = useState<BalancesProps>()

    const startDateRef = useRef(null);
    const endDateRef = useRef(null);

    const filterHandle = () => {
        let _query = "";

        if (filterValues.startDate !== "")
            _query += `${_query.includes("?") ? "&" : "?"}startDate=${moment(
                faNumToEnNum(filterValues?.startDate),
                "jYYYY/jMM/jDD",
                "fa",
            )
                .locale("en")
                .format("YYYY/MM/DD")}`;
        if (filterValues.endDate !== "")
            _query += `${_query.includes("?") ? "&" : "?"}endDate=${moment(
                faNumToEnNum(filterValues?.endDate),
                "jYYYY/jMM/jDD",
                "fa",
            )
                .locale("en")
                .format("YYYY/MM/DD")}`;
        setQuery(_query);
        fetchAllData(setSubmitFilterLoading, _query)
    };

    const fetchAllData = async (setLoading: Dispatch<SetStateAction<boolean>>, query?: string) => {
        await getCountBuyAndSell(setLoading, setCountBuyAndSellData, query)
        getCryptoCountBuyAndSell(setLoading, setCryptoCountBuyAndSellData, query)
        getCryptoSumBuyAndSell(setLoading, setCryptoSumBuyAndSellData, query)
        getPMCountBuyAndSell(setLoading, setPMCountBuyAndSellData, query)
        getPMSumBuyAndSell(setLoading, setPMSumBuyAndSellData, query)
        getSumBuyAndSell(setLoading, setSumBuyAndSellData, query)
        getSumBuyAndSellCommissions(setLoading, setSumBuyAndSellCommissionsData, query)
        getAuthenticatedUsersCount(setLoading, setAuthenticatedUsersCountData, query)
        getRegisteredUsersCount(setLoading, setRegistersUsersCountData, query)
        getUploadedDocuments(setLoading, setUploadedDocumentsData, query)
        getUSDTWithdrawalCount(setLoading, setUSDTWithdrawalCountData, query)
        getFirstBuys(setLoading, setFirstBuysData, query)
        getBalances(setLoading, setBalances)
    }

    useEffect(() => {
        fetchAllData(setLoading, `?startDate=${moment().subtract(7, 'days').format("YYYY/MM/DD")}`)
    }, []);

    return (
        <Page className='pmx-overview' title='پیشخوان'>
            <div className={"row"}>
                <Balances balancesData={balances} balancesLoading={loading}/>
                <div className='filter-row'>
                    <div className='fields'>
                        <DatePicker
                            className='date-picker'
                            value={filterValues.startDate}
                            calendar={persian}
                            ref={startDateRef}
                            locale={persian_fa}
                            render={
                                <div>
                                    <Input
                                        value={filterValues.startDate}
                                        icon={<CalendarIcon/>}
                                        placeholder='تاریخ'
                                        prefix={"از"}
                                    />
                                </div>
                            }
                            onChange={(e) => {
                                setFilterValues({...filterValues, startDate: e.format()});
                            }}
                        />
                        <DatePicker
                            className='date-picker'
                            value={filterValues.endDate}
                            calendar={persian}
                            ref={endDateRef}
                            locale={persian_fa}
                            render={
                                <div>
                                    <Input
                                        value={filterValues.endDate}
                                        icon={<CalendarIcon/>}
                                        placeholder='تاریخ'
                                        prefix={"تا"}
                                    />
                                </div>
                            }
                            onChange={(e) => {
                                setFilterValues({...filterValues, endDate: e.format()});
                            }}
                        />
                        <div className='buttons'>
                            <Button
                                loading={submitFilterLoading}
                                disabled={submitFilterLoading || loading}
                                text='اعمال فیلتر'
                                type={buttonType.success}
                                onClick={filterHandle}
                            />
                            <Button
                                loading={clearFilterLoading}
                                disabled={clearFilterLoading || loading}
                                text='حذف فیلتر'
                                type={buttonType.error}
                                onClick={() => {
                                    setFilterValues(emptyFilters);
                                    fetchAllData(setClearFilterLoading);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={"pmx-charts"}>
                <CountBuyAndSell chartData={countBuyAndSellData} chartLoading={loading}/>
                <SumBuyAndSell chartData={sumBuyAndSellData} chartLoading={loading}/>
                <PMCountBuyAndSell chartData={pmCountBuyAndSellData} chartLoading={loading}/>
                <PMSumBuyAndSell chartData={pmSumBuyAndSellData} chartLoading={loading}/>
                <CryptoCountBuyAndSell chartData={cryptoCountBuyAndSellData} chartLoading={loading}/>
                <CryptoSumBuyAndSell chartData={cryptoSumBuyAndSellData} chartLoading={loading}/>
                <SumBuyAndSellCommissions chartData={sumBuyAndSellCommissionsData} chartLoading={loading}/>
                <USDTWithdrawalCount chartData={usdtWithdrawalCountData} chartLoading={loading}/>
                {/*<UploadedDocuments chartData={uploadedDocumentsData} chartLoading={loading}/>*/}
                {/*<RegisteredUsersCount chartData={registeredUsersCountData} chartLoading={loading}/>*/}
                {/*<AuthenticatedUsersCount chartData={authenticatedUsersCountData} chartLoading={loading}/>*/}
                <UsersChart authenticatedUsersData={authenticatedUsersCountData} registeredUsersData={registeredUsersCountData} uploadedDocumentsData={uploadedDocumentsData} chartLoading={loading} />
                <FirstBuys chartData={firstBuysData} chartLoading={loading} />
            </div>
        </Page>
    )
}