import './users-saved-ibans.scss'
import {Page} from "../../../../components/page/page";
import {Card} from "../../../../components/card/card";
import {useEffect, useState} from "react";
import {PaginationList} from "../../../../core/domain";
import moment from "jalali-moment";
import {CopyOutlined, DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {UserSavedIBans} from "../../../../core/domain/users-saved-ibans/users-saved-ibans";
import {message} from "antd";
import {getAllIBans} from "../../../../core/repositories/users-saved-ibans";
import {Table} from "../../../../components/table/table";
import {Input} from "../../../../components/input/input";
import {Button, buttonType} from "../../../../components/button/button";
import {Link} from "react-router-dom";
import {EditIBanModal} from "./edit-modal/edit-modal";
import {RemoveIBanModal} from "./remove-modal/remove-modal";
import {copyToClipboard} from "../../../../core/utils/copyToClipboard";

interface FilterValues {
    search: string
}

const empty_filters: FilterValues = {
    search: ''
}

export const UsersSavedIBans = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [buttonLoading, setButtonLoading] = useState<boolean>(false)
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)
    const [filterValues, setFilterValues] = useState<FilterValues>(empty_filters)
    const [query, setQuery] = useState<string>()
    const [savedIBans, setSavedIBans] = useState<PaginationList<UserSavedIBans>>()
    const [selectedIBan, setSelectedIBan] = useState<UserSavedIBans>()
    const [pageSize, setPageSize] = useState<number>(10);

    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

    const columns: any[] = [
        {
            name: "نام و نام خانوادگی",
            id: "fullName",
            selector: (row: UserSavedIBans) => row.fullName,
            width: "15%",
        },
        {
            name: "شماره موبایل",
            id: "phoneNumber",
            selector: (row: UserSavedIBans) => row.phoneNumber,
            width: "15%",
        },
        {
            name: "عنوان شماره شبا",
            id: "label",
            selector: (row: UserSavedIBans) => row.label,
            width: "15%",
        },
        {
            name: "شماره شبا",
            id: "iBan",
            selector: (row: UserSavedIBans) => row.iBan,
            width: "20%",
            cell: (row: UserSavedIBans) => <><CopyOutlined style={{cursor: 'pointer', transform: 'translateY(-1px)'}} onClick={() => copyToClipboard(row.iBan, 'شماره شبا')} />&nbsp;{row.iBan}</>
        },
        {
            name: "تاریخ ایجاد",
            id: "createdOn",
            selector: (row: UserSavedIBans) => row.createdOn,
            width: "15%",
            cell: (row: UserSavedIBans) => moment
                .utc(row?.createdOn)
                .locale("fa")
                .local()
                .format("HH:mm - YYYY/MM/DD")
        },
        {
            name: "عملیات",
            id: "action",
            width: "10%",
            cell: (row: UserSavedIBans) => (
                <div className='actions'>
                    <DeleteOutlined
                        onClick={() => {
                            openDeleteModal(row);
                        }}
                    />
                    <EditOutlined
                        onClick={() => {
                            openEditModal(row);
                        }}
                    />
                </div>
            ),
        },
    ];

    const openDeleteModal = (item: UserSavedIBans) => {
        setSelectedIBan(item)
        setDeleteModalOpen(true);
    }

    const openEditModal = (item: UserSavedIBans) => {
        setSelectedIBan(item)
        setEditModalOpen(true)
    }

    const onCloseDeleteModal = () => {
        setDeleteModalOpen(false);
        setSelectedIBan(undefined)
        message.success("شماره شبا با موفقیت حذف شد")
        filterHandle()
    }

    const onCloseEditModal = () => {
        setEditModalOpen(false);
        setSelectedIBan(undefined)
        message.success("شماره شبا با موفقیت ویرایش شد")
        filterHandle()
    }

    const filterHandle = (clearFilter?: boolean) => {
        var _query = "";

        if (filterValues.search !== "")
            _query += `${_query.includes("?") ? "&" : "?"}Keyword=${
                filterValues.search
            }`;
        setQuery(_query);

        getAllIBans(
            clearFilter
                ? setDeleteLoading
                : _query !== ""
                    ? setButtonLoading
                    : setLoading,
            setSavedIBans,
            1,
            pageSize,
            clearFilter ? "" : _query,
        );
    };

    const clearFiltersHandle = () => {
        setFilterValues({...filterValues, search: ''});
        filterHandle(true);
    };

    async function fetches() {
        await filterHandle();
    }

    useEffect(() => {
        fetches()
    }, []);

    return (
        <Page
            className='pmx-users-saved-ibans'
            title='مدیریت شماره شبا های ثبت شده'
        >
            <Card
                title='نمایش شماره شبا های ثبت شده'
                blur={loading}
            >
                <EditIBanModal isOpen={editModalOpen} setIsOpen={setEditModalOpen} item={selectedIBan} onClose={onCloseEditModal} />
                <RemoveIBanModal isOpen={deleteModalOpen} setIsOpen={setDeleteModalOpen} item={selectedIBan} onClose={onCloseDeleteModal} />
                <div className='filter-row'>
                    <div className='fields'>
                        <Input
                            value={filterValues.search}
                            className='search'
                            placeholder='عبارت جهت جستجو را وارد کنید'
                            name='search'
                            onPressEnter={filterHandle}
                            onChange={(e) => setFilterValues({...filterValues, search: e.target.value})}
                        />
                    </div>
                    <div className='buttons'>
                        <Button
                            loading={buttonLoading}
                            disabled={buttonLoading}
                            text='اعمال فیلتر'
                            type={buttonType.success}
                            onClick={() => filterHandle()}
                        />
                        <Button
                            loading={deleteLoading}
                            disabled={deleteLoading}
                            text="حذف فیلتر"
                            type={buttonType.error}
                            onClick={clearFiltersHandle}
                        />
                        <Link to={"/dashboard/users-saved-ibans/add"}>
                            <Button type={buttonType.info} text={"افزودن"} icon={<PlusOutlined/>}/>
                        </Link>
                    </div>
                </div>

                <Table
                    setPageSize={setPageSize}
                    blur={loading}
                    setloader={setLoading}
                    getdata={getAllIBans}
                    setdata={setSavedIBans}
                    className='saved-ibans-table'
                    data={savedIBans?.items}
                    pagination={savedIBans?.pagination}
                    column={columns}
                    filters={query}
                />
            </Card>
        </Page>
    )
}