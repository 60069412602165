import { API } from "apis";
import { getViaAuth, responseValidator } from "apis/api";
import { PaginationList } from "core/domain";
import { AuditLogAll, AuditLogById } from "core/domain/audit-log/audit-log";

export const getAllAuditLogs = async (
  setLoading: any,
  setData: any,
  page: number,
  pageSize: number
) => {
  setLoading(true);
  await getViaAuth<PaginationList<AuditLogAll>>(
    `${API.auditLog.getAll}/${page}/${pageSize}`
  ).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      if (response.data) {
        setData(response.data);
      }
    }
  });
};

export const getAuditLogById = async (
  setLoading: any,
  setData: any,
  id: string
) => {
  setLoading(true);
  await getViaAuth<AuditLogById>(`${API.auditLog.getById}/${id}`).then(
    (response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) setData(response.data);
    }
  );
};
