import { Empty, Spin } from "antd";
import * as React from "react";
import "./table.scss";
import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";
import DataTable, { Direction, TableColumn } from "react-data-table-component";
import { Pagination as Paging } from "core/domain/commons/common";
import { Pagination } from "components/pagination/pagination";

interface Props {
  className: string;
  data: any;
  setloader?: any;
  setdata?: any;
  getdata?: any;
  column: TableColumn<any>[];
  expandableRowsComponent?: ({ data }: any) => JSX.Element;
  blur?: boolean;
  url?: string;
  filters?: string;
  query?: string;
  setloading?: any;
  sort?: { setsort?: any; sort?: any };
  pagination?: Paging;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
}

export const Table: React.FC<Props> = (props) => {
  const [data, setData] = React.useState<any[]>(props.data);
  const [loading, setLoading] = React.useState<boolean>();

  return (
    <div className={`pmx-data-table ${props.className ? props.className : ""}`}>
      <DataTable
        columns={props.column}
        noDataComponent={<Empty description='رکوردی یافت نشد' />}
        progressComponent={
          <Spin tip='در حال بارگذاری...' spinning={loading}></Spin>
        }
        data={props.data}
        expandableRows={props.expandableRowsComponent ? true : false}
        expandableRowsComponent={props.expandableRowsComponent}
        progressPending={loading}
        direction={Direction.RTL}
        sortIcon={
          props.sort?.sort === "asc" ? <CaretUpFilled /> : <CaretDownFilled />
        }
        onSort={() => {
          props.sort?.sort === "asc"
            ? props.sort?.setsort("desc")
            : props.sort?.setsort("asc");
        }}
        responsive
      />
      {props.pagination && props.data.length > 0 && (
        <Pagination
          page={props.pagination.pageIndex || 0}
          data={data}
          setdata={props.setdata}
          setloader={props.setloader}
          getdata={props.getdata}
          size={props.pagination.pageSize || 10}
          query={props.filters}
          totalRows={props.pagination.totalCount}
          setPageSize={props.setPageSize}
        />
      )}
    </div>
  );
};
