import * as React from "react";
import "./referral-infos.scss";
import { Page } from "components/page/page";
import { Card } from "components/card/card";
import { Table } from "components/table/table";
import { ReferralInfo } from "core/domain";
import { CopyOutlined, ExportOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { getAllReferralInfos } from "core/repositories/referral";
import { Button, buttonType } from "components/button/button";
import { Input } from "components/input/input";
// import { ClientSidePagination } from "components/pagination/pagination";
import { PaginationList } from "../../../../core/domain/commons/common";
import moment from "jalali-moment";
import { getPageNum } from "core/utils/paggingHelper";
import { copyToClipboard } from "core/utils/copyToClipboard";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { ReactComponent as CalendarIcon } from "assets/icons/dash-line-icons/calendar.svg";
import { faNumToEnNum } from "core/utils/decimalExtentions";
import { downloadCSV } from "../../../../core/utils/export-to-file";
import { digitSperator } from "core/utils/decimalExtentions";

interface FilterValues {
  search: string;
  startDate: string;
  endDate: string;
}

const empty_filters = {
  search: "",
  startDate: "",
  endDate: "",
};

export const ReferralInfos: React.FC = () => {
  var itemsPerPageCount = 20;

  const referralInfoLevels = [
    {
      id: 0,
      title: "همه",
    },
    {
      id: 1,
      title: "پایه",
    },
    {
      id: 2,
      title: "نقره ای",
    },
    {
      id: 3,
      title: "طلایی",
    },
  ];

  const defaultLevelSelected = {
    id: 0,
    title: "سطح کاربر را انتخاب کنید",
  };

  const [referralInfos, setReferralInfos] =
    React.useState<PaginationList<ReferralInfo>>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
  const [query, setQuery] = React.useState<string>("");
  const [deleteButtonLoading, setDeleteButtonLoading] =
    React.useState<boolean>(false);
  const [pageEnd, setPageEnd] = React.useState(10);
  const [filterValues, setFilterValues] =
    React.useState<FilterValues>(empty_filters);
  const [pageSize, setPageSize] = React.useState<number>(10);

  const navigate = useNavigate();
  const startDateRef = React.useRef(null);
  const endDateRef = React.useRef(null);

  const filterHandle = (clearFilter?: boolean) => {
    var _query = "";

    if (filterValues.search !== "")
      _query += `${_query.includes("?") ? "&" : "?"}keywords=${
        filterValues.search
      }`;
    if (filterValues.startDate !== "") {
      _query += `${_query.includes("?") ? "&" : "?"}startDate=${moment(
        faNumToEnNum(filterValues.startDate),
        "jYYYY/jMM/jDD",
        "fa",
      )
        .locale("en")
        .format("YYYY/MM/DD")}`;
    }
    if (filterValues.endDate !== "") {
      _query += `${_query.includes("?") ? "&" : "?"}endDate=${moment(
        faNumToEnNum(filterValues.endDate),
        "jYYYY/jMM/jDD",
        "fa",
      )
        .locale("en")
        .format("YYYY/MM/DD")}`;
    }

    setQuery(_query);
    getAllReferralInfos(
      clearFilter
        ? setDeleteButtonLoading
        : _query !== ""
        ? setButtonLoading
        : setLoading,
      setReferralInfos,
      getPageNum(),
      pageSize,
      clearFilter ? "" : _query,
    );
  };

  const clearFiltersHandle = () => {
    setFilterValues(empty_filters);
    filterHandle(true);
  };

  React.useEffect(() => {
    getAllReferralInfos(setLoading, setReferralInfos, getPageNum(), pageSize);
  }, []);

  const columns: any[] = [
    {
      name: "کد دعوت",
      id: "referralCode",
      selector: (row: ReferralInfo) => row.referralCode,
      width: "15%",
      cell: (row: ReferralInfo) => (
        <span className="en copiable">
          {row?.referralCode}
          <CopyOutlined
            onClick={() => copyToClipboard(row.referralCode, "کد دعوت")}
          />
        </span>
      ),
    },
    {
      name: "نام کامل",
      id: "fullName",
      selector: (row: ReferralInfo) => row.fullName,
      width: "15%",
    },
    {
      name: "موبایل",
      id: "phoneNumber",
      selector: (row: ReferralInfo) => row.phoneNumber,
      width: "20%",
    },
    {
      name: "موجودی",
      id: "balance",
      selector: (row: ReferralInfo) => digitSperator(row.balance),
      width: "20%",
    },
    {
      name: "تاریخ ایجاد",
      id: "createdOn",
      selector: (row: ReferralInfo) => row?.createdOn || "",
      sortable: true,
      cell: (row: ReferralInfo) => (
        <span>
          {moment
            .utc(row?.createdOn)
            .locale("fa")
            .local()
            .format("YYYY/MM/DD")}{" "}
          {moment
            .utc(row?.createdOn)
            .locale("fa")
            .local()
            .format("HH:mm")}
        </span>
      ),
    },
  ];
  return (
    <Page className="pmx-referral-infos" title="همکاران ما">
      <Card title="لیست همکاران" blur={loading}>
        <div className="filter-row">
          <div className="fields">
            <Input
              value={filterValues.search}
              className="search"
              placeholder="جستجو کنید ..."
              onPressEnter={filterHandle}
              name="search"
              onChange={(e) =>
                setFilterValues({ ...filterValues, search: e.target.value })
              }
            />
            <DatePicker
              className="date-picker"
              value={filterValues.startDate}
              calendar={persian}
              ref={startDateRef}
              locale={persian_fa}
              render={
                <div>
                  <Input
                    value={filterValues.startDate}
                    icon={<CalendarIcon />}
                    placeholder="از تاریخ"
                  />
                </div>
              }
              onChange={(e) => {
                setFilterValues({ ...filterValues, startDate: e.format() });
              }}
            />
            <DatePicker
              className="date-picker"
              value={filterValues.endDate}
              calendar={persian}
              ref={endDateRef}
              locale={persian_fa}
              render={
                <div>
                  <Input
                    value={filterValues.endDate}
                    icon={<CalendarIcon />}
                    placeholder="تا تاریخ"
                  />
                </div>
              }
              onChange={(e) => {
                setFilterValues({ ...filterValues, endDate: e.format() });
              }}
            />
          </div>
          <div className="buttons">
            <Button
              loading={buttonLoading}
              disabled={buttonLoading}
              text="اعمال فیلتر"
              type={buttonType.success}
              onClick={() => filterHandle()}
            />
            <Button
              loading={deleteButtonLoading}
              disabled={deleteButtonLoading}
              text="حذف فیلتر"
              type={buttonType.error}
              onClick={clearFiltersHandle}
            />
            <Button
              loading={deleteButtonLoading}
              disabled={deleteButtonLoading}
              text="خروجی اکسل"
              type={buttonType.info}
              icon={<ExportOutlined />}
              onClick={(event) => downloadCSV("referrals", referralInfos.items)}
            />
          </div>
        </div>
        {referralInfos?.items && referralInfos?.items.length > 0 && (
          <>
            <Table
              setPageSize={setPageSize}
              blur={loading}
              setloader={setLoading}
              getdata={getAllReferralInfos}
              setdata={setReferralInfos}
              pagination={referralInfos.pagination}
              className="referral-infos-table"
              data={referralInfos.items}
              column={columns}
              filters={query}
            />
          </>
        )}
      </Card>
    </Page>
  );
};
