import * as React from "react";
import "./card.scss";
import { Spin } from "antd";

interface Props {
  title?: string;
  className?: string;
  children?: React.ReactNode;
  blur?: boolean;
}

export const Card = (props: Props) => {
  return (
    <Spin spinning={props.blur ? props.blur : false}>
      <div className={`pmx-card ${props.className}`}>
        {props.title && <h4>{props.title}</h4>}
        <div className='content'>{props.children}</div>
      </div>
    </Spin>
  );
};
