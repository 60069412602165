import {Page} from 'components/page/page'
import './add-category.scss'
import {Card} from "../../../../../../components/card/card";
import {Input} from "../../../../../../components/input/input";
import {useEffect, useState} from "react";
import {Button, buttonType} from "../../../../../../components/button/button";
import {addCategory, addTopic, getAllTopics} from "../../../../../../core/repositories/content";
import toast from "react-hot-toast";
import {Link} from "react-router-dom";
import {Dropdown} from "../../../../../../components/dropdown/dropdown";
import {PaginationList, Topic} from "../../../../../../core/domain";
import {Spin} from "antd";

export interface AddCategoryBody {
    topicId?: string;
    title?: string,
    englishTitle?: string;
    description?: string;
}

export const AddCategory = () => {
    const [topics, setTopics] = useState<PaginationList<Topic>>();
    const [body, setBody] = useState<AddCategoryBody>({
        title: "",
        englishTitle: "",
        description: "",
    })
    const [loading, setLoading] = useState<boolean>(false);
    const [addButtonLoading, setAddButtonLoading] = useState<boolean>(false)

    const handleAddCategory = () => {
        const onComplete = () => {
            setBody({
                topicId: formattedTopics[0].id,
                title: "",
                englishTitle: "",
                description: ""
            })
            toast.success("دسته بندی با موفقیت اضافه شد")
        }
        addCategory(setAddButtonLoading, body, onComplete)
    }

    useEffect(() => {
        const onComplete = (firstTopic: Topic) => setBody({...body, topicId: firstTopic.id})
        getAllTopics(setLoading, setTopics, 1, 999, onComplete)
    }, []);

    const formattedTopics = topics?.items.map(topic => ({id: topic.id, title: topic.title}))

    return (
        <Page className="pmx-add-category" title="ایجاد دسته بندی جدید">
            <Card>
                <label>موضوع تاپیک دسته بندی جدید</label>
                {loading ? <Spin/> :
                    formattedTopics &&
                    <Dropdown items={formattedTopics} onChangeHandle={(e: string) => setBody({...body, topicId: e})}
                              loading={loading}
                              selectedOption={{
                                  id: body?.topicId,
                                  title: formattedTopics.find(topic => topic.id === body?.topicId)?.title,
                              }
                              }
                    />
                }
                <Input value={body?.title} placeholder={"نام دسته بندی جدید"} label={"نام دسته بندی جدید"}
                       onChange={(e) => setBody({...body, title: e.target.value})}/>
                <Input value={body?.englishTitle} placeholder={"نام انگلیسی دسته بندی جدید"}
                       label={"نام انگلیسی دسته بندی جدید"}
                       onChange={(e) => setBody({...body, englishTitle: e.target.value})}
                       dir={!body.englishTitle ? "rtl" : "ltr"}/>
                <Input value={body?.description} placeholder={"توضیحات دسته بندی جدید"} label={"توضیحات دسته بندی جدید"}
                       onChange={(e) => setBody({...body, description: e.target.value})}/>
                <Button type={buttonType.info} text={"ایجاد"} onClick={handleAddCategory}
                        disabled={loading || !body.topicId || body.topicId.length === 0 || body.title.length === 0 || body.englishTitle.length === 0} loading={addButtonLoading}/>
                <Link to={"/dashboard/content/categories"}>
                    <Button type={buttonType.primary} text={"بازگشت به صفحه ی دسته بندی ها"}/>
                </Link>
            </Card>
        </Page>
    )
}