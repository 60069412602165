import { API } from "apis";
import {
  getGeneric,
  getViaAuth,
  postViaAuth,
  responseValidator,
} from "apis/api";
import {
  MediaSurvey,
  PaginationList,
  UpdateMediaSurveyType,
} from "core/domain";
import { toast } from "react-hot-toast";

export const getAllMediaSurveys = async (
  setLoading: any,
  setData: any,
  page: number,
  itemsPerPageCount: number,
  query?: string,
) => {
  setLoading(true);
  await getViaAuth<PaginationList<MediaSurvey>>(
    API.survey.authMediaSurvey + `/${page}/${itemsPerPageCount}${query || ""}`,
  ).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      if (response.data) {
        setData(response.data);
      }
    }
  });
};
export const getMediaSurveyById = async (
  setLoading: any,
  setData: any,
  id: string,
  setSurveyState?: any,
) => {
  setLoading(true);
  await getViaAuth<PaginationList<MediaSurvey>>(
    API.survey.getById + `/${id}`,
  ).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      if (response.data) {
        setData(response.data);
        setSurveyState && setSurveyState(response.data.state);
      }
    }
  });
};

export const getMediaSurveysStates = async (setLoading: any, setData: any) => {
  setLoading(true);
  await getGeneric(API.survey.mediaStates).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      setData([{ id: -1, title: "همه وضعیت ها" }, ...response.data]);
    }
  });
};

export const getMediaSurveysTypes = async (setLoading: any, setData: any) => {
  setLoading(true);
  await getGeneric(API.survey.mediaTypes).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      setData([{ id: -1, title: "همه نوع ها" }, ...response.data]);
    }
  });
};

export const acceptMediaSurvey = async (
  setLoading: any,
  data: any,
  getData: any,
) => {
  setLoading(true);
  await postViaAuth<UpdateMediaSurveyType>(API.survey.accept, data).then(
    (response) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        toast.success("مدارک تایید شد.");
        getData();
      }
    },
  );
};
export const rejectMediaSurvey = async (
  setLoading: any,
  data: any,
  getData: any,
) => {
  setLoading(true);
  await postViaAuth<UpdateMediaSurveyType>(API.survey.reject, data).then(
    (response) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        toast.success("مدارک رد شد.");
        getData();
      }
    },
  );
};
