import './users.scss';
import { FC, useEffect, useState } from 'react';
import { Card } from "../../../../../../components/card/card";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import moment from "jalali-moment";
import { AuthenticatedUsersCountChartProps } from "../../../../../../core/domain/overview/authenticated-users-count";
import { RegisteredUsersCountChartProps } from "../../../../../../core/domain/overview/registered-users-count";
import { UploadedDocumentsChartProps } from "../../../../../../core/domain/overview/uploaded-documents";

Exporting(Highcharts);
ExportData(Highcharts);

interface Props {
    authenticatedUsersData: AuthenticatedUsersCountChartProps;
    registeredUsersData: RegisteredUsersCountChartProps;
    uploadedDocumentsData: UploadedDocumentsChartProps;
    chartLoading: boolean;
}

interface FormattedData {
    dateTime: string;
    value: number;
}

interface ChartData {
    dateTime: string;
    value: number;
}

const combineDates = (authData: ChartData[], regData: ChartData[], docData: ChartData[]) => {
    const allDates: Set<string> = new Set();

    [...authData, ...regData, ...docData].forEach(item => {
        const date = moment(item.dateTime).locale("fa").local().format("jYYYY/MM/DD");
        allDates.add(date);
    });

    return Array.from(allDates).sort((a, b) => moment(a, "jYYYY/MM/DD").diff(moment(b, "jYYYY/MM/DD")));
};

const getDataForDate = (data: ChartData[], date: string) => {
    const found = data.find(item => moment(item.dateTime).locale("fa").local().format("jYYYY/MM/DD") === date);
    return found ? found.value : null;
};

export const UsersChart: FC<Props> = ({ authenticatedUsersData, registeredUsersData, uploadedDocumentsData, chartLoading }) => {
    const [options, setOptions] = useState<Highcharts.Options>({});

    useEffect(() => {
        if (authenticatedUsersData && registeredUsersData && uploadedDocumentsData) {
            const authData = authenticatedUsersData.authenticatedUsersData;
            const regData = registeredUsersData.registeredUserCount;
            const docData = uploadedDocumentsData.chartDatas;

            const dates = combineDates(authData, regData, docData);

            const authValues = dates.map(date => getDataForDate(authData, date));
            const regValues = dates.map(date => getDataForDate(regData, date));
            const docValues = dates.map(date => getDataForDate(docData, date));

            setOptions({
                chart: {
                    style: {
                        fontFamily: "YekanBakh",
                        fontSize: '16px'
                    }
                },
                lang: {
                    thousandsSep: ',',
                    downloadPNG: 'PNG دانلود',
                    downloadJPEG: 'JPEG دانلود',
                    downloadPDF: 'PDF دانلود',
                    downloadSVG: 'SVG دانلود',
                    downloadCSV: 'CSV دانلود',
                    downloadXLS: 'XLS دانلود'
                },
                tooltip: {
                    style: {
                        fontSize: "16px"
                    },
                    formatter: function () {
                        return '<b>' + this.x + '</b><br>' +
                            '<span style="color:' + this.series.color + '"><span>' + this.series.name + '</span>: ' + '<b>' + Highcharts.numberFormat(this.y, 0, '.', ',') + '</b>' + '</span>';
                    }
                },
                title: {
                    text: 'کاربران'
                },
                xAxis: {
                    categories: dates
                },
                yAxis: {
                    title: {
                        text: 'مقدار'
                    },
                    labels: {
                        formatter: function () {
                            const value = this.value as number;
                            if (value >= 1_000_000) {
                                return (value / 1_000_000) + ' میلیون';
                            } else if (value >= 1_000) {
                                return (value / 1_000) + ' هزار';
                            } else {
                                return value.toString();
                            }
                        },
                        style: {
                            fontFamily: 'YekanBakh',
                            direction: 'rtl',
                        },
                    }
                },
                series: [
                    {
                        name: 'کاربران احراز هویت شده',
                        data: authValues,
                        type: 'spline',
                        color: '#4CAF50',
                        connectNulls: true,
                    },
                    {
                        name: 'کاربران ثبت نام شده',
                        data: regValues,
                        type: 'spline',
                        color: '#FF9800',
                        connectNulls: true,

                    },
                    {
                        name: 'مدارک آپلود شده',
                        data: docValues,
                        type: 'spline',
                        color: '#F44336',
                        connectNulls: true,

                    }
                ],
                exporting: {
                    enabled: true,
                    buttons: {
                        contextButton: {
                            menuItems: [
                                'downloadPNG',
                                'downloadJPEG',
                                'downloadPDF',
                                'downloadSVG',
                                'downloadCSV',
                                'downloadXLS'
                            ],
                        }
                    }
                },
                credits: {
                    enabled: false
                }
            });
        }
    }, [authenticatedUsersData, registeredUsersData, uploadedDocumentsData]);

    return (
        <Card title='کاربران' blur={chartLoading} className={"pmx-users-chart"}>
            {authenticatedUsersData && registeredUsersData && uploadedDocumentsData && (
                <HighchartsReact highcharts={Highcharts} options={options} />
            )}
        </Card>
    );
};
