import * as React from "react";
import ReactModal from "react-modal";
import "./modal.scss";

interface Props {
  isOpen: boolean;
  children: React.ReactNode;
  className?: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Modal: React.FC<Props> = (props) => {
  return (
    <>
      <ReactModal
        onAfterClose={() => props.setIsOpen(false)}
        onRequestClose={() => props.setIsOpen(false)}
        isOpen={props.isOpen}
        className={`pmx-modal ${props.className}`}
        shouldCloseOnEsc
      >
        <div className='modal-card'>{props.children}</div>
      </ReactModal>
    </>
  );
};
