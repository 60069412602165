import { API } from "apis";
import { Dispatch, SetStateAction } from 'react';
import { __API } from "apis/api-routes";
import { getViaAuth, responseValidator } from "apis/api";

export const getVoucherInfo = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    setData: Dispatch<SetStateAction<any>>,
    voucherCode: string,
    ) => {
        setLoading(true);
        await getViaAuth(API.orders.trackVoucher + `/${voucherCode}`).then(
            (response: any) => {
                setLoading(false);
                if (responseValidator(response.status)) {
                    setData(response.data);
                } else if (response.status === 404) {
                    setData(undefined);
                }
            }
        );
}