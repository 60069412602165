import * as React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { SignIn } from "./pages/sign-in/sign-in";
import { LoginWithPassword } from "./pages/sign-in/login-with-password/login-with-passowrd";
import { Dashboard } from "./pages/dashboard/dashboard";
import { Users } from "./pages/dashboard/users/users";
import { isLogin } from "apis/api";
import { Orders } from "./pages/dashboard/orders/orders";
import { EditUser } from "./pages/dashboard/users/edit/edit";
import { ReferralInfos } from "./pages/dashboard/referral-infos/referral-infos";
import { ReferralWithdrawals } from "./pages/dashboard/referral-withdrals/referral-withdrawals";
import { ReferralActions } from "./pages/dashboard/referral-actions/referral-actions";
import { Roles } from "./pages/dashboard/roles/roles";
import { MediaSurveys } from "./pages/dashboard/media-surveys/media-surveys";
import { SurveyDetails } from "./pages/dashboard/media-surveys/edit/edit-survey";
import { CreateRole } from "./pages/dashboard/roles/create/create";
import { EditRole } from "./pages/dashboard/roles/edit/edit";
import { Code } from "./pages/sign-in/code/code";
import { BankAccounts } from "./pages/dashboard/bank-accounts/bank-accounts";
import { CreateBankAccount } from "./pages/dashboard/bank-accounts/create/create-bank-account";
import { SearchOrderByTrackingCode } from "./pages/dashboard/order-by-tracking-code/order-by-tracking-code";
import { SearchUserByPhoneNumber } from "./pages/dashboard/user-by-phone-number/user-by-phone-number";
import { SearchGatewayTransactionByTrackingCode } from "./pages/dashboard/gateway-transactions-by-tracking-code/gateway-transactions-by-tracking-code";
import { GatewayTransactions } from "./pages/dashboard/gateway-transactions/gateway-transactions";
import { TrackVoucher } from "./pages/dashboard/track-voucher/track-voucher";
import { AuditLog } from "./pages/dashboard/audit-log/audit-log";
import { AuditLogById } from "./pages/dashboard/audit-log/get-by-id/audit-log-get-by-id";
import { Overview } from "./pages/dashboard/overview/overview";
import { BuysWaitingForAcceptReceipt } from "./pages/dashboard/orders/waiting-for-accept-receipt/buy/buy";
import { SellsWaitingForAcceptReceipt } from "./pages/dashboard/orders/waiting-for-accept-receipt/sell/sell";
import { TradesWaitingForAcceptReceipt } from "./pages/dashboard/orders/waiting-for-accept-receipt/trade/trade";
import { ManageMarkets } from "./pages/dashboard/content-management/manage-markets/manage-markets";
import { Categories } from "./pages/dashboard/content-management/categories/categories";
import { Contents } from "./pages/dashboard/content-management/contents/contents";
import { AddTopic } from "./pages/dashboard/content-management/topics/add/add-topic";
import { EditMarket } from "./pages/dashboard/content-management/manage-markets/edit/edit-market";
import { AddCategory } from "./pages/dashboard/content-management/categories/add/add-category";
import { EditCategory } from "./pages/dashboard/content-management/categories/edit/edit-category";
import { AddContent } from "./pages/dashboard/content-management/contents/add/add-content";
import { EditContent } from "./pages/dashboard/content-management/contents/edit/edit-content";
import { UsersSavedWallets } from "./pages/dashboard/users-saved-wallets/users-saved-wallets";
import { AddWallet } from "./pages/dashboard/users-saved-wallets/add/add";
import { UsersSavedIBans } from "./pages/dashboard/users-saved-ibans/users-saved-ibans";
import { AddIBan } from "./pages/dashboard/users-saved-ibans/add/add";
import { Topics } from "./pages/dashboard/content-management/topics/topics";
import { EditTopic } from "./pages/dashboard/content-management/topics/edit/edit-topic";

export const PmxDashboard: React.FC = () => {
  const [value, setValue] = React.useState<string>("");
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isLogin() && !window.location.pathname.includes("signin"))
      navigate("/signin");

    // const path = location.pathname;
    // var item = SidebarItems.filter(
    //   (q) => q.link.toLowerCase() === path.toLowerCase()
    // );
    // if (item.length === 0)
    //   item = SidebarItems.filter((q) =>
    //     q.secondaryLinks?.filter((w) => w.startsWith(path))
    //   );

    // dispatch(setMenuItem(item[0].id));
  }, []);

  return (
    <Routes>
      <Route path="/signin">
        <Route path="" element={<SignIn children={<LoginWithPassword />} />} />
        <Route path="code" element={<SignIn children={<Code />} />} />
      </Route>
      <Route path="dashboard">
        <Route path="users">
          <Route path="" element={<Dashboard children={<Users />} />} />
          <Route
            path="edit/:id"
            element={<Dashboard children={<EditUser />} />}
          />
        </Route>
        <Route path="orders" element={<Dashboard children={<Orders />} />} />
        <Route
          path="orders/buys-waiting-for-admin"
          element={<Dashboard children={<BuysWaitingForAcceptReceipt />} />}
        />
        <Route
          path="orders/sells-waiting-for-admin"
          element={<Dashboard children={<SellsWaitingForAcceptReceipt />} />}
        />
        <Route
          path="orders/trades-waiting-for-admin"
          element={<Dashboard children={<TradesWaitingForAcceptReceipt />} />}
        />
        <Route
          path="track-voucher"
          element={<Dashboard children={<TrackVoucher />} />}
        />
        <Route
          path="gateway-transactions"
          element={<Dashboard children={<GatewayTransactions />} />}
        />
        <Route
          path="search-gateway-transaction-by-tracking-code"
          element={
            <Dashboard children={<SearchGatewayTransactionByTrackingCode />} />
          }
        />
        <Route
          path="search-order-by-tracking-code"
          element={<Dashboard children={<SearchOrderByTrackingCode />} />}
        />
        <Route
          path="get-user-by-phone-number"
          element={<Dashboard children={<SearchUserByPhoneNumber />} />}
        />
        <Route
          path="referral-infos"
          element={<Dashboard children={<ReferralInfos />} />}
        />
        <Route path="bank-accounts">
          <Route path="" element={<Dashboard children={<BankAccounts />} />} />
          <Route
            path="create"
            element={<Dashboard children={<CreateBankAccount />} />}
          />
        </Route>
        <Route
          path="referral-withdrawals"
          element={<Dashboard children={<ReferralWithdrawals />} />}
        />
        <Route
          path="referral-actions"
          element={<Dashboard children={<ReferralActions />} />}
        />
        <Route path="roles">
          <Route path="" element={<Dashboard children={<Roles />} />} />
          <Route
            path="create"
            element={<Dashboard children={<CreateRole />} />}
          />
          <Route
            path="edit/:id"
            element={<Dashboard children={<EditRole />} />}
          />
        </Route>
        <Route path="content">
          <Route path="topics">
            <Route path="" element={<Dashboard children={<Topics />} />} />
            <Route path="add" element={<Dashboard children={<AddTopic />} />} />
            <Route
              path="edit/:id"
              element={<Dashboard children={<EditTopic />} />}
            />
          </Route>
          <Route path="manage-markets">
            <Route
              path=""
              element={<Dashboard children={<ManageMarkets />} />}
            />
            <Route
              path="edit/:id"
              element={<Dashboard children={<EditMarket />} />}
            />
          </Route>
          <Route path="categories">
            <Route path="" element={<Dashboard children={<Categories />} />} />
            <Route
              path="add"
              element={<Dashboard children={<AddCategory />} />}
            />
            <Route
              path="edit/:id"
              element={<Dashboard children={<EditCategory />} />}
            />
          </Route>
          <Route path="contents">
            <Route path="" element={<Dashboard children={<Contents />} />} />
            <Route
              path="add"
              element={<Dashboard children={<AddContent />} />}
            />
            <Route
              path="edit/:id"
              element={<Dashboard children={<EditContent />} />}
            />
          </Route>
        </Route>
        <Route path="media-surveys">
          <Route path="" element={<Dashboard children={<MediaSurveys />} />} />
          <Route
            path="details/:id"
            element={<Dashboard children={<SurveyDetails />} />}
          />
        </Route>
        <Route path="audit-log">
          <Route path="" element={<Dashboard children={<AuditLog />} />} />
          <Route
            path="details/:id"
            element={<Dashboard children={<AuditLogById />} />}
          />
        </Route>
        <Route
          path={"overview"}
          element={<Dashboard children={<Overview />} />}
        />
        <Route
          path={"users-saved-wallets"}
          element={<Dashboard children={<UsersSavedWallets />} />}
        />
        <Route
          path={"users-saved-wallets/add"}
          element={<Dashboard children={<AddWallet />} />}
        />
        <Route
          path={"users-saved-ibans"}
          element={<Dashboard children={<UsersSavedIBans />} />}
        />
        <Route
          path={"users-saved-ibans/add"}
          element={<Dashboard children={<AddIBan />} />}
        />
      </Route>
    </Routes>
  );
};
