import * as React from "react";
import {useEffect} from "react";
import {Input, inputType} from "components/input/input";
import {Button, buttonType} from "components/button/button";
import "./login-with-password.scss";
import {IsNumericText, ToEn} from "core/utils/decimalExtentions";
import {phoneRegex} from "core/utils/regexs";
import {getViaAuth, postGeneric, responseValidator} from "apis/api";
import {API} from "apis";
import {useNavigate} from "react-router-dom";
import moment from "moment";

interface Props {
}

export interface LoginResult {
    access_token: string;
    expires_in: number;
    isAuthorized: boolean;
    isTwoFactorEnabled: boolean;
    refresh_token: string;
    roles: string[];
    token_type: string;
}

export const LoginWithPassword: React.FC<Props> = () => {
    const [password, setPassword] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(false);
    const [phoneNumber, setPhoneNumber] = React.useState<string>("");

    const navigate = useNavigate();

    const phone = localStorage.getItem("phone") || "";

    useEffect(() => localStorage.clear(), []);

    function Login() {
        setLoading(true);
        postGeneric<LoginResult>(API.users.confirmCode, {
            phoneNumber: phoneNumber,
            password: password,
        })
            .then(async (res) => {
                setLoading(false);
                if (responseValidator(res.status)) {
                    if (res?.data?.isTwoFactorEnabled == true) {
                        localStorage.setItem("phone", phoneNumber);
                        navigate("/signin/code");
                    } else {
                        res.data?.access_token &&
                        localStorage.setItem("accessToken", res.data?.access_token);
                        res.data?.refresh_token &&
                        localStorage.setItem("refresh", res.data?.refresh_token);
                        localStorage.setItem("date", moment.utc().toString());
                        res.data?.expires_in &&
                        localStorage.setItem(
                            "expires_in",
                            res.data?.expires_in.toString()
                        );
                        localStorage.setItem(
                            "isAuthorized",
                            res.data?.isAuthorized.toString()
                        );
                        localStorage.setItem(
                            "level",
                            res.data?.isTwoFactorEnabled.toString()
                        );
                        res.data?.roles &&
                        localStorage.setItem("roles", res.data?.roles.join(","));
                        localStorage.setItem("token_type ", res.data?.token_type);
                        await getViaAuth(API.roles.getMineMenuItems).then((res: any) => {
                            if (responseValidator(res.status)) {
                                res.data && localStorage.setItem('menuItems', JSON.stringify(res?.data))
                            }
                        })
                        navigate("/dashboard/users");
                    }
                }
            })
            .catch((err) => {
                navigate("/signin");
            });
    }

    useEffect(() => {
    }, []);
    return (
        <div className='login-with-password'>
            <div className='title'>
                <h3>ورود یا ثبت نام</h3>
            </div>
            <p>برای ورود، اطلاعات زیر را وارد کنید.</p>

            <Input
                placeholder='شماره موبایل'
                value={phone || phoneNumber}
                onChange={(e) =>
                    IsNumericText(ToEn(e.target.value)) &&
                    setPhoneNumber(ToEn(e.target.value))
                }
                htmlType={"text"}
                maxLength={11}
                onPressEnter={Login}
            />
            <Input
                placeholder='رمز عبور'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                htmlType={"password"}
                type={inputType.text}
                onPressEnter={Login}
            />

            <Button
                disabled={ToEn(phoneNumber).search(phoneRegex) !== 0 || !password}
                type={buttonType.info}
                text='ورود'
                loading={loading}
                onClick={() => Login()}
            />
        </div>
    );
};
