import * as React from "react";
import "./gateway-transactions-by-tracking-code.scss";
import {Page} from "components/page/page";
import {Card} from "components/card/card";
import {Table} from "components/table/table";
import {GatewayTransaction, Order, PaginationList} from "core/domain";
import {useNavigate} from "react-router-dom";
import {getGatewayTransactionByTrackingCode} from "core/repositories/gateway-transaction";
import {Button, buttonType} from "components/button/button";
import {Input} from "components/input/input";
import moment from "jalali-moment";
import {digitSperator} from "../../../../core/utils/decimalExtentions";

interface FilterValues {
    search: string;
}

const empty_filters = {
    search: "",
};

export const SearchGatewayTransactionByTrackingCode: React.FC = () => {
    const [detailModalIsOpen, setDetailModalIsOpen] =
        React.useState<boolean>(false);

    const [selectedItem, setSelcetedItem] = React.useState<Order>();
    const [confirmButtonLoading, setConfirmButtonLoading] =
        React.useState<boolean>(false);
    const [query, setQuery] = React.useState<string>("");
    const [editModalIsOpen, setEditModalIsOpen] = React.useState<boolean>(false);
    const [gatewayTransactions, setGatewayTransactions] =
        React.useState<PaginationList<Order>>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
    const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
    const [payoutModalIsOpen, setPayoutModalIsOpen] =
        React.useState<boolean>(false);
    const [filterValues, setFilterValues] =
        React.useState<FilterValues>(empty_filters);
    const [pageSize, setPageSize] = React.useState<number>(10);

    const navigate = useNavigate();

    const filterHandle = () => {
        var _query = "";

        if (filterValues.search) _query += `${filterValues.search}`;

        setQuery(_query);
        getGatewayTransactionByTrackingCode(
            _query !== "" ? setButtonLoading : setLoading,
            setGatewayTransactions,
            _query
        );
    };


    const columns: any[] = [
        {
            name: "کد پیگیری",
            id: "trackId",
            selector: (row: GatewayTransaction) => row?.trackId,
            cell: (record: GatewayTransaction) => <>{record?.trackId}</>,
            width: "15%",
        },
        {
            name: "شماره کارت",
            id: "cardNumber",
            selector: (row: GatewayTransaction) => row?.cardNumber,
            width: "15%",
        },
        {
            name: "مقدار",
            id: "amount",
            selector: (row: GatewayTransaction) => digitSperator(row?.amount),
            width: "10%",
        },
        {
            name: "کد تراکنش",
            id: "orderId",
            selector: (row: GatewayTransaction) => row?.orderId,
            width: "15%",
        },
        {
            name: "توضیحات",
            id: "description",
            selector: (row: GatewayTransaction) => row?.description,
            width: "30%",
        },
        {
            name: "تاریخ پرداخت",
            id: "paidAt",
            width: "15%",
            selector: (row: GatewayTransaction) => row.paidAt,
            cell: (record: GatewayTransaction) => (
                <span>
          {moment.utc(record?.paidAt).locale("fa").local().format("HH:mm")}{" "}
                    {moment.utc(record?.paidAt).locale("fa").local().format("YYYY/MM/DD")}{" "}
        </span>
            ),
        },
    ];
    return (
        <Page
            className='pmx-zibal-by-tracking-code'
            title='جستجوی تراکنش درگاه با کد پیگیری'
        >
            <Card title='نمایش تراکنش' blur={loading}>
                <div className='filter-row'>
                    <div className='fields'>
                        <Input
                            value={filterValues.search}
                            className='search'
                            placeholder='کد پیگیری تراکنش را وارد کنید'
                            name='search'
                            onPressEnter={filterHandle}
                            onChange={(e) =>
                                setFilterValues({...filterValues, search: e.target.value})
                            }
                        />
                    </div>
                    <div className='buttons'>
                        <Button
                            loading={buttonLoading}
                            disabled={buttonLoading}
                            text='جستجو'
                            type={buttonType.success}
                            onClick={() => filterHandle()}
                        />
                    </div>
                </div>
                {
                    <>
                        {gatewayTransactions?.items &&
                            gatewayTransactions?.items.length > 0 && (
                                <Table
                                    setPageSize={setPageSize}
                                    blur={loading}
                                    setloader={setLoading}
                                    setdata={setGatewayTransactions}
                                    className='gateway-table'
                                    data={gatewayTransactions.items}
                                    column={columns}
                                    pagination={gatewayTransactions.pagination}
                                    filters={query}
                                />
                            )}
                    </>
                }
            </Card>
        </Page>
    );
};
