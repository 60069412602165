import "./edit-market.scss";
import {Page} from "../../../../../../components/page/page";
import {useState} from "react";
import {Segmented} from "antd";
import {SegmentedLabeledOption} from "antd/es/segmented";
import {EditMarketContent} from "./content/content";
import {EditMarketComments} from "./comments/comments";
import {EditMarketFaqs} from "./faq/faq";

const stages: SegmentedLabeledOption[] = [
    {
        value: "content",
        label: "محتوای اصلی ارز",
    },
    {
        value: "faq",
        label: "پرسش و پاسخ",
    },
    {
        value: "comments",
        label: "نظرات",
    },
];

export const EditMarket = () => {
    const [stage, setStage] = useState<SegmentedLabeledOption>(stages[0]);

    return (
        <Page className={"pmx-edit-market-content"} title={"ویرایش محتوای ارز"}>
            <Segmented
                onChange={(e) => setStage(stages.filter((s) => s.value === e)[0])}
                selected
                options={stages}
            />

            {stage.value === "content" && <EditMarketContent/>}
            {stage.value === "comments" && <EditMarketComments/>}
            {stage.value === "faq" && <EditMarketFaqs/>}
        </Page>
    );
};
