import * as React from "react";
import "./edit-survey.scss";
import { Col, Image, Row } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import {
  acceptMediaSurvey,
  getMediaSurveyById,
  rejectMediaSurvey,
} from "core/repositories/media-survey";
import { API } from "apis";
import { Button, buttonType } from "components/button/button";
import { Card } from "components/card/card";
import { MediaSurveyDetail } from "core/domain/media-survey/media-survey";
import moment from "jalali-moment";
import { Enum } from "core/domain";
import { getViaAuth, responseValidator } from "apis/api";
import { Page } from "components/page/page";
import TextArea from "antd/lib/input/TextArea";

const { useState, useEffect } = React;

export const SurveyDetails: React.FC = () => {
  // States
  const [approveIsLoading, setApproveIsLoading] = useState<boolean>(false);
  const [rejectIsLoading, setRejectIsLoading] = useState<boolean>(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [mediaStateTypes, setMediaStateTypes] = useState<Enum[]>();
  const [surveyData, setSurveyData] = useState<MediaSurveyDetail>();
  const [surveyStateNewData, setSurveyStateNewData] = useState<Enum>();
  const [completeIsLoading, setCompleteIsLoading] = useState(false);
  const [noAnswereIsLoading, setNoAnswerIsLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const surveyId =
    location.pathname.split("/")[location.pathname.split("/").length - 1];

  async function getMediaSurveysStates() {
    setIsPageLoading(true);
    await getViaAuth(API.survey.mediaStates).then((response: any) => {
      setIsPageLoading(false);
      if (responseValidator(response.status)) {
        setMediaStateTypes(response.data);
      }
    });
  }

  async function fetchData() {
    await getMediaSurveyById(
      setIsPageLoading,
      setSurveyData,
      surveyId,
      setSurveyStateNewData,
    );
    getMediaSurveysStates();
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page
      title="جزئیات مدارک کاربر"
      loading={isPageLoading}
      className="pmx-dashboard-surveys-edit"
    >
      <Card title="جزئیات  مدارک کاربر">
        {surveyData && (
          <>
            <div>
              <div className="right">
                <Row>
                  <span>نام کاربر</span>
                  <p>{surveyData.fullName}</p>
                </Row>
                <Row>
                  <span>شماره موبایل</span>
                  <p>{surveyData.phoneNumber}</p>
                </Row>
                <Row>
                  <span>کد ملی</span>
                  <p>{surveyData.nationalCode}</p>
                </Row>
                <Row>
                  <span>مقدار آخرین سفارش خرید</span>
                  <p>{surveyData.lastBuyOrderAmount}</p>
                </Row>
                <Row>
                  <span>وضعیت مدرک:</span>
                  <p
                    className={`${
                      surveyData.state.id === 1
                        ? "warning"
                        : surveyData.state.id === 2
                        ? "success"
                        : "error"
                    }`}
                  >
                    {surveyData.state.title}
                  </p>
                </Row>
                <Row>
                  <span>تاریخ آخرین بررسی:</span>
                  {surveyData.lastModifiedDate && (
                    <p>
                      {moment(surveyData.lastModifiedDate)
                        .locale("fa")
                        .local()
                        .format("jYYYY/MM/DD")}
                      {`-`}
                      {moment(surveyData.lastModifiedDate)
                        .local()
                        .format("HH:mm")}

                      {surveyData.modifire && " توسط "}
                      {surveyData.modifire}
                    </p>
                  )}
                </Row>
                <Row>
                  <span>تاریخ ارسال:</span>
                  {surveyData.createdOn && (
                    <p>
                      {moment(surveyData.createdOn)
                        .locale("fa")
                        .local()
                        .format("HH:mm")}{" "}
                      {` `}{" "}
                      {moment(surveyData.createdOn)
                        .locale("fa")
                        .local()
                        .format("jYYYY/MM/DD")}
                    </p>
                  )}
                </Row>
                <Row>
                  <Col>
                    <span>تصاویر مدارک</span>
                    {surveyData.files.fileByteContent.map((e) => (
                      <Image src={`data:image/jpeg;base64,${e}`} />
                    ))}
                  </Col>
                </Row>
                <Row>
                  <div className="textarea">
                    <label htmlFor="attachedMessage">توضیحات</label>

                    <TextArea
                      name="attachedMessage"
                      value={surveyData?.attachedMessage}
                      onChange={(e) =>
                        setSurveyData({
                          ...surveyData,
                          attachedMessage: e.target.value,
                        })
                      }
                    />
                    <div className="texts">
                      <Button
                        onClick={(e) =>
                          setSurveyData({
                            ...surveyData,
                            attachedMessage: "سن شما مطابق قوانین ما نمیباشد.",
                          })
                        }
                        type={buttonType.white}
                        text={"سن غیر مجاز"}
                      />

                      <Button
                        onClick={(e) =>
                          setSurveyData({
                            ...surveyData,
                            attachedMessage:
                              "مدارک شما با راهنما سایت مطابقت ندارد.",
                          })
                        }
                        type={buttonType.white}
                        text={"عدم تطابق"}
                      />

                      <Button
                        onClick={(e) =>
                          setSurveyData({
                            ...surveyData,
                            attachedMessage: " استفاده از مدارک قدیمی",
                          })
                        }
                        type={buttonType.white}
                        text={" استفاده از مدارک قدیمی"}
                      />

                      <Button
                        onClick={(e) =>
                          setSurveyData({
                            ...surveyData,
                            attachedMessage:
                              " مدارک شما کیفیت کافی جهت بررسی را ندارد.",
                          })
                        }
                        type={buttonType.white}
                        text={"کیفیت پائین تصویر"}
                      />
                      <Button
                        onClick={(e) =>
                          setSurveyData({
                            ...surveyData,
                            attachedMessage: "سن شما مطابق قوانین ما نمیباشد.",
                          })
                        }
                        type={buttonType.white}
                        text={" سن غیر مجاز"}
                      />
                    </div>
                  </div>
                </Row>
              </div>
            </div>
            <div className="buttons">
              <Button
                type={buttonType.info}
                text="تایید"
                loading={approveIsLoading}
                onClick={() =>
                  acceptMediaSurvey(
                    setApproveIsLoading,
                    { uploadFileId: surveyId, hasFinancialSetting: true },
                    () =>
                      getMediaSurveyById(
                        setIsPageLoading,
                        setSurveyData,
                        surveyId,
                        setSurveyStateNewData,
                      ),
                  )
                }
              />
              <Button
                type={buttonType.outlined}
                text="رد"
                onClick={() =>
                  rejectMediaSurvey(
                    setRejectIsLoading,
                    {
                      uploadFileId: surveyId,
                      message: surveyData.attachedMessage,
                    },
                    () =>
                      getMediaSurveyById(
                        setIsPageLoading,
                        setSurveyData,
                        surveyId,
                        setSurveyStateNewData,
                      ),
                  )
                }
                loading={rejectIsLoading}
              />
              <Button
                type={buttonType.outlined}
                text="تکمیل"
                onClick={() =>
                  acceptMediaSurvey(
                    setCompleteIsLoading,
                    { uploadFileId: surveyId, hasFinancialSetting: false },
                    () =>
                      getMediaSurveyById(
                        setIsPageLoading,
                        setSurveyData,
                        surveyId,
                        setSurveyStateNewData,
                      ),
                  )
                }
                loading={completeIsLoading}
              /><Button
                type={buttonType.outlined}
                text="تایید پس از ۷۲ ساعت"
                onClick={() =>
                  acceptMediaSurvey(
                    setCompleteIsLoading,
                    { uploadFileId: surveyId, hasFinancialSetting: false , is72H : true},
                    () =>
                      getMediaSurveyById(
                        setIsPageLoading,
                        setSurveyData,
                        surveyId,
                        setSurveyStateNewData,
                      ),
                  )
                }
                loading={completeIsLoading}
              />
              <Button
                type={buttonType.outlined}
                text="عدم پاسخ"
                onClick={() =>
                  rejectMediaSurvey(
                    setNoAnswerIsLoading,
                    { uploadFileId: surveyId, isAnsweredCall: false },
                    () =>
                      getMediaSurveyById(
                        setIsPageLoading,
                        setSurveyData,
                        surveyId,
                        setSurveyStateNewData,
                      ),
                  )
                }
                loading={noAnswereIsLoading}
              />
            </div>
          </>
        )}
      </Card>
    </Page>
  );
};
