import {
  MENU,
  ORDER_DATA,
  SIDEBAR,
  USER_DATA,
  NOTIFICATIONS,
  EXPANDED_MENU,
} from "./actions";

export const initial_state = {
  menu: -1,
  expandedMenu: -1,
  userData: {
    isTwoFactorEnabled: false,
    twoFactorMethod: 0,
    accessToken: null,
    refreshToken: null,
    tokenType: null,
    expiresIn: null,
    roles: null,
    isAuthorized: false,
  },
  profileData: {
    dateOfBirth: null,
    emailAddress: null,
    fullName: null,
    landline: null,
    level: {
      description: null,
      id: null,
      title: null,
      uniqueName: {
        id: null,
        title: null,
      },
    },
    logins: [],
    nationalCode: null,
    notifications: [],
    phoneNumber: null,
    twoFactor: {
      isActive: false,
      type: {
        id: null,
        title: null,
      },
    },
  },
  orderData: {
    stage: "PAIRS",

    source: {
      symbol: "IRT",
      amount: 0,
      name: "تومان",
      iconUrl: "/coins/irt.svg",
    },
    destination: {
      symbol: "PM",
      amount: 0,
      name: "ووچر پرفکت مانی",
      iconUrl: "/coins/pm.svg",
    },
  },
  sidebar: "close",
  notifications: [],
};

function reducer(state = initial_state, action: any) {
  switch (action.type) {
    case MENU:
      return { ...state, menu: action.payload };
    case USER_DATA:
      return { ...state, userData: action.payload };
    case EXPANDED_MENU:
      return { ...state, expandedMenu: action.payload };
    case ORDER_DATA:
      return { ...state, orderData: action.payload };
    case SIDEBAR:
      return { ...state, sidebar: action.payload };
    case NOTIFICATIONS:
      return { ...state, notifications: action.payload };
    default:
      return state;
  }
}

export default reducer;
