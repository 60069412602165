import './users-saved-wallets.scss'
import {Page} from "../../../../components/page/page";
import {Input} from "../../../../components/input/input";
import {Button, buttonType} from "../../../../components/button/button";
import {Table} from "../../../../components/table/table";
import {Card} from "../../../../components/card/card";
import {useEffect, useState} from "react";
import {PaginationList} from "../../../../core/domain";
import {
    EditUserSavedWalletsBody,
    UserSavedWallets
} from "../../../../core/domain/users-saved-wallets/users-saved-wallets";
import {getAllWallets} from "../../../../core/repositories/users-saved-wallets";
import {CopyOutlined, DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import moment from "jalali-moment";
import {RemoveWalletModal} from "./remove-modal/remove-modal";
import {message} from "antd";
import {EditWalletModal} from "./edit-modal/edit-modal";
import {Link} from "react-router-dom";
import {copyToClipboard} from "../../../../core/utils/copyToClipboard";

interface FilterValues {
    search: string
}

const empty_filters: FilterValues = {
    search: ''
}

export const UsersSavedWallets = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [buttonLoading, setButtonLoading] = useState<boolean>(false)
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)
    const [editLoading, setEditLoading] = useState<boolean>(false)
    const [filterValues, setFilterValues] = useState<FilterValues>(empty_filters)
    const [query, setQuery] = useState<string>()
    const [savedWallets, setSavedWallets] = useState<PaginationList<UserSavedWallets>>()
    const [selectedWallet, setSelectedWallet] = useState<UserSavedWallets>()
    const [pageSize, setPageSize] = useState<number>(10);

    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

    const [walletById, setWalletById] = useState<EditUserSavedWalletsBody>()
    const [walletByIdLoading, setWalletByIdLoading] = useState<boolean>(false)

    const columns: any[] = [
        {
            name: "نام و نام خانوادگی",
            id: "fullName",
            selector: (row: UserSavedWallets) => row.fullName,
            width: "15%",
        },
        {
            name: "شماره موبایل",
            id: "phoneNumber",
            selector: (row: UserSavedWallets) => row.phoneNumber,
            width: "15%",
        },
        {
            name: "عنوان کیف پول",
            id: "label",
            selector: (row: UserSavedWallets) => row.label,
            width: "15%",
        },
        {
            name: "آدرس کیف پول",
            id: "walletAddress",
            selector: (row: UserSavedWallets) => row.walletAddress,
            width: "15%",
            cell: (row: UserSavedWallets) => <><CopyOutlined style={{cursor: 'pointer', transform: 'translateY(-1px)'}} onClick={() => copyToClipboard(row.walletAddress, 'آدرس کیف پول')} />&nbsp;<span className={"en"}>{row.walletAddress}</span></>
        },
        {
            name: "شبکه",
            id: "network",
            selector: (row: UserSavedWallets) => row.network,
            width: "10%",
        },
        {
            name: "تاریخ ایجاد",
            id: "createdOn",
            selector: (row: UserSavedWallets) => row.createdOn,
            width: "15%",
            cell: (row: UserSavedWallets) => moment
                .utc(row?.createdOn)
                .locale("fa")
                .local()
                .format("HH:mm - YYYY/MM/DD")
        },
        {
            name: "عملیات",
            id: "action",
            width: "10%",
            cell: (row: UserSavedWallets) => (
                <div className='actions'>
                    <DeleteOutlined
                        onClick={() => {
                            openDeleteModal(row);
                        }}
                    />
                    <EditOutlined
                        onClick={() => {
                            openEditModal(row);
                        }}
                    />
                </div>
            ),
        },
    ];

    const openDeleteModal = (item: UserSavedWallets) => {
        setSelectedWallet(item)
        setDeleteModalOpen(true);
    }

    const openEditModal = (item: UserSavedWallets) => {
        setSelectedWallet(item)
        setEditModalOpen(true)
    }

    const onCloseDeleteModal = () => {
        setDeleteModalOpen(false);
        setSelectedWallet(undefined)
        message.success("کیف پول با موفقیت حذف شد")
        filterHandle()
    }

    const onCloseEditModal = () => {
        setEditModalOpen(false);
        setSelectedWallet(undefined)
        message.success("کیف پول با موفقیت ویرایش شد")
        filterHandle()
    }

    const filterHandle = (clearFilter?: boolean) => {
        var _query = "";

        if (filterValues.search !== "")
            _query += `${_query.includes("?") ? "&" : "?"}Keyword=${
                filterValues.search
            }`;
        setQuery(_query);

        getAllWallets(
            clearFilter
                ? setDeleteLoading
                : _query !== ""
                    ? setButtonLoading
                    : setLoading,
            setSavedWallets,
            1,
            pageSize,
            clearFilter ? "" : _query,
        );
    };

    const clearFiltersHandle = () => {
        setFilterValues({...filterValues, search: ''});
        filterHandle(true);
    };

    async function fetches() {
        await filterHandle();
    }

    useEffect(() => {
        fetches()
    }, []);

    return (
        <Page
            className='pmx-users-saved-wallets'
            title='مدیریت کیف پول های ثبت شده'
        >
            <Card title='نمایش کیف پول های ثبت شده' blur={loading}>
                <RemoveWalletModal isOpen={deleteModalOpen} setIsOpen={setDeleteModalOpen} item={selectedWallet}
                                   onClose={onCloseDeleteModal}/>
                <EditWalletModal isOpen={editModalOpen} setIsOpen={setEditModalOpen} item={selectedWallet}
                                 onClose={onCloseEditModal}/>
                <div className='filter-row'>
                    <div className='fields'>
                        <Input
                            value={filterValues.search}
                            className='search'
                            placeholder='عبارت جهت جستجو را وارد کنید'
                            name='search'
                            onPressEnter={filterHandle}
                            onChange={(e) => setFilterValues({...filterValues, search: e.target.value})}
                        />
                    </div>
                    <div className='buttons'>
                        <Button
                            loading={buttonLoading}
                            disabled={buttonLoading}
                            text='اعمال فیلتر'
                            type={buttonType.success}
                            onClick={() => filterHandle()}
                        />
                        <Button
                            loading={deleteLoading}
                            disabled={deleteLoading}
                            text="حذف فیلتر"
                            type={buttonType.error}
                            onClick={clearFiltersHandle}
                        />
                        <Link to={"/dashboard/users-saved-wallets/add"}>
                        <Button type={buttonType.info} text={"افزودن"} icon={<PlusOutlined />}/>
                        </Link>
                    </div>
                </div>

                <Table
                    setPageSize={setPageSize}
                    blur={loading}
                    setloader={setLoading}
                    getdata={getAllWallets}
                    setdata={setSavedWallets}
                    className='saved-wallets-table'
                    data={savedWallets?.items}
                    pagination={savedWallets?.pagination}
                    column={columns}
                    filters={query}
                />
            </Card>
        </Page>
    )
}