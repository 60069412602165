import * as React from "react";
import {FC, useEffect, useState} from "react";
import {Editor} from "@tinymce/tinymce-react";
import {MarketContent} from "../../../../../../../core/domain";
import {editMarketContent, getMarketContentBySymbol,} from "../../../../../../../core/repositories/manage-market";
import {Link, useLocation} from "react-router-dom";
import {Card} from "../../../../../../../components/card/card";
import {Button, buttonType,} from "../../../../../../../components/button/button";
import {FileOutlined} from "@ant-design/icons";
import {ColorPicker, Divider, Image, Row, Upload} from "antd";
import {RcFile} from "antd/es/upload";
import {toast} from "react-hot-toast";
import "./content.scss";
import {SERVER_URL, TinyEditorAPIKey,} from "../../../../../../../core/constants/constants";
import TextArea from "antd/lib/input/TextArea";
import {UploadProps} from "antd/lib";

export const EditMarketContent: FC = (props) => {
    const [body, setBody] = useState<MarketContent>({
        description1: "",
        description2: "",
        content: "",
        coin3DImageUrl: "",
        coin3DImageUrlFile: "",
        coinColor: "",
        heroBackGroundImageUrl: "",
        heroBackGroundImageUrlFile: "",
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    const handleEditContent = () => {
        editMarketContent(setButtonLoading, body, marketSymbol);
    };

    useEffect(() => {
        getMarketContentBySymbol(setLoading, setBody, marketSymbol);
    }, []);

    const location = useLocation();
    const marketSymbol =
        location.pathname.split("/")[location.pathname.split("/").length - 1];

    const coin3dImagefileUploadProps: UploadProps = {
        name: "file-uploader",
        className: "file-uploader",
        onRemove: (file: any) => {
            setBody({
                ...body,
                coin3DImageUrl: body?.coin3DImageUrl?.filter(
                    (m: any) => m.type !== "file",
                ),
            });
        },
        beforeUpload: (file: RcFile) => {
            const isCorrectFormat =
                file.type === "image/png" ||
                file.type === "image/jpeg" ||
                file.type === "image/jpg";
            if (!isCorrectFormat) toast.error(`فرمت تصویر آپلود شده صحیح نمی باشد.`);
            else
                setBody({
                    ...body,
                    coin3DImageUrl: [
                        {file: file, type: "file", name: file.name, uid: file.uid},
                    ],
                });

            return false;
        },
        listType: "text",
        showUploadList: true,
        fileList: body?.coin3DImageUrlFile && [body?.coin3DImageUrlFile],
    };

    const coinBgImageFileUploadProps: UploadProps = {
        name: "file-uploader",
        className: "file-uploader",
        onRemove: (file: any) => {
            setBody({
                ...body,
                heroBackGroundImageUrl: body?.heroBackGroundImageUrl?.filter(
                    (m: any) => m.type !== "file",
                ),
            });
        },
        beforeUpload: (file: RcFile) => {
            console.log(file)
            const isCorrectFormat =
                file.type === "image/png" ||
                file.type === "image/jpeg" ||
                file.type === "image/jpg";
            if (!isCorrectFormat) toast.error(`فرمت تصویر آپلود شده صحیح نمی باشد.`);
            else
                setBody({
                    ...body,
                    heroBackGroundImageUrl: [
                        {file: file, type: "file", name: file.name, uid: file.uid},
                    ],
                });
            console.log(body)
            return false;
        },
        listType: "text",
        showUploadList: true,
        multiple: false,
        fileList: body?.heroBackGroundImageUrlFile && [body?.heroBackGroundImageUrlFile],
    };

    const handleEditorChange = (content, editor) => {
        setBody((prevBody) => ({...prevBody, content: content}));
    };

    useEffect(() => {
        getMarketContentBySymbol(setLoading, setBody, marketSymbol);
    }, []);

    return (
        <Card blur={loading} className={"content-market-stage"}>
            <Row>
                <label>توضیحات بخش ۱ :</label>

                <TextArea
                    value={body?.description1}
                    placeholder={"توضیحات"}
                    onChange={(e) => setBody({...body, description1: e.target.value})}
                />
            </Row>
            <Divider/>

            <Row>
                <label>توضیحات بخش ۲ :</label>

                <TextArea
                    value={body?.description2}
                    placeholder={"توضیحات ۲"}
                    onChange={(e) => setBody({...body, description2: e.target.value})}
                    dir={!body.description2 ? "rtl" : "ltr"}
                />
            </Row>
            <Divider/>

            <Row className={"color"}>
                <label>رنگ ارز : </label>
                <ColorPicker
                    value={body.coinColor}
                    onChange={(color) => {
                        setBody({
                            ...body,
                            coinColor: color.toHexString(),
                        });
                    }}
                    defaultValue="#1677ff"
                    showText
                    allowClear
                    size="large"
                />
            </Row>
            <Divider/>
            <Row className={"textarea row"}>
                <label> محتوا ارز :</label>
                <Editor
                    apiKey={TinyEditorAPIKey}
                    init={{
                        resize: true,
                        min_height: 400,
                        plugins:
                            "accordion anchor autolink autoresize autosave charmap code codesample directionality emoticons fullscreen help image importcss insertdatetime link lists liststyle media nonbreaking pagebreak preview quickbars save searchreplace table visualblocks visualchars wordcount",
                        toolbar:
                            "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat | preview fullscreen code",
                    }}
                    value={body?.content}
                    onEditorChange={(a, editor) => handleEditorChange(a, editor)}
                />
            </Row>

            <Divider/>
            {body.heroBackGroundImageUrlFile && (
                <Row className={"row"}>
                    <label>تصویر بکگراند ارز</label>
                    <Image src={`${SERVER_URL}${body.heroBackGroundImageUrlFile}`}/>
                </Row>
            )}
            <Row className={"row uploader-row"}>
                {body.coin3DImageUrlFile && (
                    <Upload {...coin3dImagefileUploadProps}>
                        <button
                            className="upload-button"
                            onClick={(el) => el.preventDefault()}
                        >
                            <FileOutlined/>
                            <p>آپلود تصویر سه بعدی ارز</p>
                        </button>
                    </Upload>
                )}

                <Upload {...coinBgImageFileUploadProps}>
                    <button
                        className="upload-button"
                        onClick={(el) => el.preventDefault()}
                    >
                        <FileOutlined/>
                        <p>آپلود تصویر بک گراند ارز</p>
                    </button>
                </Upload>
            </Row>
            <br/>
            <br/>
            <Button
                type={buttonType.info}
                text={"ویرایش محتوای ارز"}
                onClick={handleEditContent}
                loading={buttonLoading}
                disabled={buttonLoading}
            />
            <Link to={"/dashboard/content/manage-markets"}>
                <Button
                    type={buttonType.primary}
                    text={"بازگشت به صفحه محتواهای ارزها"}
                />
            </Link>
        </Card>
    );
};
