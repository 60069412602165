type apiParams = string | number;

export const __API = {
  test: "",
  bankAccount: {
    getAll: "BankAccount/Management/GetAll",
    create: "BankAccount/CreateByAdmin",
    delete: "BankAccount/Delete",
  },
  banks: {
    getAll: "Bank/GetAll",
  },
  financialSettings: {
    getFinancialSettingsById: "FinancialSettings/Get",
    editFinancialSettings: "FinancialSettings/Edit",
    removeFinancialSetting: "FinancialSettings/remove",
  },
  referral: {
    getAllReferralInfos: "Referral/GetAllReferallInfos",
    getAllReferralWithdrawals: "Referral/GetAllReferralWithdrawals",
    getReferralWithdrawalStates: "Enum/ReferralWidthrawalState",
    getAllReferralActions: "Referral/GetAllRefferalActions",
    confirmWithdrawalReferral: "Referral/ConfirmWithdrawalReferral",
  },
  orders: {
    getAll: "Order/GetAllOrders",
    orderTypes: "Enum/OrderType",
    orderStates: "Enum/OrderState",
    marketTypes: "Enum/MarketTypes",
    edit: "Order/edit",
    searchByTrackingCode: "Order/SearchWithTrackingCodeByAdmin",
    confirmAndPayout: "Order/ConfirmAndPayOut",
    getGateWaysTransactions: "Order/GetGateWaysTransactions",
    getGateWaysTransaction: "Order/GetGateWaysTransaction",
    trackVoucher: "Order/TrackVoucherByAdmin",
    waitingCount: "Order/WaitingForAdminOrdersCount",
    payoutPmWithTimer: "Order/PayOutPmWithTimer",
  },
  survey: {
    mediaStates: "Enum/AuthMediaState",
    mediaTypes: "Enum/AuthMediaType",
    authMediaSurvey: "AuthMediaSurvey/GetAll",
    getById: "AuthMediaSurvey/Get",
    update: "AuthMediaSurvey/UpdateState",
    accept: "User/auth/uploadSelfie/accept",
    reject: "User/auth/uploadSelfie/reject",
  },
  users: {
    login: "user/login",
    exist: "user/exist",
    token: "User/Token",
    confirmPhoneNumber: "User/ConfirmPhoneNumber",
    setPassWord: "User/SetPassWord",
    sendSmsCode: "User/Login/SendCode",
    confirmCode: "User/Login/VerifyCode",
    profile: "User/Profile/Current",
    getAll: "User/GetAll",
    getById: "User/Management/Get",
    getByPhoneNumber: "User/GetByPhoneNumber",
    update: "User/Update",
    levels: "Level/Levels",
    verify2Step: "User/Login/VerifyTwoStepSignIn",
    getAllCategories: "Enum/UserCategory",
  },
  roles: {
    getAll: "Permission/GetAllRoles",
    permissions: "Permission/GetAllPermissions",
    create: "Permission/CreateRole",
    edit: "Permission/EditRole",
    getById: "Permission/GetRoleById",
    menuItems: "Permission/GetAllMenuItems",
    getMineMenuItems: "Permission/GetMineMenuItems",
  },
  content: {
    getAllTopics: "Content/AllTopics",
    getTopicById: "Content/TopicById",
    editTopic: "Content/EditTopic",
    addTopic: "Content/AddTopic",
    getAllCategories: "Content/AllCategories",
    getCategoryById: "Content/CategoryById",
    editCategory: "Content/EditCategory",
    addCategory: "Content/AddCategory",
    getAllContent: "Content/AllContent",
    getContentById: "Content/ContentById",
    editContent: "Content/EditContent",
    addContent: "Content/AddContent",
  },
  auditLog: {
    getAll: "AuditLog/GetAll",
    getById: "AuditLog/GetById",
  },
  dashboard: {
    getCountBuyAndSell: "Dashboard/CountBuyAndSell",
    getPMCountBuyAndSell: "Dashboard/PmCountBuyAndSell",
    getCryptoCountBuyAndSell: "Dashboard/CryptoCountBuyAndSell",
    getSumBuyAndSell: "Dashboard/SumBuyAndSell",
    getPMSumBuyAndSell: "Dashboard/PmSumBuyAndSell",
    getCryptoSumBuyAndSell: "Dashboard/CryptoSumBuyAndSell",
    getUSDTWithdrawalCount: "Dashboard/UsdtWithdrawalCount",
    getSumBuyAndSellCommissions: "Dashboard/SumBuyAndSellCommissions",
    getUploadedDocumentsChart: "Dashboard/UploadedDocumentsChart",
    getRegisteredUsersCount: "Dashboard/RegisteredUsersCount",
    getAuthenticatedUsersCount: "Dashboard/AuthenticatedUsersCount",
    getBalances: "Dashboard/Balances",
    getFirstBuys: "Dashboard/FirstBuys",
  },
  market: {
    networks: "Market/Networks/Deposit",
    getAllMarketV2: "Market/GetAllV2",
    editV2: "Market/EditV2",
  },
  marketContent: {
    getMarketContentBySymbolAdmin:
      "MarketContent/GetMarketContentBySymbolAdmin",
    getMarketCommentsBySymbolAdmin:
      "MarketContent/GetMarketCommentsBySymbolAdmin",
    getMarketFaqsBySymbolAdmin: "MarketContent/GetMarketFaqsBySymbolAdmin",
    updateMarketContent: "MarketContent/UpdateMarketContent",
    updateFaqs: "MarketContent/UpdateFaqs",
    updateComments: "MarketContent/UpdateComments",
  },
  userSavedWallets: {
    getAll: "UserSavedWallets/GetAll",
    getById: "UserSavedWallets/GetById",
    add: "UserSavedWallets/AddByAdmin",
    edit: "UserSavedWallets/Edit",
    remove: "UserSavedWallets/RemoveByAdmin",
  },
  userSavedIBans: {
    getAll: "UserSavedIBans/GetAll",
    getById: "UserSavedIBans/GetById",
    add: "UserSavedIBans/AddByAdmin",
    edit: "UserSavedIBans/Edit",
    remove: "UserSavedIBans/RemoveByAdmin",
  },
};
