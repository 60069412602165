import * as React from "react";
import "./referral-actions.scss";
import { Page } from "components/page/page";
import { Card } from "components/card/card";
import { Table } from "components/table/table";
import { ReferralAction } from "core/domain";
import { Badge } from "components";
import { CopyOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { getAllReferralActions } from "core/repositories/referral";
import { Button, buttonType } from "components/button/button";
import { Input } from "components/input/input";
// import { ClientSidePagination } from "components/pagination/pagination";
import { PaginationList } from "../../../../core/domain/commons/common";
import { getPageNum } from "core/utils/paggingHelper";
import { digitSperator, faNumToEnNum } from "core/utils/decimalExtentions";
import { copyToClipboard } from "core/utils/copyToClipboard";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { ReactComponent as CalendarIcon } from "assets/icons/dash-line-icons/calendar.svg";
import moment from "jalali-moment";

interface FilterValues {
  search: string;
  startDate: string;
  endDate: string;
}

const empty_filters = {
  search: "",
  endDate: "",
  startDate: "",
};

export const ReferralActions: React.FC = () => {
  var itemsPerPageCount = 20;

  const referralActionLevels = [
    {
      id: 0,
      title: "همه",
    },
    {
      id: 1,
      title: "پایه",
    },
    {
      id: 2,
      title: "نقره ای",
    },
    {
      id: 3,
      title: "طلایی",
    },
  ];

  const defaultLevelSelected = {
    id: 0,
    title: "سطح کاربر را انتخاب کنید",
  };

  const [referralActions, setReferralActions] =
    React.useState<PaginationList<ReferralAction>>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
  const [deleteButtonLoading, setDeleteButtonLoading] =
    React.useState<boolean>(false);
  const [filterValues, setFilterValues] =
    React.useState<FilterValues>(empty_filters);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [query, setQuery] = React.useState<string>("");

  const navigate = useNavigate();
  const startDateRef = React.useRef(null);
  const endDateRef = React.useRef(null);

  const clearFiltersHandle = () => {
    setFilterValues(empty_filters);
    filterHandle(true);
  };

  const filterHandle = (clearFilter?: boolean) => {
    var _query = "";

    if (filterValues.search)
      _query += `${_query.includes("?") ? "&" : "?"}keywords=${
        filterValues.search
      }`;
    if (filterValues.startDate !== "")
      _query += `${_query.includes("?") ? "&" : "?"}startDate=${moment(
        faNumToEnNum(filterValues?.startDate),
        "jYYYY/jMM/jDD",
        "fa",
      )
        .locale("en")
        .format("YYYY/MM/DD")}`;
    if (filterValues.endDate !== "")
      _query += `${_query.includes("?") ? "&" : "?"}endDate=${moment(
        faNumToEnNum(filterValues?.endDate),
        "jYYYY/jMM/jDD",
        "fa",
      )
        .locale("en")
        .format("YYYY/MM/DD")}`;

    setQuery(_query);
    getAllReferralActions(
      clearFilter
        ? setDeleteButtonLoading
        : _query !== ""
        ? setButtonLoading
        : setLoading,
      setReferralActions,
      getPageNum(),
      pageSize,
      clearFilter ? "" : _query,
    );
  };

  React.useEffect(() => {
    getAllReferralActions(
      setLoading,
      setReferralActions,
      getPageNum(),
      pageSize,
    );
  }, []);

  const columns: any[] = [
    {
      name: "نام کامل معرف",
      id: "referralFullName",
      selector: (row: ReferralAction) => row.referralFullName,
      width: "15%",
    },
    {
      name: "نام کامل کاربر دعوتی",
      id: "invitedUserFullName",
      selector: (row: ReferralAction) => row.invitedUserFullName,
      width: "15%",
    },
    {
      name: "مقدار سفارش",
      id: "orderAmount",
      selector: (row: ReferralAction) => digitSperator(row.orderAmount),
      width: "15%",
    },
    {
      name: "مقدار جایزه",
      id: "actionAmount",
      selector: (row: ReferralAction) => digitSperator(row.actionAmount),
      width: "15%",
    },
    {
      name: "کد پیگیری سفارش",
      id: "orderTrackingCode",
      selector: (row: ReferralAction) => row.orderTrackingCode,
      width: "15%",
      cell: (row: ReferralAction) => (
        <span className="en copiable">
          {row?.orderTrackingCode}
          <CopyOutlined
            onClick={() =>
              copyToClipboard(row.orderTrackingCode, "کد پیگیری سفارش")
            }
          />
        </span>
      ),
    },

    {
      name: "نوع",
      id: "type",
      width: "15%",
      selector: (row: ReferralAction) => row.type,
      cell: (row: ReferralAction) => (
        <Badge text={row.type.title} type={"info"} />
      ),
    },
    {
      name: "تاریخ ایجاد",
      id: "createdOn",
      selector: (row: ReferralAction) => row?.createdOn || "",
      sortable: true,
      cell: (row: ReferralAction) => (
        <span>
          {moment
            .utc(row?.createdOn)
            .locale("fa")
            .local()
            .format("YYYY/MM/DD")}{" "}
          {moment
            .utc(row?.createdOn)
            .locale("fa")
            .local()
            .format("HH:mm")}
        </span>
      ),
    },
  ];
  return (
    <Page className="pmx-referral-actions" title="همکاران ما">
      <Card title="لیست فعالیت های همکاران" blur={loading}>
        <div className="filter-row">
          <div className="fields">
            <Input
              value={filterValues.search}
              className="search"
              placeholder="جستجو کنید ..."
              onPressEnter={filterHandle}
              name="search"
              onChange={(e) =>
                setFilterValues({ ...filterValues, search: e.target.value })
              }
            />
            {/* <Dropdown
              defaultSelected={referralActionLevels[0]}
              items={referralActionLevels}
              onChangeHandle={(value) =>
                setSelectedLevel(
                  referralActionLevels.filter((item) => item.id === value)[0]
                )
              }
            /> */}
            <DatePicker
              className="date-picker"
              value={filterValues.startDate}
              calendar={persian}
              ref={startDateRef}
              locale={persian_fa}
              render={
                <div>
                  <Input
                    value={filterValues.startDate}
                    icon={<CalendarIcon />}
                    placeholder="از تاریخ"
                  />
                </div>
              }
              onChange={(e) => {
                setFilterValues({ ...filterValues, startDate: e.format() });
              }}
            />
            <DatePicker
              className="date-picker"
              value={filterValues.endDate}
              calendar={persian}
              ref={endDateRef}
              locale={persian_fa}
              render={
                <div>
                  <Input
                    value={filterValues.endDate}
                    icon={<CalendarIcon />}
                    placeholder="تا تاریخ"
                  />
                </div>
              }
              onChange={(e) => {
                setFilterValues({ ...filterValues, endDate: e.format() });
              }}
            />
          </div>
          <div className="buttons">
            <Button
              loading={buttonLoading}
              disabled={buttonLoading}
              text="اعمال فیلتر"
              type={buttonType.success}
              onClick={() => filterHandle()}
            />
            <Button
              loading={deleteButtonLoading}
              disabled={deleteButtonLoading}
              text="حذف فیلتر"
              type={buttonType.error}
              onClick={clearFiltersHandle}
            />
          </div>
        </div>
        {referralActions?.items && referralActions?.items.length > 0 && (
          <>
            <Table
              setPageSize={setPageSize}
              blur={loading}
              setloader={setLoading}
              getdata={getAllReferralActions}
              setdata={setReferralActions}
              pagination={referralActions.pagination}
              className="referral-actions-table"
              data={referralActions.items}
              column={columns}
              filters={query}
            />
          </>
        )}
      </Card>
    </Page>
  );
};
