import * as React from "react";
import "./media-surveys.scss";
import { Page } from "components/page/page";
import { Card } from "components/card/card";
import { Table } from "components/table/table";
import { MediaSurvey } from "core/domain";
import { Badge } from "components";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  getAllMediaSurveys,
  getMediaSurveysStates,
  getMediaSurveysTypes,
} from "core/repositories/media-survey";
import { Button, buttonType } from "components/button/button";
import { Input } from "components/input/input";
// import { ClientSidePagination } from "components/pagination/pagination";
import { Enum, PaginationList } from "../../../../core/domain/commons/common";
import moment from "jalali-moment";
import { getPageNum } from "core/utils/paggingHelper";
import { Dropdown } from "components/dropdown/dropdown";

interface FilterValues {
  search: string;
  state: Enum;
  type: Enum;
}

export const MediaSurveys: React.FC = () => {
  var itemsPerPageCount = 20;

  const empty_filters = {
    search: "",
    state: {
      id: -1,
      title: "همه وضعیت ها",
    },
    type: {
      id: -1,
      title: "همه نوع ها",
    },
  };

  const [mediaSurveys, setMediaSurveys] =
    React.useState<PaginationList<MediaSurvey>>();
  const [surveyStates, setSurveyStates] = React.useState<Enum[]>([
    empty_filters.state,
  ]);
  const [surveyTypes, setSurveyTypes] = React.useState<Enum[]>([
    empty_filters.type,
  ]);
  const [query, setQuery] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
  const [deleteButtonLoading, setDeleteButtonLoading] =
    React.useState<boolean>(false);
  const [filterValues, setFilterValues] =
    React.useState<FilterValues>(empty_filters);
  const [pageSize, setPageSize] = React.useState<number>(10);

  const navigate = useNavigate();

  const clearFiltersHandle = () => {
    setFilterValues(empty_filters);
    filterHandle(true);
  };

  const filterHandle = (clearFilter: boolean = undefined) => {
    var _query = "";

    if (filterValues.search)
      _query += `${_query.includes("?") ? "&" : "?"}keywords=${
        filterValues.search
      }`;
    if (filterValues.state?.id !== -1)
      _query += `${_query.includes("?") ? "&" : "?"}state=${
        filterValues.state?.id
      }`;
    if (filterValues.type?.id !== -1)
      _query += `${_query.includes("?") ? "&" : "?"}type=${
        filterValues.type?.id
      }`;

    setQuery(_query);
    getAllMediaSurveys(
      clearFilter
        ? setDeleteButtonLoading
        : _query !== ""
        ? setButtonLoading
        : setLoading,
      setMediaSurveys,
      getPageNum(),
      pageSize,
      clearFilter ? "" : _query
    );
  };

  React.useEffect(() => {
    fetches();
  }, []);

  async function fetches() {
    await getMediaSurveysStates(setLoading, setSurveyStates);
    filterHandle();
    getMediaSurveysTypes(setLoading, setSurveyTypes);
  }

  const columns: any[] = [
    {
      name: "نام کامل",
      id: "fullName",
      selector: (row: MediaSurvey) => row.fullName,
      width: "15%",
    },
    {
      name: "شماره موبایل",
      id: "phoneNumber",
      selector: (row: MediaSurvey) => row.phoneNumber,
      width: "20%",
    },
    {
      name: "وضعیت",
      id: "state",
      width: "15%",
      selector: (row: MediaSurvey) => row.state,
      cell: (row: MediaSurvey) => (
        <Badge
          text={row.state.title}
          type={
            row.state.id === 3
              ? "error"
              : row.state.id === 1
              ? "waiting"
              : "success"
          }
        />
      ),
    },
    {
      name: "نوع",
      id: "type",
      width: "15%",
      selector: (row: MediaSurvey) => row.type,
      cell: (row: MediaSurvey) => <Badge text={row.type.title} type={"info"} />,
    },

    {
      name: "تاریخ ایجاد",
      id: "createdOn",
      selector: (row: MediaSurvey) => row?.createdOn || "",
      sortable: true,
      width: "15%",
      cell: (row: MediaSurvey) => (
        <span>
          {moment.utc(row?.createdOn).locale("fa").local().format("HH:mm")}{" "}
          {moment.utc(row?.createdOn).locale("fa").local().format("YYYY/MM/DD")}{" "}
        </span>
      ),
    },

    {
      name: "عملیات",
      id: "action",
      cell: (row: MediaSurvey) => (
        <div className='actions'>
          <InfoCircleOutlined onClick={() => navigate(`details/${row.id}`)} />
        </div>
      ),
    },
  ];
  return (
    <Page className='pmx-media-surveys' title='مدارک کاربران'>
      <Card title='لیست  مدارک کاربران' blur={loading}>
        <div className='filter-row'>
          <div className='fields'>
            <Input
              value={filterValues.search}
              className='search'
              placeholder='جستجو کنید ...'
              name='search'
              onPressEnter={filterHandle}
              onChange={(e) =>
                setFilterValues({ ...filterValues, search: e.target.value })
              }
            />
            {surveyStates.length > 0 && (
              <Dropdown
                items={surveyStates}
                onChangeHandle={(value) =>
                  setFilterValues({
                    ...filterValues,
                    state: surveyStates.find((l) => l.id === value),
                  })
                }
                selectedOption={filterValues.state}
              />
            )}
            {surveyTypes.length > 0 && (
              <Dropdown
                items={surveyTypes}
                onChangeHandle={(value) =>
                  setFilterValues({
                    ...filterValues,
                    type: surveyTypes.find((l) => l.id === value),
                  })
                }
                selectedOption={filterValues.type}
              />
            )}
          </div>
          <div className='buttons'>
            <Button
              loading={buttonLoading}
              disabled={buttonLoading}
              text='اعمال فیلتر'
              type={buttonType.success}
              onClick={() => filterHandle()}
            />
            <Button
              loading={deleteButtonLoading}
              disabled={deleteButtonLoading}
              text='حذف فیلتر'
              type={buttonType.error}
              onClick={clearFiltersHandle}
            />
          </div>
        </div>
        {mediaSurveys?.items && mediaSurveys?.items.length > 0 && (
          <>
            <Table
              setPageSize={setPageSize}
              blur={loading}
              setloader={setLoading}
              getdata={getAllMediaSurveys}
              setdata={setMediaSurveys}
              pagination={mediaSurveys.pagination}
              className='media-surveys-table'
              data={mediaSurveys.items}
              column={columns}
              filters={query}
            />
          </>
        )}
      </Card>
    </Page>
  );
};
