import * as React from "react";
import Transfer, {TransferDirection} from "antd/lib/transfer";

import {Permission, Role} from "core/domain";

import "./create.scss";
import {getViaAuth, postViaAuth, responseValidator} from "apis/api";
import {message, Spin} from "antd";
import TextArea from "antd/lib/input/TextArea";
import {useLocation, useNavigate} from "react-router-dom";
import {API} from "apis";
import {Button, buttonType} from "components/button/button";
import {Input} from "components/input/input";

const {useState, useRef, useEffect} = React;

type GoogleAuth = {
    code?: string;
    error?: string;
};

type MenuItem = {
    id: string;
    name: string;
    path?: string;
    parent?: string;
};

const permissionsData: any[] = Array.from({length: 10}).map((_, i) => ({
    id: i.toString(),
    displayText: `دسترسی ${i + 1}`,
}));

const initialTargetKeys = permissionsData
    .filter((item) => Number(item.id) > 10)
    .map((item) => item.id.toString());

export const CreateRole: React.FC = () => {
    // States
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [isCardLoading, setIsCardLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [roleData, setRoleData] = useState<Role>();
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

    const [targetKeys, setTargetKeys] = useState<string[]>([]);
    const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);

    const [role, setRole] = useState<any>();

    const [permissions, setPermissions] = useState<Permission[]>([]);
    const [googleAuthData, setGoogleAthData] = useState<GoogleAuth>();

    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
    const [selectedMenuKeys, setSelectedMenuKeys] = useState<string[]>([]);
    const [targetMenuKeys, setTargetMenuKeys] = useState<string[]>([]);

    const location = useLocation();
    const navigate = useNavigate();

    const createRoleHandle = async () => {
        setLoading(true);
        await postViaAuth(API.roles.create, {
            name: roleData?.title,
            title: roleData?.title,
            claims: targetKeys.map((key) => parseInt(key)),
            description: roleData?.description,
            menuItems: targetMenuKeys, // An array of GUID strings
        }).then((response: any) => {
            setLoading(false);
            if (responseValidator(response.status)) {
                setModalIsOpen(false);
                message.success("نقش مورد نظر با موفقیت ایجاد شد.");
                navigate("/dashboard/roles/");
            }
        });
    };

    async function getAllMenuItems() {
        const arr = [];
        setIsCardLoading(true);
        await getViaAuth(API.roles.menuItems).then((response: any) => {
            setIsCardLoading(false);
            if (responseValidator(response.status) && response.data) {
                response.data.forEach((mi) => arr.push({
                        key: mi.id,
                        name: mi.name,
                    })
                )
                setMenuItems(arr);
            }
        });
    }

    const getAllPermissions = async () => {
        const arr = [];
        setIsCardLoading(true);
        await getViaAuth(API.roles.permissions).then((response: any) => {
            setIsCardLoading(false);
            if (responseValidator(response.status) && response.data) {
                response.data.forEach((p) =>
                    arr.push({
                        key: p.id,
                        displayText: p.displayText,
                        title: p.displayText,
                    })
                );
                setPermissions(arr);
            }
        });
    };


    const onChange = (
        nextTargetKeys: string[],
        direction: TransferDirection,
        moveKeys: string[]
    ) => {
        setTargetKeys(nextTargetKeys);
    };

    const onSelectChange = (
        sourceSelectedKeys: string[],
        targetSelectedKeys: string[]
    ) => {
        setSelectedPermissions([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    const onMenuItemsChange = (nextTargetKeys: string[]) => {
        setTargetMenuKeys(nextTargetKeys);
    };

    const onMenuItemsSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
        setSelectedMenuKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    const onScroll = (
        direction: TransferDirection,
        e: React.SyntheticEvent<HTMLUListElement>
    ) => {
    };

    const fetch = async () => {
        await getAllMenuItems();
        await getAllPermissions();
    }

    useEffect(() => {
        fetch();
    }, []);

    return (
        <>
            <Spin spinning={isCardLoading} tip='درحال بارگذاری'>
                <div className='pmx-dashboard-roles-create'>
                    <h3>ایجاد نقش</h3>
                    <form>
                        <Input
                            label='عنوان'
                            value={roleData?.title}
                            onChange={(e) =>
                                setRoleData({...roleData, title: e.target.value})
                            }
                            name='title'
                            placeholder='عنوان نقش را وارد کنید'
                        />

                        <TextArea
                            name='description'
                            value={roleData?.description}
                            onChange={(e) =>
                                setRoleData({...roleData, description: e.target.value})
                            }
                            placeholder='توضیحات نقش را وارد کنید'
                        />
                        {permissions.length > 0 && (
                            <Transfer
                                className='roles-transfer'
                                dataSource={permissions}
                                titles={["لیست دسترسی ها", "دسترسی های نقش"]}
                                targetKeys={targetKeys}
                                selectedKeys={selectedPermissions}
                                onChange={onChange}
                                onSelectChange={onSelectChange}
                                onScroll={onScroll}
                                render={(item) => item.displayText}
                                locale={{
                                    itemUnit: "مورد",
                                    itemsUnit: "مورد",
                                    selectAll: "انتخاب همه",
                                    selectCurrent: "تایید موارد انتخاب شده",
                                    selectInvert: "معکوس کردن انتخاب ها",
                                }}
                            />
                        )}
                        {menuItems.length > 0 && (
                            <Transfer
                                className='menu-items-transfer'
                                dataSource={menuItems}
                                titles={["لیست آیتم های منو", "آیتم های منو نقش"]}
                                targetKeys={targetMenuKeys}
                                selectedKeys={selectedMenuKeys}
                                onChange={onMenuItemsChange}
                                onSelectChange={onMenuItemsSelectChange}
                                onScroll={onScroll}
                                render={(item) => item.name}
                                locale={{
                                    itemUnit: "مورد",
                                    itemsUnit: "مورد",
                                    selectAll: "انتخاب همه",
                                    selectCurrent: "تایید موارد انتخاب شده",
                                    selectInvert: "معکوس کردن انتخاب ها",
                                }}
                            />
                        )}
                        <div className='buttons'>
                            <Button
                                type={buttonType.info}
                                text='ایجاد'
                                onClick={() => createRoleHandle()}
                                loading={loading}
                                htmlType='button'
                                disabled={!roleData?.title}
                            />
                            <Button
                                type={buttonType.outlined}
                                text='بازگشت'
                                onClick={() => navigate("/dashboard/roles/")}
                                htmlType='button'
                            />
                        </div>
                    </form>
                </div>
            </Spin>
        </>
    );
};
