import * as React from "react";
import Transfer, { TransferDirection } from "antd/lib/transfer";

import {
  Permission,
  BankAccount,
  BankAccountCreate,
  PaginationList,
  User,
} from "core/domain";

import "./create-bank-account.scss";
import { getViaAuth, postViaAuth, responseValidator } from "apis/api";
import { message, Row, Select, Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "components/modal/modal";
import ReactCodeInput from "react-verification-code-input";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { API } from "apis";
import { Button, buttonType } from "components/button/button";
import { Input, inputType } from "components/input/input";
import { formatCardNumber } from "core/utils/bankDetecter";
import { DefaultOptionType } from "antd/es/select";

const { useState, useRef, useEffect } = React;

type GoogleAuth = {
  code?: string;
  error?: string;
};

const permissionsData: any[] = Array.from({ length: 10 }).map((_, i) => ({
  id: i.toString(),
  displayText: `دسترسی ${i + 1}`,
}));

export const CreateBankAccount: React.FC = () => {
  // States
  const [isUsersDropdownLoading, setIsUsersDropdownLoading] =
    React.useState<boolean>(false);
  const [users, setUsers] = React.useState<DefaultOptionType[]>();
  const [banks, setBanks] = React.useState<DefaultOptionType[]>();
  const [isCardLoading, setIsCardLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [bankAccountData, setBankAccountData] = useState<BankAccountCreate>();

  const location = useLocation();
  const navigate = useNavigate();

  const getAllUsers = async (query: string) => {
    setIsUsersDropdownLoading(true);
    await getViaAuth<PaginationList<User>>(
      API.users.getAll + `/${1}/${50}${query && `?keywords=${query}`}`
    ).then((response: any) => {
      const arr = [];
      setIsUsersDropdownLoading(false);
      if (responseValidator(response.status)) {
        if (response.data.items) {
          response.data.items.forEach((e) =>
            arr.push({
              label:
                e.fullName.startsWith(" ") || !e.fullName
                  ? e.phoneNumber
                  : e.fullName,
              value: e.id,
            })
          );
          setUsers(arr);
        }
      }
    });
  };

  const getAllBanks = async () => {
    setIsCardLoading(true);
    await getViaAuth(API.banks.getAll).then((response: any) => {
      const arr = [];
      setIsCardLoading(false);
      if (responseValidator(response.status)) {
        if (response.data) {
          response.data.forEach((e) =>
            arr.push({
              label: e.title,
              value: e.id,
            })
          );
          setBanks(arr);
        }
      }
    });
  };

  const onSearchChange = (value: string) => {
    getAllUsers(value);
  };

  const createBankAccountHandle = async () => {
    setLoading(true);
    await postViaAuth(API.bankAccount.create, {
      ...bankAccountData,
      cardNumber: bankAccountData.cardNumber.replaceAll("-", ""),
    }).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        message.success("حساب بانکی مورد نظر با موفقیت ایجاد شد.");
        navigate("/dashboard/bank-accounts/");
      }
    });
  };

  const fetch = async () => {
    await getAllUsers("");
    getAllBanks();
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <Spin spinning={isCardLoading} tip='درحال بارگذاری'>
        <div className='pmx-dashboard-bank-accounts-create'>
          <h3>ایجاد حساب بانکی توسط ادمین</h3>

          <form>
            <Input
              type={inputType.cardNumber}
              maxLength={20}
              label='شماره کارت'
              value={bankAccountData?.cardNumber}
              onChange={(e) =>
                setBankAccountData({
                  ...bankAccountData,
                  cardNumber: e.target.value,
                })
              }
              name='cardNumber'
              placeholder='شماره کارت را وارد کنید'
            />

            <Input
              label='شماره شبا'
              maxLength={24}
              value={bankAccountData?.iBan}
              onChange={(e) =>
                setBankAccountData({
                  ...bankAccountData,
                  iBan: e.target.value,
                })
              }
              name='iBan'
              placeholder='شماره شبا را وارد کنید'
            />

            <Row className='center'>
              <Spin className='antspin' spinning={isUsersDropdownLoading}>
                <div className='select'>
                  <label htmlFor='message'>کاربر صاحب حساب</label>
                  <Select
                    allowClear
                    value={bankAccountData?.userId}
                    placeholder='کاربر را انتخاب کنید'
                    onChange={(value, options) => {
                      setBankAccountData({
                        ...bankAccountData,
                        userId: value,
                      });
                    }}
                    options={users}
                    showSearch
                    onSearch={(value) => onSearchChange(value)}
                    filterOption={false}
                  />
                </div>
              </Spin>
            </Row>

            <Row className='center'>
              <div className='select'>
                <label htmlFor='message'>بانک</label>
                <Select
                  allowClear
                  value={bankAccountData?.bankId}
                  placeholder='بانک را انتخاب کنید'
                  onChange={(value, options) => {
                    setBankAccountData({
                      ...bankAccountData,
                      bankId: value,
                    });
                  }}
                  options={banks}
                  filterOption={false}
                />
              </div>
            </Row>

            <div className='buttons'>
              <Button
                type={buttonType.info}
                text='ایجاد'
                onClick={() => createBankAccountHandle()}
                loading={loading}
                htmlType='button'
                disabled={
                  !bankAccountData?.cardNumber ||
                  !bankAccountData?.iBan ||
                  !bankAccountData?.userId ||
                  !bankAccountData?.bankId
                }
              />
              <Button
                type={buttonType.outlined}
                text='بازگشت'
                onClick={() => navigate(-1)}
                htmlType='button'
              />
            </div>
          </form>
        </div>
      </Spin>
    </>
  );
};
