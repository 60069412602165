// noinspection BadExpressionStatementJS

import { API } from "./";
import axios from "axios";
import moment from "moment";

import toast from "react-hot-toast";
import { API_URL } from "core/constants/constants";
import { authToken } from "core/utils/storage";
import { Response } from "core/domain/commons/common";

export const SERVER = API_URL;
export const expireRefreshToken = 86400;

export const generateHeader = async (object: any = {}) => {
  var expiredTime = parseInt(localStorage.getItem("expires_in"));

  const header: any = object;
  if (authToken.get()) {
    let lastRefresh = localStorage.getItem("date");
    if (
      lastRefresh &&
      Math.abs(moment(lastRefresh).diff(moment.utc(), "seconds")) > expiredTime
    ) {
      await axios
        .get(SERVER + `${API.users.token}/${localStorage.getItem("refresh")}`)
        .then((res) => {
          localStorage.setItem("refresh", res.data.data.refresh_token);
          localStorage.setItem("accessToken", res.data.data.access_token);

          localStorage.setItem("date", moment.utc().toString());
        })
        .catch((err) => {
          window.location.href = "/signin";
        });
    }
  }
  header.Authorization = authToken.get();
  for (const key of Object.keys(object)) {
    header[key] = object[key];
  }
  return header;
};

export function isLogin() {
  if (window.location.href.includes("verify")) return true;
  try {
    if (localStorage.getItem("date"))
      return (
        localStorage.getItem("date") &&
        Math.abs(
          moment(localStorage.getItem("date")).diff(moment.utc(), "seconds"),
        ) < expireRefreshToken
      );
    return false;
  } catch {
    return false;
  }
}

export function del(url: string, body: any) {
  let status: number;
  var header: any;
  // eslint-disable-next-line
  async () => {
    header = await generateHeader({ "Content-Type": "application/json" });
  };

  return new Promise((resolve) => {
    fetch(SERVER + url, {
      method: "DELETE",
      body: JSON.stringify(body),
      headers: header,
    })
      .then(function (response) {
        status = response.status;
        return response.json();
      })
      .then(function (data) {
        resolve({ data, status });
      })
      .catch((err) => {
        resolve({ data: null, status });
      });
  });
}

export const post = async (url: string, body: any) => {
  let status: number;
  return new Promise((resolve) => {
    fetch(SERVER + url, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: JSON.stringify(body),
    })
      .then(function (response) {
        status = response.status;
        if (response.status === 401) window.open("/signin", "_self");
        else if (response.status === 403) resolve({ value: null, status });
        return response.json();
      })
      .then(function (data) {
        handleErrors(data);
        resolve({ data, status });
      })
      .catch((err) => {
        resolve({ data: null, status });
      });
  });
};

export const form = async (url: string, body: any) => {
  let status: number;
  var header = await generateHeader();

  return new Promise((resolve) => {
    fetch(SERVER + url, {
      method: "POST",
      body: body,
      headers: header,
    })
      .then(function (response) {
        status = response.status;
        return response.json();
      })
      .then(function (data) {
        resolve({ data, status });
      })
      .catch((err) => {
        resolve({ data: null, status });
      });
  });
};

export function put(url: string, body: any) {
  let status: number;
  var header: any;
  // eslint-disable-next-line
  async () => {
    header = await generateHeader({ "Content-Type": "application/json" });
  };
  return new Promise((resolve) => {
    fetch(SERVER + url, {
      method: "PUT",
      body: JSON.stringify(body),
      headers: header,
    })
      .then(function (response) {
        status = response.status;
        if (response.status === 403 || response.status === 401)
          window.open("/signin", "_self");

        return response.json();
      })
      .then(function (data) {
        resolve({ data, status });
      })
      .catch((err) => {
        resolve({ data: null, status });
      });
  });
}

export function patch(url: string, body: any) {
  let status: number;

  var header: any;
  // eslint-disable-next-line
  async () => {
    header = await generateHeader({ "Content-Type": "application/json" });
  };

  return new Promise((resolve) => {
    fetch(SERVER + url, {
      method: "PATCH",
      body: JSON.stringify(body),
      headers: header,
    })
      .then(function (response) {
        status = response.status;
        return response.json();
      })
      .then(function (data) {
        resolve({ data, status });
      })
      .catch((err) => {
        resolve({ data: null, status });
      });
  });
}

export function get(url: string, params: any = {}) {
  var header: any;
  // eslint-disable-next-line
  async () => {
    header = await generateHeader({ "Content-Type": "application/json" });
  };

  const generatedUrl = new URL(SERVER + url);
  Object.keys(params).forEach((key) => {
    if (params[key]) {
      generatedUrl.searchParams.append(key, params[key]);
    }
  });
  let status: number;
  return new Promise((resolve) => {
    fetch(SERVER + url, {
      method: "GET",
      headers: header,
    })
      .then(function (response) {
        status = response.status;
        if (response.status === 401) window.open("/signin", "_self");
        else if (response.status === 403) resolve({ value: null, status });

        return response.json();
      })
      .then(function (data) {
        handleErrors(data);

        resolve({ data, status });
      })
      .catch((err) => {
        resolve({ data: null, status });
      });
  });
}

export async function getViaAuth<R>(
  url: string,
  params: { [k: string]: any } = {},
): Promise<Response<R | null>> {
  const generatedUrl = new URL(SERVER + url);
  Object.keys(params).forEach((key) => {
    if (params[key] !== undefined) {
      generatedUrl.searchParams.append(key, params[key]);
    }
  });

  let status: number;
  let message: string;
  const header = await generateHeader({ "Content-Type": "application/json" });

  return new Promise(async (resolve) => {
    fetch(generatedUrl.href, {
      method: "GET",
      headers: header,
    })
      .then((response: any) => {
        status = response.status;

        if (status === 401) window.open("/signin", "_self");
        else if (status === 403) {
          message = " دسترسی غیر مجاز .";
          resolve({ data: null, message, status: status });
        }
        return response.json();
      })
      .then((data: any) => {
        message = data.message;
        data.status = status;

        handleErrors(data);
        resolve(data);
      })
      .catch((err) => {
        message = " خطا در برقراری ارتباط با سرور";
        if (status === 401) window.open("/signin", "_self");
        resolve({ data: null, message, status: status });
      });
  });
}

export function getGeneric<R>(
  url: string,
  params: { [k: string]: any } = {},
): Promise<Response<R | null>> {
  const generatedUrl = new URL(SERVER + url);
  Object.keys(params).forEach((key) => {
    if (params[key] !== undefined) {
      generatedUrl.searchParams.append(key, params[key]);
    }
  });
  let status: number;
  let message: string;

  const header: { [k: string]: any } = {};
  header["Content-Type"] = "application/json";
  return new Promise((resolve) => {
    fetch(generatedUrl.href, {
      method: "GET",
      headers: header,
    })
      .then((response: any) => {
        status = response.status;
        if (response.status === 401) window.open("/signin", "_self");
        else if (response.status === 403) {
          message = "دسترسی غیر مجاز .";
          resolve({ data: null, message, status: status });
        }
        return response.json();
      })
      .then((data: any) => {
        message = data.message;
        data.status = status;
        resolve(data);
      })
      .catch((err) => {
        message = " خطا در برقراری ارتباط با سرور";
        resolve({ data: null, message, status: status });
      });
  });
}

export async function postViaAuth<R>(
  url: string,
  body: any,
  fromForm: boolean = false,
): Promise<Response<R | null>> {
  let status: number;
  let message: string;
  const header =
    fromForm === true
      ? await generateHeader({})
      : await generateHeader({ "Content-Type": "application/json" });

  return new Promise(async (resolve, reject) => {
    fetch(SERVER + url, {
      method: "POST",
      headers: header,
      body: fromForm === true ? body : JSON.stringify(body),
    })
      .then((response: any) => {
        status = response.status;
        return response.json();
      })
      .then((data: any) => {
        message = data.message;
        data.status = status;
        if (status === 401) window.location.replace("/signin/");
        else if (status === 403) {
          message = "دسترسی غیر مجاز .";
          resolve({ data: null, message, status: status });
        }

        handleErrors(data);

        resolve(data);
      })
      .catch((err) => {
        message = " خطا در برقراری ارتباط با سرور";
        resolve({ data: null, message: message, status: status });
      });
  });
}

export async function postGeneric<R>(
  url: string,
  body: any,
  fromForm: boolean = false,
): Promise<Response<R | null>> {
  let status: number;
  let message: string;

  return new Promise(async (resolve, reject) => {
    fetch(SERVER + url, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/json" }),
      body: fromForm === true ? body : JSON.stringify(body),
    })
      .then((response: any) => {
        status = response.status;
        return response.json();
      })
      .then((data: any) => {
        message = data.message;
        data.statusCode = status;
        if (status === 401) window.location.replace("/signin/");
        else if (status === 403) {
          message = "دسترسی غیر مجاز .";
          resolve({ data: null, message, status: status });
        }
        handleErrors(data);
        resolve(data);
      })
      .catch((err) => {
        console.log(err);
        message = " خطا در برقراری ارتباط با سرور";
        resolve({ data: null, message, status: status });
      });
  });
}

function handleErrors(data: any) {
  let message = data.message;
  if (!responseValidator(data.status) && !data.isSuccess) {
    toast.error(message);
  } else if (data.status === 401) window.location.replace("/signin/");
  else if (data.status === 403) toast.error("دسترسی غیر مجاز .");
  else if (data && data.status && data.status != 200) toast.error(message);
}

export function responseValidator(status: number) {
  return status >= 200 && status < 300;
}

export function hasValidationError(status: number) {
  return status == 400;
}

export function getValidationErrors(errors: any): any[] {
  let list = [];

  for (let key in errors) {
    let value = errors[key];
    list.push(value);
  }

  return list;
}
