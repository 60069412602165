import { MenuItem } from "./common";
import {
  AppstoreOutlined,
  BookFilled,
  BookOutlined,
  CameraOutlined,
  ClockCircleOutlined,
  CompressOutlined,
  ContactsOutlined,
  CreditCardOutlined,
  EditOutlined,
  FileSearchOutlined,
  HomeOutlined,
  SearchOutlined,
  TeamOutlined,
  ToolOutlined,
  TransactionOutlined,
  UnorderedListOutlined,
  UserOutlined,
  WalletOutlined,
} from "@ant-design/icons";

export const SidebarItems: MenuItem[] = [
  {
    id: 1,
    isActive: false,
    title: "پیشخوان",
    icon: <HomeOutlined />,
    link: "/dashboard/overview",
  },
  {
    id: 4,
    isActive: false,
    title: "مدیریت کاربران",
    icon: <UserOutlined />,
    link: "/dashboard/users",
  },

  {
    id: 7,
    isActive: false,
    title: "مشاهده سفارشات",
    icon: <ClockCircleOutlined />,
    link: "/dashboard/orders",
  },
  {
    id: 8,
    isActive: false,
    title: "اقدامات",
    icon: <ToolOutlined />,
    link: "",
    itemKey: "waitingForAcceptReceipt",
    childrens: [
      {
        id: 801,
        title: "خرید",
        link: "/dashboard/orders/buys-waiting-for-admin",
        itemKey: "buyWaitingForAcceptReceipt",
      },
      {
        id: 802,
        title: "فروش",
        link: "/dashboard/orders/sells-waiting-for-admin",
        itemKey: "sellWaitingForAcceptReceipt",
      },
      {
        id: 803,
        title: "ترید",
        link: "/dashboard/orders/trades-waiting-for-admin",
        itemKey: "tradeWaitingForAcceptReceipt",
      },
    ],
  },
  {
    id: 9,
    isActive: false,
    title: "مدیریت محتوا",
    icon: <EditOutlined />,
    link: "",
    childrens: [
      {
        id: 1901,
        title: "تاپیک ها",
        link: "/dashboard/content/topics",
      },
      {
        id: 1902,
        title: "دسته بندی",
        link: "/dashboard/content/categories",
      },
      {
        id: 1903,
        title: "محتوا",
        link: "/dashboard/content/contents",
      },
      {
        id: 1904,
        title: "مدیریت ارز ها",
        link: "/dashboard/content/manage-markets",
      },
    ],
  },
  {
    id: 11,
    isActive: false,
    title: "همکاران ما",
    icon: <ContactsOutlined />,
    link: "",
    secondaryLinks: ["/dashboard/referral-infos"],
    childrens: [
      {
        id: 111,
        title: "لیست همکاران",
        link: "/dashboard/referral-infos",
      },
      {
        id: 112,
        title: "لیست درخواست برداشت های رفرال",
        link: "/dashboard/referral-withdrawals",
      },
      {
        id: 113,
        title: "لیست فعالیت همکاران",
        link: "/dashboard/referral-actions",
      },
    ],
  },
  {
    id: 12,
    isActive: false,
    title: "نقش ها",
    icon: <AppstoreOutlined />,
    link: "/dashboard/roles",
  },
  {
    id: 13,
    isActive: false,
    title: "تصاویر مدرک کاربران",
    icon: <CameraOutlined />,
    link: "/dashboard/media-surveys",
  },
  {
    id: 14,
    isActive: false,
    title: "مدیریت حساب های بانکی",
    icon: <CreditCardOutlined />,
    link: "/dashboard/bank-accounts",
  },
  {
    id: 15,
    isActive: false,
    title: "تراکنش های درگاه فعال",
    icon: <TransactionOutlined />,
    link: "/dashboard/gateway-transactions",
  },
  {
    id: 16,
    isActive: false,
    title: "جستجوی تراکنش درگاه با کد پیگیری",
    icon: <SearchOutlined />,
    link: "/dashboard/search-gateway-transaction-by-tracking-code",
  },
  {
    id: 2,
    isActive: false,
    title: "استعلام ووچر",
    icon: <CompressOutlined />,
    link: "/dashboard/track-voucher",
  },
  {
    id: 17,
    isActive: false,
    title: "جستجوی سفارش با کد پیگیری",
    icon: <FileSearchOutlined />,
    link: "/dashboard/search-order-by-tracking-code",
  },
  {
    id: 18,
    isActive: false,
    title: "جستجوی کاربر با شماره موبایل",
    icon: <SearchOutlined />,
    link: "/dashboard/get-user-by-phone-number",
  },
  {
    id: 19,
    isActive: false,
    title: "مدیریت کیف پول های ثبت شده",
    icon: <WalletOutlined />,
    link: "/dashboard/users-saved-wallets",
  },
  {
    id: 20,
    isActive: false,
    title: "مدیریت شبا های ثبت شده",
    icon: <CreditCardOutlined />,
    link: "/dashboard/users-saved-ibans",
  },
];

export const MobileItems: MenuItem[] = [
  {
    id: 4,
    isActive: false,
    title: "مدیریت کاربران",
    icon: <UserOutlined />,
    link: "/dashboard/users",
  },
  {
    id: 7,
    isActive: false,
    title: "مشاهده سفارشات",
    icon: <ClockCircleOutlined />,
    link: "/dashboard/orders",
  },
  {
    id: 8,
    isActive: false,
    title: "اقدامات",
    icon: <ToolOutlined />,
    link: "",
    itemKey: "waitingForAcceptReceipt",
    childrens: [
      {
        id: 801,
        title: "خرید",
        link: "/dashboard/orders/buys-waiting-for-admin",
        itemKey: "buyWaitingForAcceptReceipt",
      },
      {
        id: 802,
        title: "فروش",
        link: "/dashboard/orders/sells-waiting-for-admin",
        itemKey: "sellWaitingForAcceptReceipt",
      },
      {
        id: 803,
        title: "ترید",
        link: "/dashboard/orders/trades-waiting-for-admin",
        itemKey: "tradeWaitingForAcceptReceipt",
      },
    ],
  },
  {
    id: 111,
    title: "لیست همکاران",
    link: "/dashboard/referral-infos",
    isActive: false,
    icon: <TeamOutlined />,
  },
  {
    id: 112,
    title: "لیست درخواست برداشت های رفرال",
    link: "/dashboard/referral-withdrawals",
    isActive: false,
    icon: <TransactionOutlined />,
  },
  {
    id: 113,
    title: "لیست فعالیت همکاران",
    link: "/dashboard/referral-actions",
    isActive: false,
    icon: <UnorderedListOutlined />,
  },
  {
    id: 12,
    isActive: false,
    title: "نقش ها",
    icon: <AppstoreOutlined />,
    link: "/dashboard/roles",
  },
  {
    id: 13,
    isActive: false,
    title: "تصاویر مدرک کاربران",
    icon: <CameraOutlined />,
    link: "/dashboard/media-surveys",
  },
  {
    id: 14,
    isActive: false,
    title: "مدیریت حساب های بانکی",
    icon: <CreditCardOutlined />,
    link: "/dashboard/bank-accounts",
  },
  {
    id: 15,
    isActive: false,
    title: "تراکنش های درگاه فعال",
    icon: <TransactionOutlined />,
    link: "/dashboard/gateway-transactions",
  },
  {
    id: 16,
    isActive: false,
    title: "جستجوی تراکنش درگاه با کد پیگیری",
    icon: <SearchOutlined />,
    link: "/dashboard/search-gateway-transaction-by-tracking-code",
  },
  {
    id: 17,
    isActive: false,
    title: "جستجوی سفارش با کد پیگیری",
    icon: <FileSearchOutlined />,
    link: "/dashboard/search-order-by-tracking-code",
  },
  {
    id: 18,
    isActive: false,
    title: "جستجوی کاربر با شماره موبایل",
    icon: <SearchOutlined />,
    link: "/dashboard/get-user-by-phone-number",
  },
  {
    id: 19,
    isActive: false,
    title: "مدیریت کیف پول های ثبت شده",
    icon: <WalletOutlined />,
    link: "/dashboard/users-saved-wallets",
  },
  {
    id: 20,
    isActive: false,
    title: "مدیریت شبا های ثبت شده",
    icon: <CreditCardOutlined />,
    link: "/dashboard/users-saved-ibans",
  },
];
