import { API } from "apis";
import { getViaAuth, postViaAuth, responseValidator } from "apis/api";
import { PaginationList, BankAccount } from "core/domain";
import toast from "react-hot-toast";

export const getAllBankAccounts = async (
  setLoading: any,
  setData: any,
  page: number,
  itemsPerPageCount: number,
  query?: string
) => {
  setLoading(true);
  await getViaAuth<PaginationList<BankAccount>>(
    API.bankAccount.getAll +
      "/" +
      page +
      "/" +
      itemsPerPageCount +
      `${query || ""}`
  ).then((response: any) => {
    setLoading(false);
    if (responseValidator(response.status)) {
      if (response.data) {
        setData(response.data);
      }
    }
  });
};

export const deleteBankAccount = async (
  setLoading: any,
  data: any,
  onCompelete: () => void
) => {
  setLoading(true);
  await postViaAuth(API.bankAccount.delete + `/${data.id}`, data).then(
    (response) => {
      setLoading(false);
      if (responseValidator(response.status)) {
        toast.success("کارت بانکی با موفقیت حذف شد");
        onCompelete();
      }
    }
  );
};
