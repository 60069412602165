import { FC, SetStateAction, useEffect, useState } from "react";
import { Input } from "../../../../../../../components/input/input";
import "./comments.scss";
import { EditMarketComment } from "../../../../../../../core/domain";
import { editMarketComments } from "../../../../../../../core/repositories/manage-market";
import { Card } from "../../../../../../../components/card/card";
import {
  Button,
  buttonType,
} from "../../../../../../../components/button/button";
import { Link, useLocation } from "react-router-dom";
import { Collapse } from "antd";
import {
  CheckCircleFilled,
  DownCircleFilled,
  DownOutlined,
  EditFilled,
  MinusCircleFilled,
  PlusCircleFilled,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import * as React from "react";
import { get, responseValidator } from "../../../../../../../apis/api";
import { API } from "../../../../../../../apis";

const initial_comment = {
  text: "",
  author: "",
  authorPosition: "",
  id: "",
};

export const EditMarketComments: FC = (props) => {
  const [newCommentValue, setNewCommentValue] =
    useState<EditMarketComment>(initial_comment);
  const [comments, setComments] = useState<EditMarketComment[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isAddingNewComment, setIsAddingNewComment] = useState(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [selectedComment, setSelectedComment] = useState<EditMarketComment>();

  const location = useLocation();
  const marketSymbol =
    location.pathname.split("/")[location.pathname.split("/").length - 1];

  const removeCommentFromListHandle = (comId: string) => {
    if (!comId) {
      console.warn("Comment ID is not provided.");
      return; // Early return if comId is not provided
    }

    const filteredComments = comments?.filter((e) => e.id.toString() !== comId);

    if (filteredComments) {
      setComments(filteredComments);
    }
  };
  const editCommentHandle = (com: EditMarketComment) => {};

  async function getMarketCommentsBySymbol() {
    setLoading(true);
    await get(
      API.marketContent.getMarketCommentsBySymbolAdmin + `/${marketSymbol}`,
    ).then((response: any) => {
      setLoading(false);
      if (responseValidator(response.status) && response.data) {
        const newcomments = [];
        response.data.data.map((e) =>
          newcomments.push({
            key: e.id,
            label: e.author,
            children: (
              <p className={"comment-item"} key={e.id}>
                <div className={"head"}></div>({e.authorPosition}):
                <br />
                {e.text}
                <br />
              </p>
            ),
          }),
        );

        setComments(response.data.data);
      }
    });
  }

  const handleEditComments = () => {
    editMarketComments(setButtonLoading, comments, marketSymbol);
  };

  const onSaveCommentClickHandle = () => {
    const updatedComments = comments.map((comment) =>
      comment.id === newCommentValue.id ? newCommentValue : comment,
    );

    // Update the comments state with the new list
    setComments(updatedComments);
    setIsEditing(false);
    setIsAddingNewComment(false);
  };

  const onAddNewCommentClickHandle = () => {
    let newComments = comments;
    !newCommentValue.text ||
      !newCommentValue.authorPosition ||
      !newCommentValue.author ||
      newComments.push({ ...newCommentValue, id: "" });
    setComments(newComments);
    setIsAddingNewComment(false);
  };

  useEffect(() => {
    getMarketCommentsBySymbol();
  }, []);

  const onEditClickHandle = () => {
    setIsAddingNewComment(false);
    selectedComment && setIsEditing(true);
    setNewCommentValue(selectedComment);
  };

  const handleEditorChange = (content, editor) => {
    setComments((prevBody) => ({ ...prevBody, text: content }));
  };

  return (
    <Card className={"comments-stage"} blur={loading}>
      <div className={"comments"}>
        <div className={"head"}>
          <span className={"add-comment-icon"}>
            {isAddingNewComment ? (
              <div className={"add-head"}>
                <span>ذخیره کردن نظر</span>
                <CheckCircleFilled
                  onClick={() => {
                    onAddNewCommentClickHandle();
                  }}
                />
              </div>
            ) : (
              <div className={"add-head"}>
                <span>اضافه کردن نظر</span>
                <PlusCircleFilled
                  onClick={() => {
                    setNewCommentValue(initial_comment);
                    setIsAddingNewComment(true);
                  }}
                />
              </div>
            )}
          </span>
        </div>
        {isAddingNewComment && (
          <div className={"edit-comment-input"}>
            <h4>اضافه کردن نظر</h4>
            <Input
              value={newCommentValue?.author}
              placeholder={"کاربر"}
              label={"کاربر"}
              onChange={(e) =>
                setNewCommentValue({
                  ...newCommentValue,
                  author: e.target.value,
                })
              }
            />
            <Input
              value={newCommentValue?.authorPosition}
              placeholder={"نقش کاربر"}
              label={"نقش کاربر"}
              onChange={(e) =>
                setNewCommentValue({
                  ...newCommentValue,
                  authorPosition: e.target.value,
                })
              }
            />

            <div className={"textarea"}>
              <label>متن کامنت</label>
              <TextArea
                name="attachedMessage"
                value={newCommentValue?.text}
                onChange={(e) =>
                  setNewCommentValue({
                    ...newCommentValue,
                    text: e.target.value,
                  })
                }
              />
            </div>
          </div>
        )}
        {comments?.map((comment) => (
          <div className={"comment-item"} key={comment.id}>
            <div className={"head"}>
              <span className={"author"}>
                {comment.author} {` `} ({comment.authorPosition})
              </span>
              <span className={"remove-comment"}>
                <MinusCircleFilled
                  onClick={() => removeCommentFromListHandle(comment.id)}
                />
              </span>
              <span className={"edit-comment"}>
                {selectedComment?.id === comment?.id ? (
                  isEditing ? (
                    <CheckCircleFilled
                      onClick={() =>
                        selectedComment &&
                        newCommentValue &&
                        onSaveCommentClickHandle()
                      }
                      className={"check-icon"}
                    />
                  ) : (
                    <EditFilled onClick={() => onEditClickHandle()} />
                  )
                ) : (
                  <></>
                )}
              </span>
              <span
                className={`toggle-comment ${
                  selectedComment && selectedComment?.id === comment.id
                    ? "active"
                    : ""
                }`}
              >
                <DownCircleFilled
                  onClick={(event) => {
                    if (!selectedComment) {
                      setSelectedComment(comment);
                    } else {
                      setSelectedComment(undefined);
                      setIsEditing(false);
                      setIsAddingNewComment(false);
                    }
                  }}
                />
              </span>
            </div>
            {selectedComment && selectedComment?.id === comment.id && (
              <div className={"comment-text"}>{comment.text}</div>
            )}
            {isEditing && selectedComment?.id === comment.id && (
              <div className={"edit-comment-input"}>
                <h4>ویرایش نظر</h4>
                <Input
                  value={newCommentValue?.author}
                  placeholder={"کاربر"}
                  label={"کاربر"}
                  onChange={(e) =>
                    setNewCommentValue({
                      ...newCommentValue,
                      author: e.target.value,
                    })
                  }
                />
                <Input
                  value={newCommentValue?.authorPosition}
                  placeholder={"نقش کاربر"}
                  label={"نقش کاربر"}
                  onChange={(e) =>
                    setNewCommentValue({
                      ...newCommentValue,
                      authorPosition: e.target.value,
                    })
                  }
                />

                <div className={"textarea"}>
                  <label>متن کامنت</label>
                  <TextArea
                    name="attachedMessage"
                    value={newCommentValue?.text}
                    onChange={(e) =>
                      setNewCommentValue({
                        ...newCommentValue,
                        text: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      <Button
        type={buttonType.info}
        text={"ویرایش نظرات"}
        onClick={handleEditComments}
        loading={buttonLoading}
        disabled={buttonLoading}
      />
      <Link to={"/dashboard/content/manage-markets"}>
        <Button
          type={buttonType.primary}
          text={"بازگشت به صفحه محتواهای ارزها"}
        />
      </Link>
    </Card>
  );
};
