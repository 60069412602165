import * as React from "react";

import "./mobile-navigationbar.scss";
import { Drawer } from "antd";
import { RootState } from "core/redux/store";
import { connect, ConnectedProps } from "react-redux";
import { setSidebar } from "core/redux/actions";
import { SidebarItems } from "core/domain/commons/menu";
import { MobileItems } from "core/domain/commons/menu";
import MobileNavigationBarItem from "./navbar-item/navbar-item";
import { ReactComponent as Logout } from "assets/icons/dash-line-icons/logout.svg";
import { useDispatch } from "react-redux";
import { Modal } from "components/modal/modal";
import { Button, buttonType } from "components/button/button";
import { useNavigate } from "react-router-dom";
import {WaitingCounts} from "../../core/domain/dashboard/dashboard";

const mapState = (state: RootState) => ({
  state: state,
});

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
  waitingCounts?: WaitingCounts;
}

const MobileNavigationbar: React.FC<Props> = ({ state, waitingCounts }) => {
  const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const onLogoutClick = () => {
    setModalIsOpen(true);
  };

  const totalOrderCounts = waitingCounts ? waitingCounts.buyWaitingForAcceptReceipt + waitingCounts.sellWaitingForAcceptReceipt + waitingCounts.tradeWaitingForAcceptReceipt : 0

  return (
    <Drawer
      className='navigation-drawer'
      open={state.sidebar === "close" ? false : true}
      onClose={() => dispatch(setSidebar("close"))}
      placement='bottom'
      headerStyle={{ display: "none" }}
    >
      <Modal
        setIsOpen={setModalIsOpen}
        isOpen={modalIsOpen}
        className='logout-modal'
      >
        <p>آیا از خارج شدن از حسابتان اطمینان دارید؟</p>

        <div className='buttons'>
          <Button
            type={buttonType.info}
            text='بله'
            onClick={() => window.open("/signin", "_self")}
          />
          <Button
            type={buttonType.error}
            text='خیر'
            onClick={() => setModalIsOpen(false)}
          />
        </div>
      </Modal>
      <>
        <span className='holder'></span>
        {MobileItems.filter(
          (item) =>
            item.title != "خرید و فروش" && item.title != "تاریخچه سفارشات"
        ).map((item, index) => (
          <MobileNavigationBarItem
            id={item.id}
            icon={item.icon}
            title={item.title}
            link={item.link}
            isActive={item.isActive}
            childrens={item.childrens}
            waitingCounts={waitingCounts}
            itemKey={item.itemKey}
          />
        ))}
        <a
          onClick={() => {
            onLogoutClick();
          }}
          className={`mobile-navigationbar-item logout red`}
        >
          <div className='content'>
            <Logout />
            <p className='title'>خروج</p>
          </div>
        </a>
      </>
    </Drawer>
  );
};

export default connector(MobileNavigationbar);
