import * as React from "react";
import "./edit-modal.scss";
import { Modal } from "../../../../../components/modal/modal";
import { Spin } from "antd";
import { changeOrderStatus, getAllOrders } from "core/repositories/order";
import { Dropdown } from "components/dropdown/dropdown";
import { Button, buttonType } from "components/button/button";
import { Enum } from "core/domain/commons/common";
import { Input } from "components/input/input";
import { Order } from "core/domain";

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  item: Order;
  onClose: () => void;
  orderStates: Enum[];
}

export const OrderEditModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  item,
  onClose,
  orderStates,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [iBan, setIBan] = React.useState<string>("");
  const [selectedState, setSelectedState] = React.useState<Enum>(
    orderStates.filter((q) => q.title === item.state.title)[0]
  );

  const onCloseModal = () => {
    onClose();
    setIBan("");
  };

  React.useEffect(() => {
    isOpen === false && onCloseModal();
  }, [isOpen]);

  return (
    <Modal className='order-edit-modal' isOpen={isOpen} setIsOpen={setIsOpen}>
      <Spin spinning={loading}>
        <h3>تغییر سفارش</h3>
        <div className='row'>
          <label>تغییر وضعیت سفارش</label>
          {orderStates?.length > 0 && (
            <Dropdown
              items={orderStates}
              onChangeHandle={(value) =>
                setSelectedState(
                  orderStates.filter((item) => item.id === value)[0]
                )
              }
              selectedOption={selectedState}
            />
          )}
        </div>
        {item.type === "فروش" && (
          <div className='row'>
            <Input
              placeholder='شماره شبا را وارد کنید'
              value={iBan}
              onChange={(e) => setIBan(e.target.value)}
              maxLength={24}
            />
          </div>
        )}
        <div className='buttons'>
          <Button
            loading={buttonLoading}
            type={buttonType.info}
            text='اعمال تغییرات'
            onClick={async (e) => {
              if (item.trackingCode) {
                await changeOrderStatus(
                  setButtonLoading,
                  {
                    trackingCode: item.trackingCode,
                    state: selectedState.id,
                    iban:
                      item.type === "فروش"
                        ? iBan !== ""
                          ? iBan.replaceAll("IR", "")
                          : null
                        : null,
                  },
                  (e) => {
                    setIsOpen(false);
                    setIBan("");
                  }
                );
              }
            }}
          />
          <Button
            type={buttonType.outlined}
            text='بازگشت'
            onClick={() => setIsOpen(false)}
          />
        </div>
      </Spin>
    </Modal>
  );
};
