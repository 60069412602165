import * as React from "react";
import {useRef, useState} from "react";
import {PmxComponent} from "core/components/PmxComponenet";
import useOnBlur from "core/utils/useOnBlur";
import "./dropdown.scss";
import {DownOutlined, LoadingOutlined,} from "@ant-design/icons";

interface DropdownItem {
    id: number | string;
    title: string;
    icon?: React.ReactElement;
}

interface Props extends PmxComponent {
    disabled?: boolean;
    onChangeHandle?: (value: number | string) => void;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    items: DropdownItem[];
    defaultSelected?: DropdownItem;
    dropDownButtonIcon?: React.ReactNode;
    selectedOption?: DropdownItem;
    loading?: boolean
}

export const Dropdown: React.FC<Props> = (props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [selectedItemm, setSelectedItem] = useState<DropdownItem>(
        props?.defaultSelected ? props?.defaultSelected : props?.items[0]
    );

    const dropdowRef = useRef<any>();

    useOnBlur(dropdowRef, () => {
        if (isOpen) setIsOpen(false);
    });

    return (
        <div
            ref={dropdowRef}

            className={`pmx-dropdown ${
                props.className && props.className !== "" ? props.className : ""
            }`}
        >
            <div onClick={() => setIsOpen(!isOpen)} className='dropdown-selector'>
                <a role='button'>
                    {props.selectedOption
                        ? props.selectedOption?.icon
                        : selectedItemm?.icon}
                    <span>
            {" "}
                        {props.selectedOption
                            ? props.selectedOption?.title
                            : selectedItemm?.title}
          </span>
                </a>
                {props.loading ? <LoadingOutlined/> :
                    props.dropDownButtonIcon ? props.dropDownButtonIcon : <DownOutlined/>
                }
            </div>
            <div
                className={`dropdown-items ${isOpen ? "" : "d-none"}`}
            >
                <ul>
                    {props.items.length > 0 &&
                        props.items
                            .filter((i) =>
                                props.selectedOption
                                    ? i.id !== props.selectedOption.id
                                    : i.id !== selectedItemm.id
                            )
                            .map((item, idx, arr) => {
                                return (
                                    <>
                                        <li
                                            className={`dropdown-item ${
                                                props.selectedOption
                                                    ? item.id === props.selectedOption.id
                                                        ? "selected"
                                                        : ""
                                                    : item.id === selectedItemm?.id
                                                        ? "selected"
                                                        : ""
                                            }`}
                                            onClick={() => {
                                                setSelectedItem(item);
                                                props.onChangeHandle && props.onChangeHandle(item.id);
                                                setIsOpen(false);
                                            }}
                                        >
                                            <div>
                                                {" "}
                                                {item.icon} <span>{item.title}</span>
                                            </div>
                                        </li>
                                    </>
                                );
                            })}
                </ul>
            </div>
        </div>
    );
};
