import * as React from "react";
import "./navbar-item.scss";
import {RootState} from "core/redux/store";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {setMenuItem, setSidebar} from "core/redux/actions";
import {MenuItemChild} from "../../../core/domain";
import {WaitingCounts} from "../../../core/domain/dashboard/dashboard";
import {Badge} from "../../badge/badge";

const mapState = (state: RootState) => ({
    state: state,
});

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    id: number;
    title: string;
    icon?: React.ReactNode;
    link?: string;
    className?: string;
    isActive?: boolean;
    onClickHandle?: () => void;
    childrens?: MenuItemChild[]
    waitingCounts?: WaitingCounts
    itemKey?: string;
}

const MobileNavigationbarItem: React.FC<Props> = (props) => {
    const dispatch = useDispatch();

    const [isChildrenVisible, setIsChildrenVisible] = React.useState(false);

    function onSelect() {
        dispatch(setMenuItem(props.id));
        dispatch(setSidebar("close"));
    }

    const onDropdownClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        setIsChildrenVisible(!isChildrenVisible)
    }

    var waitingCountKeys = props.waitingCounts && Object.keys(props.waitingCounts);
    var waitingCountValues = props.waitingCounts && Object.values(props.waitingCounts);


    return (
        <>
            <Link
                to={props.link ?? ""}
                onClick={(e) => {
                    !props.childrens && onSelect();
                    props.childrens && onDropdownClick(e)
                }}
                className={`mobile-navigationbar-item ${
                    props.state.menu === props.id ? "active" : ""
                } ${props.className ?? ""} ${props.isActive ? "filled" : ""}`}
            >
                <div className='content'>
                    {props.icon}
                    <p className='title'>
                        {props.title}
                        {props.itemKey && props.waitingCounts && (
                            <Badge
                                type='error'
                                text={
                                    props.itemKey == "waitingForAcceptReceipt" ? props.waitingCounts.buyWaitingForAcceptReceipt + props.waitingCounts.sellWaitingForAcceptReceipt + props.waitingCounts.tradeWaitingForAcceptReceipt : ""
                                }
                            />
                        )}
                    </p>
                </div>
            </Link>
            {props.childrens && isChildrenVisible &&
                <div className={"mobile-navigationbar-item-children"}>
                    {props.childrens.map((item) => (
                        <Link to={item.link}>
                            {item.title}
                            {props.waitingCounts && item.itemKey && (
                                <Badge
                                    type="error"
                                    text={
                                        waitingCountValues?.filter(
                                            (i, index) =>
                                                waitingCountKeys &&
                                                waitingCountKeys.filter(
                                                    (a, idx) => a === item.itemKey && index === idx
                                                )[0]
                                        )[0]
                                    }
                                />
                            )}
                        </Link>
                    ))}
                </div>
            }
        </>
    );
};
export default connector(MobileNavigationbarItem);
