import * as React from "react";
import "./roles.scss";
import { Page } from "components/page/page";
import { Card } from "components/card/card";
import { Table } from "components/table/table";
import { Role } from "core/domain";
import { Badge } from "components";
import {
  CopyOutlined,
  EditOutlined,
  InfoCircleOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { getAllRoles } from "core/repositories/roles";
import { Button, buttonType } from "components/button/button";
import { Input } from "components/input/input";
// import { ClientSidePagination } from "components/pagination/pagination";
import { Enum, PaginationList } from "../../../../core/domain/commons/common";
import moment from "jalali-moment";
import { getPageNum } from "core/utils/paggingHelper";
import { digitSperator } from "core/utils/decimalExtentions";
import { copyToClipboard } from "core/utils/copyToClipboard";
import { Tag } from "antd";

interface FilterValues {
  search: string;
}

const empty_filters = {
  search: "",
};

export const Roles: React.FC = () => {
  var itemsPerPageCount = 20;

  const [roles, setRoles] = React.useState<Role[]>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
  const [deleteButtonLoading, setDeleteButtonLoading] =
    React.useState<boolean>(false);
  const [query, setQuery] = React.useState<string>("");
  const [filterValues, setFilterValues] =
    React.useState<FilterValues>(empty_filters);
  const [pageSize, setPageSize] = React.useState<number>(10);

  const navigate = useNavigate();

  const filterHandle = (clearFilter?: boolean) => {
    var _query = "";

    if (filterValues.search)
      _query += `${_query.includes("?") ? "&" : "?"}keywords=${
        filterValues.search
      }`;

    setQuery(query);
    getAllRoles(
      clearFilter
        ? setDeleteButtonLoading
        : _query !== ""
        ? setButtonLoading
        : setLoading,
      setRoles,
      clearFilter ? "" : clearFilter ? "" : _query
    );
  };

  React.useEffect(() => {
    filterHandle();
  }, []);

  const columns: any[] = [
    {
      name: "نام",
      id: "name",
      selector: (row: Role) => row.name,
      width: "20%",
    },
    {
      name: "عنوان",
      id: "title",
      selector: (row: Role) => row.title,
      width: "20%",
    },
    {
      name: "توضیحات",
      id: "description",
      selector: (row: Role) => row.description,
      width: "20%",
    },
    {
      name: "دسترسی ها",
      id: "claims",
      selector: (row: Role) => row.claims,
      cell: (record: Role) =>
        record.claims
          ? record.claims.map((e) => <Tag color='blue'>{e}</Tag>)
          : "-",
      width: "20%",
    },
    {
      name: "عملیات",
      id: "action",
      cell: (row: Role) => (
        <div className='actions'>
          <EditOutlined onClick={() => navigate(`edit/${row.id}`)} />
        </div>
      ),
    },
  ];
  return (
    <Page className='pmx-roles' title='نقش ها'>
      <Card title='لیست نقش ها' blur={loading}>
        <Button
          type={buttonType.info}
          text='افزودن نقش'
          icon={<PlusCircleFilled />}
          onClick={() => navigate("create")}
        />
        <div className='filter-row'>
          <div className='fields'>
            <Input
              value={filterValues.search}
              className='search'
              placeholder='جستجو کنید ...'
              onPressEnter={filterHandle}
              name='search'
              onChange={(e) =>
                setFilterValues({ ...filterValues, search: e.target.value })
              }
            />
            {/* <Dropdown
              defaultSelected={roleLevels[0]}
              items={roleLevels}
              onChangeHandle={(value) =>
                setSelectedLevel(
                  roleLevels.filter((item) => item.id === value)[0]
                )
              }
            /> */}
          </div>
          <div className='buttons'>
            <Button
              loading={buttonLoading}
              disabled={buttonLoading}
              text='اعمال فیلتر'
              type={buttonType.success}
              onClick={() => filterHandle()}
            />
          </div>
        </div>
        {roles && roles?.length > 0 && (
          <>
            <Table
              setPageSize={setPageSize}
              blur={loading}
              setloader={setLoading}
              getdata={getAllRoles}
              setdata={setRoles}
              className='roles-table'
              data={roles}
              column={columns}
              filters={query}
            />
          </>
        )}
      </Card>
    </Page>
  );
};
