import * as React from "react";
import {useEffect} from "react";
import "./sidebar-item.scss";
import {connect, ConnectedProps} from "react-redux";
import {RootState} from "core/redux/store";
import {useAppDispatch} from "core/redux/hooks";
import {Link} from "react-router-dom";
import {setExpandedMenuItem, setMenuItem, setSidebar,} from "core/redux/actions";
import {Badge} from "../../badge/badge";
import {WaitingCounts} from "../../../core/domain/dashboard/dashboard";

const mapState = (state: RootState) => ({
    state: state,
});
const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface SidebarItemProps extends PropsFromRedux {
    id: number;
    title: string;
    icon?: React.ReactNode;
    link?: string;
    className?: string;
    isActive?: boolean;
    onClickHandle?: () => void;
    childrens?: {
        id: number;
        title: string;
        link: string;
        itemKey?: string;
    }[];
    filled: boolean;
    itemKey?: string;
    waitingCounts?: WaitingCounts
}

const SidebarItem: React.FC<SidebarItemProps> = (props) => {
    const dispatch = useAppDispatch();

    var waitingCountKeys = props.waitingCounts && Object.keys(props.waitingCounts);
    var waitingCountValues = props.waitingCounts && Object.values(props.waitingCounts);

    return (
        <div
            className={`sidebar-item ${props.className ? props.className : ""} ${
                !props.childrens && props.state.menu === props.id ? "active" : ""
            } ${props.filled ? "filled" : ""}`}
        >
            <Link
                to={`${props.childrens ? "" : props.link}`}
                onClick={() => {
                    props.onClickHandle
                        ? props.onClickHandle()
                        : !props.childrens
                            ? dispatch(setMenuItem(props.id)) && dispatch(setSidebar("close"))
                            : props.state.expandedMenu === props.id
                                ? dispatch(setExpandedMenuItem(-1))
                                : props.state.expandedMenu !== props.id
                                    ? dispatch(setExpandedMenuItem(props.id))
                                    : dispatch(setExpandedMenuItem(-1));
                }}
            >
                <div className='content'>
                    {props.icon}
                    <p className='title'>
                        {props.title}
                        {props.itemKey && props.waitingCounts && (
                            <Badge
                                type='error'
                                text={
                                    props.itemKey == "waitingForAcceptReceipt" ? props.waitingCounts.buyWaitingForAcceptReceipt + props.waitingCounts.sellWaitingForAcceptReceipt + props.waitingCounts.tradeWaitingForAcceptReceipt : ""
                                }
                            />
                        )}
                    </p>
                </div>
                {props.childrens && (
                    <svg
                        className={`${!props.childrens && props.state.menu === props.id ? "open" : ""
                        } ${props.state.expandedMenu === props.id ? "expanded" : ""}`}
                        width='10'
                        height='12'
                        viewBox='0 0 10 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <path
                            d='M0.356857 4.869C0.411469 4.811 0.617674 4.563 0.809755 4.359C1.93588 3.076 4.87359 0.976 6.41119 0.335C6.6447 0.232 7.23507 0.014 7.55049 0C7.85274 0 8.14086 0.072 8.4158 0.218C8.75854 0.422 9.03347 0.743 9.18413 1.122C9.28111 1.385 9.43176 2.172 9.43176 2.186C9.58241 3.047 9.66527 4.446 9.66527 5.992C9.66527 7.465 9.58241 8.807 9.45907 9.681C9.44494 9.695 9.29429 10.673 9.12952 11.008C8.82727 11.62 8.2369 12 7.60511 12H7.55049C7.13903 11.985 6.27372 11.605 6.27372 11.591C4.81898 10.949 1.94906 8.952 0.795631 7.625C0.795631 7.625 0.470788 7.284 0.329551 7.071C0.109223 6.765 0 6.386 0 6.007C0 5.584 0.123346 5.19 0.356857 4.869Z'
                            fill='white'
                        />
                    </svg>
                )}
            </Link>
            {props.childrens && props.state.expandedMenu === props.id && (
                <ul className={`item-body`}>
                    {props.childrens?.map((item) => {
                        return (
                            <Link to={item.link}>
                                <li
                                    className={`${props.state.menu === item.id ? "active" : ""}`}
                                    onClick={() => {
                                        dispatch(setMenuItem(item.id));
                                        dispatch(setSidebar("close"));
                                    }}
                                >
                                    {item.title}
                                    {props.waitingCounts && item.itemKey && (
                                        <Badge
                                            type="error"
                                            text={
                                                waitingCountValues?.filter(
                                                    (i, index) =>
                                                        waitingCountKeys &&
                                                        waitingCountKeys.filter(
                                                            (a, idx) => a === item.itemKey && index === idx
                                                        )[0]
                                                )[0]
                                            }
                                        />
                                    )}
                                </li>
                            </Link>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};

export default connector(SidebarItem);
export {};
