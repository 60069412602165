import './edit-topic.scss'
import {Page} from "../../../../../../components/page/page";
import {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {editTopic, getTopicById} from "../../../../../../core/repositories/content";
import toast from "react-hot-toast";
import {Input} from "../../../../../../components/input/input";
import {Button, buttonType} from "../../../../../../components/button/button";
import {Card} from "../../../../../../components/card/card";

export interface EditTopicBody {
    id?: string;
    title?: string,
    englishTitle?: string;
    description?: string;
}

export const EditTopic = () => {
    const [body, setBody] = useState<EditTopicBody>({
        title: "",
        englishTitle: "",
        description: "",
    })
    const [loading, setLoading] = useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [topic, setTopic] = useState<EditTopicBody>()

    const handleEditTopic = () => {
        editTopic(setButtonLoading, body, () => toast.success("تاپیک با موفقیت ویرایش شد"))
    }

    useEffect(() => {
        getTopicById(setLoading, topicId, setBody)
    }, []);

    const location = useLocation()
    const topicId =
        location.pathname.split("/")[location.pathname.split("/").length - 1];

    return (
        <Page className={"pmx-edit-topic"} title={"ویرایش تاپیک"}>
            <Card blur={loading}>
                <Input value={body?.title} placeholder={"نام تاپیک جدید"} label={"نام تاپیک جهت ویرایش"}
                       onChange={(e) => setBody({...body, title: e.target.value})}/>
                <Input value={body?.englishTitle} placeholder={"نام انگلیسی تاپیک جدید"}
                       label={"نام انگلیسی تاپیک جهت ویرایش"}
                       onChange={(e) => setBody({...body, englishTitle: e.target.value})}
                       dir={!body.englishTitle ? "rtl" : "ltr"}/>
                <Input value={body?.description} placeholder={"توضیحات تاپیک جدید"} label={"توضیحات تاپیک جهت ویرایش"}
                       onChange={(e) => setBody({...body, description: e.target.value})}/>
                <Button type={buttonType.info} text={"ویرایش تاپیک"} onClick={handleEditTopic} loading={buttonLoading}
                        disabled={buttonLoading} />
                <Link to={"/dashboard/content/topics"}>
                    <Button type={buttonType.primary} text={"بازگشت به صفحه ی تاپیک ها"}/>
                </Link>
            </Card>
        </Page>
    )
}