import './add.scss'
import {Page} from "../../../../../components/page/page";
import {message, Select} from "antd";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {DefaultOptionType} from "antd/es/select";
import {getViaAuth, responseValidator} from "../../../../../apis/api";
import {PaginationList, User} from "../../../../../core/domain";
import {API} from "../../../../../apis";
import {AddUserSavedWalletsBody} from "../../../../../core/domain/users-saved-wallets/users-saved-wallets";
import {Input} from "../../../../../components/input/input";
import {Card} from "../../../../../components/card/card";
import {Button, buttonType} from "../../../../../components/button/button";
import {addWallet} from "../../../../../core/repositories/users-saved-wallets";
import {Link} from "react-router-dom";

export const AddWallet = () => {
    const [users, setUsers] = useState<DefaultOptionType[]>([]);
    const [usersLoading, setUsersLoading] = useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [data, setData] = useState<AddUserSavedWalletsBody>();
    const searchTimeout = useRef<NodeJS.Timeout | null>(null);

    const getAllUsers = async (query: string) => {
        setUsersLoading(true);
        await getViaAuth<PaginationList<User>>(
            API.users.getAll + `/${1}/${10}${query && `?keywords=${query}`}`
        ).then((response: any) => {
            setUsersLoading(false);
            const arr = [];
            if (responseValidator(response.status)) {
                if (response.data.items) {
                    response.data.items.forEach((e) =>
                        arr.push({
                            label:
                                e.fullName.startsWith(" ") || !e.fullName
                                    ? e.phoneNumber
                                    : e.fullName,
                            value: e.id,
                        })
                    );
                    setUsers(arr);
                }
            }
        });
    };

    const onSearchChange = (value: string) => {
        if (value !== "" && value.length > 0) {
            if (searchTimeout.current) {
                clearTimeout(searchTimeout.current);
            }

            searchTimeout.current = setTimeout(() => {
                getAllUsers(value);
            }, 1000);
        }
    };

    const handleAddWallet = () => {
        addWallet(setButtonLoading, data, () => message.success("کیف پول با موفقیت افزوده شد"));
    };

    useEffect(() => {
        return () => {
            if (searchTimeout.current) {
                clearTimeout(searchTimeout.current);
            }
        };
    }, []);

    return (
        <Page className={"pmx-add-wallet"}>
            <Card title={"اضافه کردن کیف پول"}>
                <div className='select'>
                    <label htmlFor='message'>کاربر صاحب حساب</label>
                    <Select
                        loading={usersLoading}
                        allowClear
                        value={data?.userId}
                        placeholder='کاربر را جستجو کنید'
                        onChange={(value, options) => {
                            setData({
                                ...data,
                                userId: value,
                            });
                        }}
                        options={users}
                        showSearch
                        onSearch={(value) => onSearchChange(value)}
                        filterOption={false}
                    />
                </div>
                <Input value={data?.label} label={"عنوان کیف پول"} placeholder={"عنوان کیف پول"}
                       onChange={(e) => setData({...data, label: e.target.value})}/>
                <Input value={data?.walletAddress} label={"آدرس کیف پول"} placeholder={"آدرس کیف پول"}
                       onChange={(e) => setData({...data, walletAddress: e.target.value})}/>
                <Input value={data?.network} label={"شبکه کیف پول"} placeholder={"شبکه کیف پول"}
                       onChange={(e) => setData({...data, network: e.target.value})}/>

                <div className={"buttons"}>
                    <Button type={buttonType.success} text={"افزودن"} loading={buttonLoading}
                            onClick={handleAddWallet}/>
                    <Link to={"/dashboard/users-saved-wallets"} target={"_self"}>
                        <Button type={buttonType.outlined} text={"بازگشت"}/>
                    </Link>
                </div>
            </Card>
        </Page>
    )
}
