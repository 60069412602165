import {Dispatch, SetStateAction} from "react";
import {getViaAuth, postViaAuth, put, responseValidator} from "../../apis/api";
import {PaginationList, } from "../domain";
import {API} from "../../apis";
import {
    AddUserSavedWalletsBody,
    EditUserSavedWalletsBody,
    RemoveUserSavedWalletBody,
    UserSavedWallets
} from "../domain/users-saved-wallets/users-saved-wallets";

export const getAllWallets = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    setData: Dispatch<SetStateAction<any>>,
    page: number,
    pageSize: number,
    query?: string
) => {
    setLoading(true)
    await getViaAuth<PaginationList<UserSavedWallets>>(
        API.userSavedWallets.getAll + `/${page}/${pageSize}${query || ""}`,
    ).then((response: any) => {
        setLoading(false);
        if (responseValidator(response.status)) {
            if (response.data) {
                setData(response.data);
            }
        }
    });
}

export const addWallet = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    data: AddUserSavedWalletsBody,
    onComplete: () => void,
) => {
    setLoading(true)
    await postViaAuth(API.userSavedWallets.add, data).then((response: any) => {
        setLoading(false)
        if (responseValidator(response.status)) {
            onComplete()
        }
    })
}

export const editWallet = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    data: EditUserSavedWalletsBody,
    onComplete: () => void
) => {
    setLoading(true)
    await postViaAuth(API.userSavedWallets.edit, data).then((response: any) => {
        setLoading(false)
        if (responseValidator(response.status)) {
            onComplete()
        }
    })
}

export const removeWallet = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    data: RemoveUserSavedWalletBody,
    onComplete: () => void
)=> {
    setLoading(true)
    await postViaAuth(API.userSavedWallets.remove, data).then((response: any) => {
        setLoading(false)
        if (responseValidator(response.status)) {
            onComplete()
        }
    })
}

export const getNetworks = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    symbol: string,
    setData: Dispatch<SetStateAction<any>>,
    onComplete: () => void,
) => {
    setLoading(true)
    getViaAuth(`${API.market.networks}/${symbol}`).then((response: any) => {
        setLoading(false)
        if (responseValidator(response.status)) {
            setData(response.data)
            onComplete()
        }
    })

}

export const getWalletById = (
    setLoading: Dispatch<SetStateAction<boolean>>,
    id: string,
    setData: Dispatch<SetStateAction<any>>,
    onComplete: () => void
) => {
    setLoading(true)
    getViaAuth(`${API.userSavedWallets.getById}/${id}`).then((response: any) => {
        setLoading(false)
        if (responseValidator(response.status)) {
            setData(response.data)
            onComplete()
        }
    })
}